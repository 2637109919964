<template>
    <el-autocomplete
        :value="value"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        @select="selected"
        @clear="$emit('clear')"
        :disabled="disabled"
        :placeholder="placeholder"
        clearable
        :fetch-suggestions="fetchSuggestions"
        :value-key="valueKey"
        >
        <span slot-scope="{ item }" class="contact-popper">
            <span>{{ item.full_name }}<template v-if="valueKey != 'full_name'"> ({{ item[valueKey] }})</template> <i class="fa-regular fa-star" v-if="item.favorite" /></span>
            <span v-if="item.ic" class="contact-popper__details">{{ item.ic }}</span>
            <span v-else class="contact-popper__details">{{ item.birthdate | formatDate }}</span>
            <span v-if="user && item.user && item.user.id != user.id" class="contact-popper__owner">({{ item.user.name }})</span>
        </span>

        <slot name="prepend" slot="prepend" v-if="$slots.prepend" />
        <slot name="append" slot="append" v-if="$slots.append" />
    </el-autocomplete>
</template>

<style lang="scss" scoped>
    .contact-popper {
        &__details {
            font-size: 11px;
            opacity: 0.6;
        }

        &__owner {
            font-size: 11px;
            opacity: 0.6;
            float: right;
        }
    }
</style>

<script>
  export default {
    inject: {
      user: {
        default: null
      }
    },    
    name: 'LsContactAutocomplete',
    props: {
        value: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        types: { //get only certain type of contact ["FO", "OSVC", "PO"]
            type: Array,
            default: null
        },
        valueKey: {
            type: String,
            default: 'full_name'
        }
    },

    methods: {
      /**
       * Load all contacts from DB
       */
      async fetchSuggestions(query, cb) {
        try {
            let data = await this.$api.post('/proculus-api/kontakty/basic', {
                types: this.types,
                limit: 20,
                name: query,
                latest: !query,
                onlyOwned: !query,
            }).then(x => x.data);
            cb(data);
        }
        catch(err) {
            this.$catch(err, this.$t('ls.contactAutocomplete.error.loading'));
            cb([]);
        }
      },

      /**
       * Contact selected from dropdown
       */
      async selected(el) {
        this.$emit('input', el[this.valueKey]);
        this.$emit('select', el);
      }
    }
  }
</script>

<i18n>
{
  "cz": {
    "ls": {
      "contactAutocomplete": {
        "error": {
          "loading": "Nepodařilo se načíst seznam kontaktů",
          "details": "Nepodařilo se načíst podrobnosti kontaktu"
        }
      }
    }
  }
}
</i18n>

<template>
  <div v-loading="loading" class="flex flex-col space-y-6">
    <el-alert type="info" show-icon :closable="false" :title="
        $t('Please select the type of document we will use to verify your identity, take a photo of the necessary pages and verify your face')
    "/>

    <div>
      <p class="pb-2 text-lg font-bold">{{ $t('Identity document') }}</p>
      <el-select v-model="documentType">
        <el-option value="identity" :label="$t('Identity card')" v-if="secondDocumentType !== 'identity'" />
        <el-option value="driver" :label="$t('Driving license')" v-if="secondDocumentType !== 'driver'" />
        <el-option value="passport" :label="$t('Passport')" v-if="secondDocumentType !== 'passport'"/>
      </el-select>
    </div>

    <div class="space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
      <document-side-capture
          class="flex-1"
          :key="documentType+'-front'"
          :label="$t('Front of document')"
          :icon="'fa-regular fa-id-card'"
          @submit="file => documentFront = file"
      />
      <document-side-capture
          v-if="documentType === 'identity' || documentType === 'driver'"
          class="flex-1"
          :key="documentType+'-back'"
          :label="$t('Back of document')"
          :icon="'fa-regular fa-credit-card'"
          @submit="file => documentBack = file"
      />
    </div>

    <div v-if="requireAdditionalDocument">
      <p class="pb-2 text-lg font-bold">{{ $t('Second identity document') }}</p>
      <el-select v-model="secondDocumentType">
        <el-option value="identity" :label="$t('Identity card')" v-if="documentType !== 'identity'" />
        <el-option value="driver" :label="$t('Driving license')" v-if="documentType !== 'driver'" />
        <el-option value="passport" :label="$t('Passport')" v-if="documentType !== 'passport'"/>
      </el-select>
    </div>

    <div class="space-y-4 sm:flex sm:space-x-4 sm:space-y-0" v-if="requireAdditionalDocument">
      <document-side-capture
          class="flex-1"
          :key="'second-'+secondDocumentType+'-front'"
          :label="$t('Front of document')"
          :icon="'fa-regular fa-id-card'"
          @submit="file => secondDocumentFront = file"
      />
      <document-side-capture
          v-if="secondDocumentType === 'identity' || secondDocumentType === 'driver'"
          class="flex-1"
          :key="'second-'+secondDocumentType+'-back'"
          :label="$t('Back of document')"
          :icon="'fa-regular fa-credit-card'"
          @submit="file => secondDocumentBack = file"
      />
    </div>

    <face-verification @submit="file => selfie = file"/>

    <div class="flex justify-center">
      <el-button @click="close" v-if="!hideBackButton">
        <i class="fa-solid fa-chevron-left mr-1"></i> {{ $t('Back') }}
      </el-button>

      <el-button @click="submit" type="primary">
        {{ $t('Confirm identification') }} <i class="fa-solid fa-check ml-1"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
import FaceVerification from '@/components/checks/kyc/camera/face-verification.vue'
import DocumentSideCapture from '@/components/checks/kyc/camera/document-side-capture.vue'

export default {
    components: {
        FaceVerification,
        DocumentSideCapture,
    },
    props: {
        kycAttemptUuid: {
            type: String,
            required: true,
        },
        hideBackButton: {
            type: Boolean,
            required: false,
            default: false
        },
        requireAdditionalDocument: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            documentType: 'identity',
            secondDocumentType: null,
            documentFront: null,
            documentBack: null,
            secondDocumentFront: null,
            secondDocumentBack: null,
            selfie: null,
            loading: false,
        }
    },
    mounted() {
      if (this.requireAdditionalDocument) {
        this.secondDocumentType = 'passport';
      }
    },
    watch: {
        documentType() {
            this.documentFront = null;
            this.documentBack = null;
        },
        secondDocumentType() {
            this.secondDocumentFront = null;
            this.secondDocumentBack = null;
        }
    },
    computed: {
        canBeSubmitted() {
            if (!this.selfie || !this.documentFront) {
              return false;
            }

            if (this.documentType !== 'passport' && !this.documentBack) {
              return false;
            }

            if (this.requireAdditionalDocument) {
              if (!this.secondDocumentFront) {
                return false;
              }
              if (this.secondDocumentType !== 'passport' && !this.secondDocumentBack) {
                return false;
              }
            }

            return true;
        }
    },
    methods: {
        submit() {
            if (! this.canBeSubmitted) {
                return this.$message.warning({
                    message: this.$t('To confirm identification, you must upload the documents and selfie'),
                    duration: 5000
                })
            }

            this.loading = true

            const data = new FormData();
            data.append('document_front', this.documentFront);
            data.append('selfie', this.selfie);

            if (this.documentBack) {
              data.append('document_back', this.documentBack);
            }
            if (this.secondDocumentFront) {
              data.append('second_document_front', this.secondDocumentFront);
            }
            if (this.secondDocumentBack) {
              data.append('second_document_back', this.secondDocumentBack);
            }

            this.$api.post('/external-api/kyc-attempts/' + this.kycAttemptUuid + '/submit', data)
                .then(() => this.$emit('submit'))
                .catch(error => {
                    this.$sentry.captureException(error);
                    this.$message.error(this.$t('Error occurred while uploading files'))
                })
                .finally(() => this.loading = false)
        },
        close() {
            this.$emit('close')
        }
    },
}
</script>

<i18n>
    {
        "cz": {
            "Identity document": "Doklad totožnosti",
            "Second identity document": "Druhý doklad totožnosti",
            "Please select the type of document we will use to verify your identity, take a photo of the necessary pages and verify your face": "Prosím, vyberte typ dokladu, pomocí kterého ověříme Vaši identitu, vyfoťte potřebné strany a ověřte svůj obličej",
            "Select document type": "Vyberte typ dokladu",
            "Identity card": "Občanský průkaz",
            "Driving license": "Řidičský průkaz",
            "Passport": "Pas",
            "Face verification and selfie": "Kontrola obličeje a selfie",
            "Confirm identification": "Potvrdit identifikaci",
            "Back of document": "Zadní strana dokladu",
            "Front of document": "Přední strana dokladu",
            "Additional document": "Dodatečný doklad",
            "To confirm identification, you must upload the documents and selfie": "K potvrzení identifikace je nutné nahrát doklady a ověřit obličej",
            "Error occurred while uploading files": "Nastala chyba při nahrávání souborů"
        },
        "en": {
            "Identity document": "Identity document",
            "Second identity document": "Second identity document",
            "Please select the type of document we will use to verify your identity, take a photo of the necessary pages and verify your face": "Please select the type of document we will use to verify your identity, take a photo of the necessary pages and verify your face",
            "Select document type": "Select document type",
            "Identity card": "Identity card",
            "Driving license": "Driving license",
            "Passport": "Passport",
            "Face verification and selfie": "Face verification and selfie",
            "Confirm identification": "Confirm identification",
            "Back of document": "Back of document",
            "Front of document": "Front of document",
            "Additional document": "Additional document",
            "To confirm identification, you must upload the documents and selfie": "To confirm identification, you must upload the documents and selfie",
            "Error occurred while uploading files": "Error occurred while uploading files"
        }
    }
</i18n>

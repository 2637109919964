<template>
    <div class="ls-card" :class="{ 'ls-card--labeled': !hideLabel }">
        <div class="ls-card__wrapper">
            <div class="ls-card__title" v-if="(title || $slots['title'])">
                <div>
                    <span v-if="collapsible" @click="collapsed = !collapsed" class="ls-card__collapse">
                        <i v-if="collapsed" class="el-icon-arrow-up" />
                        <i v-else class="el-icon-arrow-down" />
                    </span>
                    <template v-if="title">
                        <span>{{ title }}</span>
                        
                        <el-popover popper-class="ls-card__popper" v-if="$slots.help || $attrs.help" placement="top-start" trigger="hover">
                            <div style="ls-card__popper-container">
                            <template v-if="$attrs.help"><VNodes :node="$attrs.help"/></template>
                            <slot v-else name="help"></slot>
                            </div><i class="el-icon-question" slot="reference"></i>
                        </el-popover>
                    </template>
                    <slot v-else name="title"></slot>
                </div>
                <img v-if="!hideLogo" :src="hostname + '/images/icon.svg'" alt="logo icon" class="ls-card__icon" width="20">
            </div>

            <div class="ls-card__content" v-if="!collapsed">
                <slot></slot>
            </div>
            <div v-else class="ls-card__content-collapsed"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        hideLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        hideLogo: {
            type: Boolean,
            required: false,
            default: false
        },
        collapsible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        titleExists(){
            return this.title != null || this.$slots.title != null
        }
    },
    data() {
        return {
            collapsed: false
        }
    }
}
</script>

<style lang="scss">
$colors: #f0b428, #65bf98, #3ca9c5;

.ls-card{
    $self: &;
    position: relative;
    margin-bottom: 50px;

    @for $i from 1 through length($colors) {
        &--labeled:nth-child(#{length($colors)}n+#{$i}) {
            &:after {
                background-color: nth($colors, $i)
            }
        }
    }

    &--labeled {
        &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 80px;
            right: 100%;
            top: 0;
            border-radius: 6px 0 0 6px;
            background-color: #0F998E;
        }

        #{ $self }__wrapper{
            border-radius: 0 6px 6px 6px;
        }
    }

    &__wrapper{
        border-radius: 6px;
        border: solid 1px #EFEFEF;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .1);
        background-color: #FFFFFF;
        overflow: hidden;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background: #FFFFFF;
        border-bottom: solid 2px #EFEFEF;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;

        @media (min-width: 649px) {
            padding: 15px 20px;
        }
    }

    &__icon {
        margin-left: 10px;
        opacity: .15;
        filter: grayscale(100%);
    }

    &__content {
        padding: 10px;
        background-color: #FFFFFF;

        @media (min-width: 649px) {
            padding: 20px;
        }
    }

    &__content-collapsed {
        background-color: #FFFFFF;
        padding: 15px;
    }

    &__popper {
        max-width: 90%;

        @media (min-width: 600px) {
            max-width: 500px;
        }
    }

    &__popper-container {
        text-align: justify;
        word-break: keep-all;
    }

    &__collapse {
        cursor: pointer;
    }
}
</style>

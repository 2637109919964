<template>
    <div v-loading="Boolean(loading)">
        <!-- we have a contact -->
        <template v-if="originalContact">
            <div class="contact-card">
                <el-button
                     :disabled="disabled || lock"
                    class="contact-card__button"
                    @click="cancel" 
                    type="text"
                >
                    {{ $t('ls.contactCard.selectAnother') }}
                    <i class="fa-solid fa-arrow-rotate-right" />
                </el-button>

                <div class="contact-card__box">
                    <div class="contact-card__avatar">
                        <el-tooltip effect="dark" :content="$t('ls.contactCard.contactType.'+originalContact.type)" placement="top">
                            <i v-if="originalContact.type == 'PO'" class="fa-solid fa-building" />
                            <i v-else-if="originalContact.type == 'OSVC'" class="fa-solid fa-briefcase" />
                            <i v-else class="fa-solid fa-user" />
                        </el-tooltip>
                    </div>
                    <div>
                        <a :href="contactUrl" class="contact-card__name" target="_blank">
                            <span style="font-style: italic; opacity: 0.4;" v-if="status.creating">{{ $t('ls.contactCard.creating') }}</span>
                            <template v-else-if="status.unpaired || status.removed">-</template>
                            <template v-else>{{ originalContact.full_name || '-' }}</template>
                        </a>
                        <div class="contact-card__details" v-if="!status.unpaired && !status.removed">
                            <div v-if="originalContact.ic">{{ originalContact.ic }}</div>
                            <div v-else-if="originalContact.birthdate">{{ originalContact.birthdate | formatDate }}</div>
                            <div v-else-if="originalContact.rc">{{ originalContact.rc }}</div>
                            <div>{{ originalContact.email }}</div>
                        </div>
                    </div>
                </div>

                <div class="contact-card__status">
                    <div class="contact-card__status-warning" v-if="status.unpaired">
                        <i class="fas fa-exclamation-triangle"></i>
                        <div style="font-weight: bold">{{ $t('ls.contactCard.status.unpaired.title') }}</div>
                        {{ $t('ls.contactCard.status.unpaired.detail') }}
                    </div>
                    <div class="contact-card__status-warning" v-else-if="status.removed">
                        <i class="fas fa-exclamation-triangle"></i>
                        <div style="font-weight: bold">{{ $t('ls.contactCard.status.removed.title') }}</div>
                        {{ $t('ls.contactCard.status.removed.detail') }}
                        <el-button :disabled="disabled" @click="recreate" type="text">{{ $t('ls.contactCard.status.removed.recreate') }}</el-button>
                    </div>
                    <div class="contact-card__status-warning" v-else-if="disabled">
                        <i class="fas fa-exclamation-triangle"></i>
                        <div style="font-weight: bold">{{ $t('ls.contactCard.status.disabled.title') }}</div>
                        {{ $t('ls.contactCard.status.disabled.detail') }}
                    </div>
                    <div class="contact-card__status-warning" v-else-if="status.unauthorized">
                        <i class="fas fa-exclamation-triangle"></i>
                        <div style="font-weight: bold">{{ $t('ls.contactCard.status.unauthorized.title') }}</div>
                        {{ $t('ls.contactCard.status.unauthorized.detail') }}
                    </div>
                    <div class="contact-card__status-warning" v-else-if="collisionHandling && collision.mismatch">
                        <i class="fas fa-exclamation-triangle"></i>
                        <div style="font-weight: bold">{{ $t('ls.contactCard.status.collision.title') }}</div>
                        {{ $t('ls.contactCard.status.collision.detail') }}
                        <el-button :disabled="disabled" @click="collision.dialog = true" type="text">{{ $t('ls.contactCard.status.collision.resolve') }}</el-button>
                    </div>
                    <div class="contact-card__status-success" v-else>
                        <i class="fas fa-check-circle"></i>
                        <div style="font-weight: bold">{{ $t('ls.contactCard.status.sync.title') }}</div>
                        {{ $t('ls.contactCard.status.sync.detail') }}
                    </div>
                </div>
            </div>
        </template>

        <!-- no contact selected -->
        <template v-else>
            <div class="contact-search el-input el-input-group el-input-group--append">
                <el-select 
                    v-cancel-read-only
                    filterable
                    remote
                    :value="search"
                    clearable
                    @clear="clear"
                    ref="contactSelect"
                    value-key="id"
                    :disabled="disabled"
                    @visible-change="dropdownVisibilityChange"
                    @change="select"
                    :remote-method="apiLoadContacts"
                    @keydown.enter.native="enterKeydown"
                    :placeholder="type == 'PO' ? $t('ls.contactCard.placeholderPO') : $t('ls.contactCard.placeholder')"
                    :no-data-text="$t('ls.contactCard.noData')"
                    popper-class="contact-popper"
                >
                    <span slot="prefix" class="contact-icon el-input__suffix-inner">
                        <i class="fas fa-search" />
                    </span>

                        <el-option
                            v-for="item in contacts"
                            :key="item.id"
                            :value="item"
                        >
                            {{ item.full_name }} 
                            
                            <i v-if="item.favorite" class="far fa-star" />

                            <span v-if="item.ic" class="contact-popper__details">{{ item.ic }}</span>
                            <span v-else class="contact-popper__details">{{ item.birthdate | formatDate }}</span>
                            <span v-if="user && item.user && item.user.id != user.id" class="contact-popper__owner">({{ item.user.name }})</span>
                        </el-option>
                </el-select>

                <div class="el-input-group__append">
                    <el-button :disabled="disabled" @click="create">
                        <i class="fas fa-plus" /> {{ $t('ls.contactCard.createButton') }}
                    </el-button>
                </div>
            </div>

            <small class="contact-search-warning">
                <transition name="fade">
                    <span v-if="!dropdownVisible && search">
                        <i18n path="ls.contactCard.helper.message">
                            <template v-slot:existing>
                                <el-button type="text" :disabled="disabled" @click="dropdownOpen">{{ $t('ls.contactCard.helper.existing') }}</el-button>
                            </template>
                            <template v-slot:new>
                                <el-button type="text" :disabled="disabled" @click="dropdownOpen">{{ $t('ls.contactCard.helper.new') }}</el-button>
                            </template>
                        </i18n>
                    </span>
                </transition>
            </small>

        </template>

        <CollisionDialog
            v-if="collisionHandling"
            v-model="collision.dialog"
            :data="collision.data"
            :contact="contact"
            :compare-function="checkCollision"
            @update-document="collisionUpdateDocument"
            @update-contact="collisionUpdateContact"
        />
    </div>
</template>

<script>
import CollisionDialog from './CollisionDialog.vue';
export default {
    inject: {
      user: {
        default: null
      }
    },
    components: { CollisionDialog },
    props: {
        //whole person
        value: { 
            type: Object,
            default: null
        },
        //contact type
        type: {
            type: String,
            default: "FO",
        },
        //lock change to other contact
        lock: {
            type: Boolean,
            default: false
        },
        //new data loaded from DB
        changeInternal: { 
            type: Function
        },
        //data changed within form
        changeExternal: { 
            type: Function
        },
        //restrict which columns we are working with
        additionalColumns: { 
            type: Array,
            default: null
        },
        //disable component (readonly mode)
        disabled: {
            type: Boolean,
            default: false
        },
        collisionHandling: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            loading: 0,

            status: {
                initialized: false,
                creating: false,
                removed: false,
                unauthorized: false,
                unpaired: false,
            },

            search: null,
            dropdownVisible: false,

            contact: null,
            originalContact: null,
            contacts: [],

            collision: {
                dialog: false,
                mismatch: false,
                data: null
            },

            defaultColumns: ['id', 'full_name', 'name', 'middlename', 'surname', 'prefix', 'sufix', 'type', 'favorite'],
            skipColumns: ['id', 'type', 'favorite']
        };
    },

    computed: {
        columns() {
            return this.defaultColumns.concat(this.additionalColumns);
        },
        contactUrl() {
            if(this.status.removed || this.status.unpaired || this.status.unauthorized) return null;

            return this.hostname + '/v2/contacts/' + this.originalContact?.id;
        },
    },

    watch: {
        /**
         * propagate change from person model to contact (and therefore also to DB)
         */
        value: {
            handler() {
                if(!this.contact) return;

                this.changeExternal?.(this.value, this.contact);
            },
            deep: true
        },

        /**
         * Save data to DB
         *  - only if contact is CHANGED (not "selected", "created" or "removed")
         *  - in case of collision, skip saving
         *  - use debounce to prevent too many requests
         */
        contact: {
            handler: _.debounce(function(newValue, oldValue) {
                if(this.disabled) return;
                if(newValue === null || oldValue === null) return;
                if(this.collision.mismatch || this.status.removed || this.status.unpaired || this.status.unauthorized) return;

                let data = this.getContactData();
                this.apiUpdateContact(data);
            }, 500),
            deep: true
        },

        /**
         * contact_id was provided outside of the component, reinitialize
         *  - (mostly to handle special case for katastr sellers, see nemovitostAutomatic4)
         */
        "value.contact_id": {
            handler(newValue, oldValue) {
                if(!oldValue && newValue && !this.initialized) {
                    this.init(newValue);
                }
            }
        },
    },

    /**
     * Load all contacts from DB
     */
    async created() {
        this.apiLoadContacts();

        //backward compatibility
        if(!this.value.contact_id && this.value.contact) {
            this.$set(this.value, 'contact_id', this.value.contact.id || (this.value.contact.full_name ? -1 : null));
        }
    },

    /**
     * if we have contact_id, load contact details from DB and compare with actual data in form
     */
    async mounted() {
        this.init(this.value?.contact_id)
    },

    methods: {
        /**
         * Get contact data for storage
         */
        getContactData() {
            let data = {};
            this.columns.forEach((key) => data[key] = this.contact[key]);
            return data;
        },

        /**
         * Initialize component
         *  - load contact details from DB if contact_id is provided
         *  - compare contact data with existing person model
         *  - set collision flag if data mismatch
         */
        async init(contact_id) {
            if(!contact_id || this.initialized) return;
            this.initialized = true;

            //re-create contact from person model data
            this.contact = this.$globalHelpers.getEmptyContact();
            this.changeExternal?.(this.value, this.contact)

            //load actual contact from DB
            let databaseContact = await this.apiContactDetails(contact_id);
            this.originalContact = Object.assign({}, this.contact);

            //contact not loaded, skip comparison
            if(!databaseContact) return;

            //no collision handling, just update document data directly
            if(!this.collisionHandling) {
                this.collision.data = databaseContact;
                this.collisionUpdateDocument();
                return;
            }

            //compare database contact with person model
            if(!this.contactsEqual(this.contact, databaseContact)) {
                this.collision.mismatch = true;
                this.collision.data = databaseContact;
            }
        },

        /**
         * Load all contacts from DB
         */
        async apiLoadContacts(query) {
            try {
                this.search = query;
                this.contacts = await this.$api.post("/proculus-api/kontakty/basic", {
                    types: this.type == 'FO' ? ['FO', 'OSVC'] : [this.type],
                    limit: 19,
                    name: query,
                    latest: !query,
                    onlyOwned: !query,
                }).then((x) => x.data);
            } catch (err) {
                this.$catch(err, this.$t('ls.contactCard.error.load'));
            }
        },

        /**
         * Enter key pressed when no contact found - create new contact
         */
        enterKeydown() {
            if(this.contacts.length) return;

            this.create();
        },

        /**
         * Load contact details from DB
         */
        async apiContactDetails(contact_id) {
            if(contact_id == -1) {
                this.status.unpaired = true;
                return;
            }

            try {
                this.loading++;
                return await this.$api.get('/proculus-api/kontakty/get/' + contact_id).then(x => x.data);
            }
            catch(err) {
                if(err?.response?.status == 404) {
                    this.status.removed = true;
                    return;
                }

                if(err?.response?.status == 403) {
                    this.status.unauthorized = true;
                    return;
                }

                if(err?.response?.status == 301) {
                    this.$set(this.value, 'contact_id', err.response.data.id);
                    return err.response.data;
                }

                this.$catch(err, this.$t('ls.contactCard.error.details'));
            }
            finally {
                this.loading--;
            }
        },

        /**
         * Store new contact to DB
         */
        async apiCreateContact(data) {
            try {
                this.loading++;
                return await this.$api.post("/proculus-api/kontakty/new", data).then(x => x.data);
            }
            catch(err) {
                this.$catch(err, this.$t('ls.contactCard.error.create'));
            } 
            finally {
                this.loading--;
            }
        },

        /**
         * Update contact in DB
         */
        apiUpdateContact(data) {
            if(!data?.id) return;

            this.$api.put(`/proculus-api/kontakty/${data.id}/update`, data).catch(err => {
                if(err?.response?.status == 403) {
                    this.status.unauthorized = true;
                    return;
                }
                
                this.$catch(err, this.$t('ls.contactCard.error.update'));
            });
        },

        /**
         * Select contact
         */
        async select(contact) {
            if(!contact) return;

            this.initialized = true;
            this.contact = await this.apiContactDetails(contact.id);
            this.originalContact = Object.assign({}, this.contact);
            this.changeInternal?.(this.value, this.contact);
            this.status.creating = false;
            this.$nextTick(() => this.$emit('select'));
        },

        /**
         * Clear search input
         */
        clear() {
            this.search = null;
            this.apiLoadContacts();
        },

        /**
         * Open select dropdown
         */
        dropdownOpen() {
            this.$refs.contactSelect.focus();
            this.$nextTick(() => this.$refs.contactSelect.query = this.search);
        },

        /**
         * Select dropdown visibility changed (open/close)
         */
        dropdownVisibilityChange(visibility) {
            this.dropdownVisible = visibility;
            if(!visibility) return;
            this.$nextTick(() => this.$refs.contactSelect.query = this.search);
        },

        /**
         * Create new contact
         */
        async create() {
            this.initialized = true;

            this.contact = this.$globalHelpers.getEmptyContact();
            let contact = await this.apiCreateContact({ 
                name: this.search,
                type: this.type
            });
            this.contact = Object.assign(this.contact, contact);

            this.originalContact = Object.assign({}, this.contact);
            this.changeInternal?.(this.value, this.contact);
            this.status.creating = true;
        },

        /**
         * Recreate removed contact
         */
        async recreate() {
            let data = this.getContactData();

            this.contact = this.$globalHelpers.getEmptyContact();            
            let contact = await this.apiCreateContact({
                ...data,
                id: null
            });
            this.contact = Object.assign(this.contact, contact);

            this.originalContact = Object.assign({}, this.contact);
            this.changeInternal?.(this.value, this.contact);
            this.status.removed = false;
        },

        /**
         * Cancel selected contact
         */
        cancel() {
            this.clear();
            this.contact = null;
            this.originalContact = null;
            this.status.creating = false;
            this.status.removed = false;
            this.status.unpaired = false;
            this.status.unauthorized = false;
            this.collisionReset();
            this.changeInternal?.(this.value, this.contact);
            this.$nextTick(() => this.$emit('cancel'));
        },

        /**
         * Compare two contacts for equality *only* in relevant columns
         */
        contactsEqual(a, b) {
           for(let key of this.columns) {
                if(this.skipColumns.includes(key)) continue;

                if(this.checkCollision(key, a, b)) {
                    return false;
                }
           }

            return true;
        },

        /**
         * Check if two contacts have different data in given column
         */
        checkCollision(key, a, b) {
            if(!this.columns.includes(key)) return false;

            let first = a[key];
            let second = b[key];

            if(!first) return !!second;

            first = typeof first === 'string' ? first.trim() : first
            second = typeof second === 'string' ? second.trim() : second

            return first != second;
        },

        /**
         * Update data in person model (within form)
         */
        collisionUpdateDocument() {
            this.contact = this.collision.data;
            this.changeInternal?.(this.value, this.contact);
            this.collisionReset();
        },

        /**
         * Update data in contact (in DB)
         */
        collisionUpdateContact() {
            this.contact = {
                ...this.collision.data,
                ...this.contact,
            };
            this.changeInternal?.(this.value, this.contact);
            this.collisionReset();
        },

        /**
         * Reset collision data
         */
        collisionReset() {
            this.collision.data = null;
            this.collision.mismatch = false;
        }
    },
};
</script>

<style lang="scss" scoped>
    .contact-search::v-deep {
        .is-focus .el-input__inner {
            border-color: #0096B3 !important;
        }
    }

    .contact-icon {
        line-height: 40px;
        padding: 5px;
    }    

    .contact-popper {
        &__details {
            font-size: 11px;
            opacity: 0.6;
        }

        &__owner {
            font-size: 11px;
            opacity: 0.6;
            float: right;
        }
    }

    .contact-card {
        padding: 20px;
        border: 1px solid #ccc;
        background: #fafafa;
        border-radius: 12px;
        margin-bottom: 20px;
        position: relative;

        &__box {
            display: flex;
            gap: 20px;
            align-items: center;
        }

        &__avatar {
            font-size: 26px;
            border-radius: 50px;
            border: 1px solid rgb(204, 204, 204);
            width: 50px;
            line-height: 46px;
            height: 50px;
            text-align: center;

            @media (max-width: 400px) {
                display: none;
            }
        }

        &__name {
            font-weight: bold;
        }
        
        &__details {
            font-size: 13px;
        }

        &__button {
            position: absolute;
            font-size: 13px;
            right: 20px;
            top: 20px;
            padding: 0;
            margin: 0;

            @media (max-width: 400px) {
                position: relative;
                margin-bottom: 15px;
                right: initial;
                top: initial;
            }
        }

        &__status {
            border-top: 1px solid #DDD;
            margin-top: 15px;
            padding-top: 15px;
            font-size: 13px;
            
            .el-button {
                font-size: inherit;
                padding: 0;
            }

            i {
                float: left; 
                font-size: 25px; 
                padding: 7px 10px 0 0;
            }
            
        }

        &__status-warning {
            color: #E6A23C;
        }

        &__status-success {
            color: #54A280;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: .3s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .contact-search-warning {
        color: #E6A23C;

        .el-button {
            padding: 0;
            margin: 0;
        }
    }
</style>

<i18n>
{
    "cz": {
        "ls": {
            "contactCard": {
                "contactType": {
                    "FO": "Fyzická osoba",
                    "PO": "Právnická osoba",
                    "OSVC": "OSVČ"
                },
                "selectAnother": "Vybrat jiný kontakt",
                "creating": "Nový kontakt",
                "createButton": "Vytvořit kontakt",
                "placeholder": "Vyhledejte nebo zadejte jméno osoby",
                "placeholderPO": "Vyhledejte nebo zadejte jméno společnosti",
                "noData": "Žádný kontakt nenalezen",
                "status": {
                    "sync": {
                        "title": "Kontakt je synchronizován.",
                        "detail": "Provedené změny jsou synchronizovány s kontaktem."
                    },
                    "collision": {
                        "title": "Pozor! Byly nalezeny rozdíly v datech.",
                        "detail": "Některé údaje se neshodují s kontaktem. Synchronizace změn byla zastavena.",
                        "resolve": "Řešit rozdíly"
                    },
                    "removed": {
                        "title": "Pozor! Vybraný kontakt byl odstraněn.",
                        "detail": "Synchronizace změn do kontaktů není možná. Kontakt můžete znovu vytvořit, vybrat jiný nebo pokračovat bez synchronizace.",
                        "recreate": "Znovu vytvořit kontakt"
                    },
                    "unauthorized": {
                        "title": "Pozor! Nemáte oprávnění pro přístup k tomuto kontaktu.",
                        "detail": "Synchronizace změn do kontaktu není možná. Můžete vybrat jiný kontakt nebo pokračovat bez synchronizace."
                    },
                    "unpaired": {
                        "title": "Pozor! Data nejsou propojena s kontaktem.",
                        "detail": "Synchronizace změn do kontaktu není možná. Můžete vybrat jiný kontakt nebo pokračovat bez synchronizace."
                    },
                    "disabled": {
                        "title": "Pozor! Kontakt je dostupný jen pro čtení.",
                        "detail": "Synchronizace změn do kontaktu není možná."
                    }
                },
                "helper": {
                    "message": "Pro doplnění dalších údajů {existing} nebo {new}.",
                    "existing": "vyberte existující kontakt",
                    "new": "vytvořte nový kontakt"
                },
                "error": {
                    "load": "Nepodařilo se načíst seznam kontaktů",
                    "details": "Nepodařilo se načíst podrobnosti kontaktu",
                    "create": "Nepodařilo se vytvořit nový kontakt",
                    "update": "Nepodařilo se aktualizovat kontakt"
                }
            }
        }
    },
    "en": {
        "ls": {
            "contactCard": {
                "contactType": {
                    "FO": "Natural person",
                    "PO": "Legal entity",
                    "OSVC": "Self-employed"
                },
                "selectAnother": "Select another contact",
                "creating": "New contact",
                "createButton": "Create contact",
                "placeholder": "Search or enter person name",
                "placeholderPO": "Search or enter company name",
                "noData": "No contact found",
                "status": {
                    "sync": {
                        "title": "Contact is synchronized.",
                        "detail": "Changes are synchronized with the contact."
                    },
                    "collision": {
                        "title": "Warning! Differences in data were found.",
                        "detail": "Some data does not match the contact. Synchronization of changes has been stopped.",
                        "resolve": "Resolve differences"
                    },
                    "removed": {
                        "title": "Warning! Selected contact has been removed.",
                        "detail": "Synchronization of changes to contacts is not possible. You can recreate the contact, select another one, or continue without synchronization.",
                        "recreate": "Recreate contact"
                    },
                    "unauthorized": {
                        "title": "Warning! You do not have permission to access this contact.",
                        "detail": "Synchronization of changes to the contact is not possible. You can select another contact or continue without synchronization."
                    },
                    "unpaired": {
                        "title": "Warning! Data is not paired with the contact.",
                        "detail": "Synchronization of changes to the contact is not possible. You can select another contact or continue without synchronization."
                    },
                    "disabled": {
                        "title": "Warning! Contact is read-only.",
                        "detail": "Synchronization of changes to the contact is not possible."
                    }
                },
                "helper": {
                    "message": "To complete additional data {existing} or {new}.",
                    "existing": "select existing contact",
                    "new": "create new contact"
                },
                "error": {
                    "load": "Failed to load contact list",
                    "details": "Failed to load contact details",
                    "create": "Failed to create new contact",
                    "update": "Failed to update contact"
                }
            }
        }
    },
    "sk": {
        "ls": {
            "contactCard": {
                "contactType": {
                    "FO": "Fyzická osoba",
                    "PO": "Právnická osoba",
                    "OSVC": "OSVČ"
                },
                "selectAnother": "Vybrať iný kontakt",
                "creating": "Nový kontakt",
                "createButton": "Vytvoriť kontakt",
                "placeholder": "Vyhľadajte alebo zadajte meno osoby",
                "placeholderPO": "Vyhľadajte alebo zadajte názov spoločnosti",
                "noData": "Žiadny kontakt nenájdený",
                "status": {
                    "sync": {
                        "title": "Kontakt je synchronizovaný.",
                        "detail": "Provedené zmeny sú synchronizované s kontaktom."
                    },
                    "collision": {
                        "title": "Pozor! Boli nájdené rozdiely v dátach.",
                        "detail": "Niektoré údaje sa nezhodujú s kontaktom. Synchronizácia zmien bola zastavená.",
                        "resolve": "Riešiť rozdiely"
                    },
                    "removed": {
                        "title": "Pozor! Vybraný kontakt bol odstránený.",
                        "detail": "Synchronizácia zmien do kontaktov nie je možná. Kontakt môžete znovu vytvoriť, vybrať iný alebo pokračovať bez synchronizácie.",
                        "recreate": "Znovu vytvoriť kontakt"
                    },
                    "unauthorized": {
                        "title": "Pozor! Nemáte oprávnenie pre prístup k tomuto kontaktu.",
                        "detail": "Synchronizácia zmien do kontaktu nie je možná. Môžete vybrať iný kontakt alebo pokračovať bez synchronizácie."
                    },
                    "unpaired": {
                        "title": "Pozor! Dáta nie sú prepojené s kontaktom.",
                        "detail": "Synchronizácia zmien do kontaktu nie je možná. Môžete vybrať iný kontakt alebo pokračovať bez synchronizácie."
                    },
                    "disabled": {
                        "title": "Pozor! Kontakt je dostupný len pre čítanie.",
                        "detail": "Synchronizácia zmien do kontaktu nie je možná."
                    }
                },
                "helper": {
                    "message": "Pre doplnenie ďalších údajov {existing} alebo {new}.",
                    "existing": "vyberte existujúci kontakt",
                    "new": "vytvorte nový kontakt"
                },
                "error": {
                    "load": "Nepodarilo sa načítať zoznam kontaktov",
                    "details": "Nepodarilo sa načítať podrobnosti kontaktu",
                    "create": "Nepodarilo sa vytvoriť nový kontakt",
                    "update": "Nepodarilo sa aktualizovať kontakt"
                }
            }
        }
    }
}
</i18n>
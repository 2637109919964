<template>
  <div class="pdfPlayground">
    <!-- signature storage -->
    <div id="signatures"></div>

    <!-- toolbox -->
    <div id="toolboxSpacing"></div>
    <div id="toolbox" v-loading="loading">
      <div class="dragWrap" :key="index" v-for="(subject, index) in subjects.map((x,i)=>{x.i = i; return x;}).filter(x => x.type != 'remote-review')">
        <div class="hidden" :data-sign="subject.i">
          <div>
            <div class="remove" onclick="window.removeFn(this)">X</div>
            <template v-if="subject.type=='local'">
              <img :src="subject.sign"><br>
            </template>
            <template v-else>
              {{subject.name || ('Subjekt '+(index+1))}}<template v-if="subject.subject == 'variable'"> (variabilní)</template>
            </template>
          </div>
        </div>
        <div class="draggable dropped-out" :data-sign="subject.i" :data-id="index">
          <div>
            <div class="remove" onclick="window.removeFn(this)">X</div>
            <template v-if="subject.type=='local'">
              <img :src="subject.sign"><br>
            </template>
            <template v-else>
              {{subject.name || ('Subjekt '+(index+1))}}<template v-if="subject.subject == 'variable'"> (variabilní)</template>
            </template>
          </div>
        </div>
      </div>
      <div style="clear:both"></div>
    </div>

    <!-- PDF viewer -->
    <div id="pageContainer"></div>
  </div>
</template>

<style lang="scss" scoped>
  .pdfPlayground {
    position: relative;
  }

  .pdfPlayground::v-deep {
    #toolbox {
      padding: 20px;
      position: sticky;
      top: 66px;
      margin-top: -66px;
      border: 1px solid #EBEEF5;
      background: #fafafa;
      border-radius: 4px;
      box-shadow: 0 5px 15px 10px #FFF;

      .dragWrap {
        width: 200px;
        height: 100px;
        max-width: 200px;
        max-height: 100px;
        border: 1px solid #AAA;
        display: block;
        float: left;
        margin-right: 20px;
        border-radius: 5px;
        position: relative;

        .hidden {
          display: none;
        }      
      }
    }

    #toolboxSpacing {
      height: 66px;
      background: white;
      position: sticky;
      top: 0;
    }

    .draggable {
      touch-action: none; user-select: none;
      width: 200px; 
      height: 100px; 
      background: #66666611;
      border: 2px solid #666;
      color: #666;
      border-radius: 5px;
      font-weight: bold;
      margin: -1px 0 0 -1px;
      position: absolute;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
      }

      div {
        width: 100%;
        height: 100%;  
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }

    .draggable.can-drop {
      color: #65BF98;
      border-color: #65BF98;
      background: #65BF9833;
    }

    .draggable.dropped-out, .draggable.first-drag {
      z-index: 1000;
    }

    .can-drop .remove {
      width: 16px;
      height: 16px;
      position: absolute;
      display: block;
      top: -8px;
      right: -8px;
      font-size: 10px;
      cursor: pointer;
      border-radius: 20px;
      line-height: 16px;
      text-align: center;
      background: black;
      color: white;    
    }

    .drop-active .remove, .dropped-out .remove {
      display: none;
      visibility: hidden;
    }

    .dropzone {
      border: 1px solid #CCC;
      background-color:transparent;
      outline: 2px dashed transparent;
      margin-top: 20px;
      width: 100%;
    }

    .dropzone.dropzone-active {
      outline-color: #999;
    }
  }
</style>

<script>
  import * as interact from 'interactjs'

  const PdfJs = require('pdfjs-dist/es5/build/pdf');
  require('@/../css/pdf_viewer.css');
  PdfJs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/es5/build/pdf.worker.entry.js');

  export default {
    props: [
      'value',
      'pdf',
      'subjects'
    ],
    data () {
      return {
        loading: false,
        resizeObserver: null,
        resizeState: null,
        resizeSwitch: true,
        resizeWidth: 0,

        destroying: false,
        width: 200,
        height: 100,
        minWidth: 100,
        minHeight: 50,
        ratio: 2,
        pages: [],
        signatures: {},
        highestId: 0,
      }
    },

    beforeDestroy() {
      try {
        this.resizeObserver.unobserve(document.getElementById('pageContainer'));
      }
      catch (e) {}
      this.destroying = true;
      this.resizeObserver = null;
      this.interactCleaner();
    },

    destroyed() {
      window.removeFn = undefined;
    },

    async mounted() {
      window.removeFn = this.removeFn;

      this.highestId = this.subjects.length;

      await this.drawPdfCanvas();

      this.resizeWidth = document.getElementById('pageContainer').offsetWidth;
      this.resizeObserver = new ResizeObserver(this.resizeCleaner);
      this.resizeObserver.observe(document.getElementById('pageContainer'), {box: 'border-box'});
  	},
    methods: {
      async drawPdfCanvas() {
        this.loading = true;

        //load PDF
        await PdfJs.getDocument({data: atob(this.pdf)}).promise.then(async pdf => {

          // Fetch pages
          for (var i = 1; i <= pdf.numPages; i++) {
            let page = await pdf.getPage(i);

            //create canvas
            var canvas = document.createElement('canvas');
            canvas.classList.add('dropzone');
            canvas.setAttribute("data-page", page.pageNumber || i);
            document.getElementById('pageContainer').appendChild(canvas);
            var context = canvas.getContext('2d');
            
            //set canvas width to 100% of wrapper
            canvas.width = canvas.parentElement.offsetWidth;
            
            if(page.rotate == 90 || page.rotate == 270) {
              var pageWidth = page.view[3];
              var pageHeight = page.view[2];
            }
            else {
              var pageWidth = page.view[2];
              var pageHeight = page.view[3];
            }

            this.pages.push({
              left: page.view[0], 
              top: page.view[1], 
              width: pageWidth, 
              height: pageHeight, 
              scale: pageWidth / canvas.width
            });

            //get viewport in aspect ratio
            var viewport = page.getViewport({ scale: (canvas.width / page.getViewport({ scale: 1.0}).width) });
            
            //set corresponding canvas height
            canvas.height = viewport.height;
            
            //Render PDF page into canvas context
            var renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            
            try {
              await page.render(renderContext).promise;
            }
            catch(err) {
              this.$message.error('Nastala chyba! Váš dokument se nepodařilo zobrazit, pravděpodobně je poškozený. Náš tým byl informován.');
              this.$sentry.captureException(err);
            };
          }
        });

        // enable draggables to be dropped into this
        interact('.dropzone').dropzone({
          // only accept elements matching this CSS selector
          accept: '.draggable',

          // Require a 100% element overlap for a drop to be possible
          overlap: 1,
      
          // listen for drop related events
          ondropactivate: this.ondropactivate,
          ondropdeactivate: this.ondropdeactivate,
          ondragenter: this.ondragenter,
          ondragleave: this.ondragleave,
          ondrop: this.ondrop,
        });

        interact('.draggable').resizable({
            // resize from all edges and corners
            edges: { left: true, right: true, bottom: true, top: true },
        
            listeners: {
              move: this.dragResize,
              end: this.dragResizeEnd
            },
            modifiers: [
              // keep the edges inside the parent
              interact.modifiers.restrictEdges({ outer: '#pageContainer' }),
        
              interact.modifiers.aspectRatio({ ratio: this.ratio }),

              // minimum size
              interact.modifiers.restrictSize({ min: { width: this.minWidth, height: this.minHeight } })
            ],
          }).draggable({
            // keep the element within the area of it's parent
            modifiers: [
              interact.modifiers.restrictRect({
                restriction: '.pdfPlayground',
                endOnly: true
              })
            ],

            // enable autoScroll
            autoScroll: true,
        
            listeners: {
              // call this function on every dragmove event
              start: this.dragStart,
              move: this.dragMove,
            }
          });
        this.loading = false;
      },

      interactCleaner() {
        interact('.dropzone').unset();
        interact('.draggable').unset();
      },

      resizeCleaner(entries) {
        const width = Math.round(entries?.[0].borderBoxSize?.[0].inlineSize);
        if (this.resizeSwitch && typeof width === 'number' && width !== this.resizeWidth) {
          this.resizeWidth = width;

          //handle resize
          clearTimeout(this.resizeState);
          this.resizeState = setTimeout(this.resizeCleanerFn, 300);
          this.resizeSwitch = false;
        }
      },

      async resizeCleanerFn() {
        if(!this.destroying) {
          //remove placed signatures
          if(Object.keys(this.signatures).length) {
            for(var prop in this.signatures) {
                if (this.signatures.hasOwnProperty(prop)) {
                  document.querySelector(`[data-id='${prop}']`).parentElement.remove();
                }
            }
            this.signatures = {};
            this.$emit('input', this.signatures);
          }

          this.pages = [];
          this.interactCleaner();

          //remove canvas content and redraw
          var parent = document.getElementById('pageContainer');
          while (parent.firstChild) { parent.removeChild(parent.firstChild); }

          await this.drawPdfCanvas();
        }
        this.resizeSwitch = true;
      },

      dragMove(event) {
        var draggable = event.target;

        var x = (parseFloat(draggable.getAttribute('data-x')) || 0) + event.dx;
        var y = (parseFloat(draggable.getAttribute('data-y')) || 0) + event.dy;
        this.setCoordinates(draggable, x, y);
      },

      dragResize(event) {
        var draggable = event.target;
        
        //disable resize when outside dropzone
        if(!draggable.classList.contains("can-drop"))
          return;
        
        // translate when resizing from top or left edges
        var x = (parseFloat(draggable.getAttribute('data-x')) || 0) + event.deltaRect.left;
        var y = (parseFloat(draggable.getAttribute('data-y')) || 0) + event.deltaRect.top;
        this.setCoordinates(draggable, x, y);

        //resize
        draggable.style.width = event.rect.width + 'px';
        draggable.style.height = event.rect.height + 'px';        
      },      

      ondropactivate(event) {
        // add active dropzone feedback
        event.target.classList.add('dropzone-active');
        event.relatedTarget.classList.add('drop-active');
      },

      ondragenter(event) {
        var draggable = event.relatedTarget,
            dropzone = event.target;

        // feedback the possibility of a drop
        dropzone.classList.add('drop-target');
        draggable.classList.add('can-drop');
        draggable.classList.remove('dropped-out');
      },

      ondragleave(event) {
        var draggable = event.relatedTarget,
            dropzone = event.target;

        // remove the drop feedback style
        dropzone.classList.remove('drop-target');
        draggable.classList.remove('can-drop');
        draggable.classList.add('dropped-out');
      },

      dragStart(event) {
        var draggable = event.target;

        //if element in toolbox, move to signatures
        let dBox = draggable.parentElement.parentElement;
        if(dBox && dBox.getAttribute('id') == "toolbox") {
          draggable.classList.add('first-drag');
          //add duplicate to toolbox
          let wrapper = draggable.parentElement;
          let signatures = document.getElementById("signatures");

          let shadow = wrapper.querySelector(".hidden");
          if(shadow) {
            let clone = shadow.cloneNode(true);
            clone.classList.remove("hidden");
            clone.classList.add("draggable");
            clone.classList.add("dropped-out");
            clone.setAttribute("data-id", this.highestId);
            this.highestId++;
            wrapper.appendChild(clone);
          }

          let x = wrapper.getBoundingClientRect().left - signatures.getBoundingClientRect().left;
          let y = wrapper.getBoundingClientRect().top - signatures.getBoundingClientRect().top;
          this.setCoordinates(draggable, x, y);

          let dragWrap = document.createElement('div');
          dragWrap.classList.add('dragWrap');
          dragWrap.appendChild(draggable);
          signatures.appendChild(dragWrap);
        }
      },

      ondropdeactivate(event) {
        var draggable = event.relatedTarget,
            dropzone = event.target;

        //if moved outside of dropzone, remove
        if(draggable.classList.contains("dropped-out")) {
          delete this.signatures[draggable.getAttribute("data-id")];
          let dBox = draggable.parentElement.parentElement;
          if(dBox && dBox.getAttribute('id') == "toolbox")
            this.revertBack(draggable);
          else
            draggable.parentElement.remove();

          this.$emit('input', this.signatures);
        }

        // remove active dropzone feedback
        dropzone.classList.remove('dropzone-active');
        dropzone.classList.remove('drop-target');
        draggable.classList.remove('drop-active');
        draggable.classList.remove('first-drag');
      },

      ondrop(event) {
        var draggable = event.relatedTarget,
            dropzone = event.target;

        draggable.classList.remove('drop-active');

        //get page ID and signature ID
        var page = parseInt(dropzone.getAttribute("data-page")) - 1;
        var sign = parseInt(draggable.getAttribute("data-sign"));
        var id = parseInt(draggable.getAttribute("data-id"));

        //calculate coordinates and scale
        var coords = this.getPageCoordinates(dropzone, draggable, page);
        var scale = this.getPageScale(draggable, page);

        //compose or overwrite signature
        var signature = { 
          id: id,
          page: page, 
          sign: sign,
          scale: scale,
          x: coords.x,
          y: coords.y,
        }

        this.signatures[signature.id] = signature;

        this.$emit('input', this.signatures);
      },

      dragResizeEnd(event) {
        //get signature - could be null (signature is not on canvas)
        var signature = this.signatures[event.target.getAttribute("data-id")];
        
        if(signature) {
          //get canvas DOM reference
          var pageTarget = document.querySelector(`[data-page='${signature.page+1}']`);

          //new coordinates
          var coords = this.getPageCoordinates(pageTarget, event.target, signature.page);
          signature.x = coords.x;
          signature.y = coords.y;

          signature.scale = this.getPageScale(event.target, signature.page);

          this.$emit('input', this.signatures);
        }
      },

      //remove from DOM and from array
      removeFn(event) {
        var draggable = event.parentElement.parentElement;
        delete this.signatures[draggable.getAttribute("data-id")];
        draggable.remove();

        this.$emit('input', this.signatures);
      },      

      /* HELPER METHODS */
        //set target X/Y coordinates
        setCoordinates(target, x, y) {
          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },

        //return target to initial position
        revertBack(target) {
          this.setCoordinates(target, 0, 0);
          target.style.width  = null;
          target.style.height = null;
        },

        //calculate coordinates in page reference size
        getPageCoordinates(page, sign, pageNumber) {
          var x = sign.getBoundingClientRect().left   - page.getBoundingClientRect().left;
          var y = page.getBoundingClientRect().bottom - sign.getBoundingClientRect().bottom;

          return {
            x: x * this.pages[pageNumber].scale,
            y: y * this.pages[pageNumber].scale 
          }
        },

        //calculate scale of signature in reference to PDF
        getPageScale(element, pageNumber) {
          //scale according to image size = width of DIV / real width of IMG
          //at this point we don't have IMG dimensions - will be calculated in generator script
          var scale = element.offsetWidth;

          //put to PDF perspective
          return scale * this.pages[pageNumber].scale;
        }

    }
  }
</script>

// Array of country objects for the flag dropdown.

// Here is the criteria for the plugin to support a given country/territory
// - It has an iso2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
// - It has it's own country calling code (it is not a sub-region of another country): https://en.wikipedia.org/wiki/List_of_country_calling_codes
// - It has a flag in the region-flags project: https://github.com/behdad/region-flags/tree/gh-pages/png
// - It is supported by libphonenumber (it must be listed on this page): https://github.com/googlei18n/libphonenumber/blob/master/resources/ShortNumberMetadata.xml

// Each country array has the following information:
// [
//    Country name,
//    iso2 code,
//    International dial code
// ]
const allCountries = [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    iso2: 'AF',
    dial: '93',
  },
  {
    name: 'Albania (Shqipëri)',
    iso2: 'AL',
    dial: '355',
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    iso2: 'DZ',
    dial: '213',
  },
  {
    name: 'American Samoa',
    iso2: 'AS',
    dial: '1684',
  },
  {
    name: 'Andorra',
    iso2: 'AD',
    dial: '376',
  },
  {
    name: 'Angola',
    iso2: 'AO',
    dial: '244',
  },
  {
    name: 'Anguilla',
    iso2: 'AI',
    dial: '1264',
  },
  {
    name: 'Antigua and Barbuda',
    iso2: 'AG',
    dial: '1268',
  },
  {
    name: 'Argentina',
    iso2: 'AR',
    dial: '54',
  },
  {
    name: 'Armenia (Հայաստան)',
    iso2: 'AM',
    dial: '374',
  },
  {
    name: 'Aruba',
    iso2: 'AW',
    dial: '297',
  },
  {
    name: 'Australia',
    iso2: 'AU',
    dial: '61',
  },
  {
    name: 'Austria (Österreich)',
    iso2: 'AT',
    dial: '43',
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    iso2: 'AZ',
    dial: '994',
  },
  {
    name: 'Bahamas',
    iso2: 'BS',
    dial: '1242',
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    iso2: 'BH',
    dial: '973',
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    iso2: 'BD',
    dial: '880',
  },
  {
    name: 'Barbados',
    iso2: 'BB',
    dial: '1246',
  },
  {
    name: 'Belarus (Беларусь)',
    iso2: 'BY',
    dial: '375',
  },
  {
    name: 'Belgium (België)',
    iso2: 'BE',
    dial: '32',
  },
  {
    name: 'Belize',
    iso2: 'BZ',
    dial: '501',
  },
  {
    name: 'Benin (Bénin)',
    iso2: 'BJ',
    dial: '229',
  },
  {
    name: 'Bermuda',
    iso2: 'BM',
    dial: '1441',
  },
  {
    name: 'Bhutan (འབྲུག)',
    iso2: 'BT',
    dial: '975',
  },
  {
    name: 'Bolivia',
    iso2: 'BO',
    dial: '591',
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    iso2: 'BA',
    dial: '387',
  },
  {
    name: 'Botswana',
    iso2: 'BW',
    dial: '267',
  },
  {
    name: 'Brazil (Brasil)',
    iso2: 'BR',
    dial: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    iso2: 'IO',
    dial: '246',
  },
  {
    name: 'British Virgin Islands',
    iso2: 'VG',
    dial: '1284',
  },
  {
    name: 'Brunei',
    iso2: 'BN',
    dial: '673',
  },
  {
    name: 'Bulgaria (България)',
    iso2: 'BG',
    dial: '359',
  },
  {
    name: 'Burkina Faso',
    iso2: 'BF',
    dial: '226',
  },
  {
    name: 'Burundi (Uburundi)',
    iso2: 'BI',
    dial: '257',
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    iso2: 'KH',
    dial: '855',
  },
  {
    name: 'Cameroon (Cameroun)',
    iso2: 'CM',
    dial: '237',
  },
  {
    name: 'Canada',
    iso2: 'CA',
    dial: '1',
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    iso2: 'CV',
    dial: '238',
  },
  {
    name: 'Caribbean Netherlands',
    iso2: 'BQ',
    dial: '599',
  },
  {
    name: 'Cayman Islands',
    iso2: 'KY',
    dial: '1345',
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    iso2: 'CF',
    dial: '236',
  },
  {
    name: 'Chad (Tchad)',
    iso2: 'TD',
    dial: '235',
  },
  {
    name: 'Chile',
    iso2: 'CL',
    dial: '56',
  },
  {
    name: 'China (中国)',
    iso2: 'CN',
    dial: '86',
  },
  {
    name: 'Christmas Island',
    iso2: 'CX',
    dial: '61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso2: 'CC',
    dial: '61',
  },
  {
    name: 'Colombia',
    iso2: 'CO',
    dial: '57',
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    iso2: 'KM',
    dial: '269',
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    iso2: 'CD',
    dial: '243',
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    iso2: 'CG',
    dial: '242',
  },
  {
    name: 'Cook Islands',
    iso2: 'CK',
    dial: '682',
  },
  {
    name: 'Costa Rica',
    iso2: 'CR',
    dial: '506',
  },
  {
    name: 'Côte d’Ivoire',
    iso2: 'CI',
    dial: '225',
  },
  {
    name: 'Croatia (Hrvatska)',
    iso2: 'HR',
    dial: '385',
  },
  {
    name: 'Cuba',
    iso2: 'CU',
    dial: '53',
  },
  {
    name: 'Curaçao',
    iso2: 'CW',
    dial: '599',
  },
  {
    name: 'Cyprus (Κύπρος)',
    iso2: 'CY',
    dial: '357',
  },
  {
    name: 'Czech Republic (Česká republika)',
    iso2: 'CZ',
    dial: '420',
  },
  {
    name: 'Denmark (Danmark)',
    iso2: 'DK',
    dial: '45',
  },
  {
    name: 'Djibouti',
    iso2: 'DJ',
    dial: '253',
  },
  {
    name: 'Dominica',
    iso2: 'DM',
    dial: '1767',
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    iso2: 'DO',
    dial: '1',
  },
  {
    name: 'Ecuador',
    iso2: 'EC',
    dial: '593',
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    iso2: 'EG',
    dial: '20',
  },
  {
    name: 'El Salvador',
    iso2: 'SV',
    dial: '503',
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    iso2: 'GQ',
    dial: '240',
  },
  {
    name: 'Eritrea',
    iso2: 'ER',
    dial: '291',
  },
  {
    name: 'Estonia (Eesti)',
    iso2: 'EE',
    dial: '372',
  },
  {
    name: 'Ethiopia',
    iso2: 'ET',
    dial: '251',
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    iso2: 'FK',
    dial: '500',
  },
  {
    name: 'Faroe Islands (Føroyar)',
    iso2: 'FO',
    dial: '298',
  },
  {
    name: 'Fiji',
    iso2: 'FJ',
    dial: '679',
  },
  {
    name: 'Finland (Suomi)',
    iso2: 'FI',
    dial: '358',
  },
  {
    name: 'France',
    iso2: 'FR',
    dial: '33',
  },
  {
    name: 'French Guiana (Guyane française)',
    iso2: 'GF',
    dial: '594',
  },
  {
    name: 'French Polynesia (Polynésie française)',
    iso2: 'PF',
    dial: '689',
  },
  {
    name: 'Gabon',
    iso2: 'GA',
    dial: '241',
  },
  {
    name: 'Gambia',
    iso2: 'GM',
    dial: '220',
  },
  {
    name: 'Georgia (საქართველო)',
    iso2: 'GE',
    dial: '995',
  },
  {
    name: 'Germany (Deutschland)',
    iso2: 'DE',
    dial: '49',
  },
  {
    name: 'Ghana (Gaana)',
    iso2: 'GH',
    dial: '233',
  },
  {
    name: 'Gibraltar',
    iso2: 'GI',
    dial: '350',
  },
  {
    name: 'Greece (Ελλάδα)',
    iso2: 'GR',
    dial: '30',
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    iso2: 'GL',
    dial: '299',
  },
  {
    name: 'Grenada',
    iso2: 'GD',
    dial: '1473',
  },
  {
    name: 'Guadeloupe',
    iso2: 'GP',
    dial: '590',
  },
  {
    name: 'Guam',
    iso2: 'GU',
    dial: '1671',
  },
  {
    name: 'Guatemala',
    iso2: 'GT',
    dial: '502',
  },
  {
    name: 'Guernsey',
    iso2: 'GG',
    dial: '44',
  },
  {
    name: 'Guinea (Guinée)',
    iso2: 'GN',
    dial: '224',
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    iso2: 'GW',
    dial: '245',
  },
  {
    name: 'Guyana',
    iso2: 'GY',
    dial: '592',
  },
  {
    name: 'Haiti',
    iso2: 'HT',
    dial: '509',
  },
  {
    name: 'Honduras',
    iso2: 'HN',
    dial: '504',
  },
  {
    name: 'Hong Kong (香港)',
    iso2: 'HK',
    dial: '852',
  },
  {
    name: 'Hungary (Magyarország)',
    iso2: 'HU',
    dial: '36',
  },
  {
    name: 'Iceland (Ísland)',
    iso2: 'IS',
    dial: '354',
  },
  {
    name: 'India (भारत)',
    iso2: 'IN',
    dial: '91',
  },
  {
    name: 'Indonesia',
    iso2: 'ID',
    dial: '62',
  },
  {
    name: 'Iran (‫ایران‬‎)',
    iso2: 'IR',
    dial: '98',
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    iso2: 'IQ',
    dial: '964',
  },
  {
    name: 'Ireland',
    iso2: 'IE',
    dial: '353',
  },
  {
    name: 'Isle of Man',
    iso2: 'IM',
    dial: '44',
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    iso2: 'IL',
    dial: '972',
  },
  {
    name: 'Italy (Italia)',
    iso2: 'IT',
    dial: '39',
  },
  {
    name: 'Jamaica',
    iso2: 'JM',
    dial: '1876',
  },
  {
    name: 'Japan (日本)',
    iso2: 'JP',
    dial: '81',
  },
  {
    name: 'Jersey',
    iso2: 'JE',
    dial: '44',
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    iso2: 'JO',
    dial: '962',
  },
  {
    name: 'Kazakhstan (Казахстан)',
    iso2: 'KZ',
    dial: '7',
  },
  {
    name: 'Kenya',
    iso2: 'KE',
    dial: '254',
  },
  {
    name: 'Kiribati',
    iso2: 'KI',
    dial: '686',
  },
  {
    name: 'Kosovo',
    iso2: 'XK',
    dial: '383',
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    iso2: 'KW',
    dial: '965',
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    iso2: 'KG',
    dial: '996',
  },
  {
    name: 'Laos (ລາວ)',
    iso2: 'LA',
    dial: '856',
  },
  {
    name: 'Latvia (Latvija)',
    iso2: 'LV',
    dial: '371',
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    iso2: 'LB',
    dial: '961',
  },
  {
    name: 'Lesotho',
    iso2: 'LS',
    dial: '266',
  },
  {
    name: 'Liberia',
    iso2: 'LR',
    dial: '231',
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    iso2: 'LY',
    dial: '218',
  },
  {
    name: 'Liechtenstein',
    iso2: 'LI',
    dial: '423',
  },
  {
    name: 'Lithuania (Lietuva)',
    iso2: 'LT',
    dial: '370',
  },
  {
    name: 'Luxembourg',
    iso2: 'LU',
    dial: '352',
  },
  {
    name: 'Macau (澳門)',
    iso2: 'MO',
    dial: '853',
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    iso2: 'MK',
    dial: '389',
  },
  {
    name: 'Madagascar (Madagasikara)',
    iso2: 'MG',
    dial: '261',
  },
  {
    name: 'Malawi',
    iso2: 'MW',
    dial: '265',
  },
  {
    name: 'Malaysia',
    iso2: 'MY',
    dial: '60',
  },
  {
    name: 'Maldives',
    iso2: 'MV',
    dial: '960',
  },
  {
    name: 'Mali',
    iso2: 'ML',
    dial: '223',
  },
  {
    name: 'Malta',
    iso2: 'MT',
    dial: '356',
  },
  {
    name: 'Marshall Islands',
    iso2: 'MH',
    dial: '692',
  },
  {
    name: 'Martinique',
    iso2: 'MQ',
    dial: '596',
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    iso2: 'MR',
    dial: '222',
  },
  {
    name: 'Mauritius (Moris)',
    iso2: 'MU',
    dial: '230',
  },
  {
    name: 'Mayotte',
    iso2: 'YT',
    dial: '262',
  },
  {
    name: 'Mexico (México)',
    iso2: 'MX',
    dial: '52',
  },
  {
    name: 'Micronesia',
    iso2: 'FM',
    dial: '691',
  },
  {
    name: 'Moldova (Republica Moldova)',
    iso2: 'MD',
    dial: '373',
  },
  {
    name: 'Monaco',
    iso2: 'MC',
    dial: '377',
  },
  {
    name: 'Mongolia (Монгол)',
    iso2: 'MN',
    dial: '976',
  },
  {
    name: 'Montenegro (Crna Gora)',
    iso2: 'ME',
    dial: '382',
  },
  {
    name: 'Montserrat',
    iso2: 'MS',
    dial: '1664',
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    iso2: 'MA',
    dial: '212',
  },
  {
    name: 'Mozambique (Moçambique)',
    iso2: 'MZ',
    dial: '258',
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    iso2: 'MM',
    dial: '95',
  },
  {
    name: 'Namibia (Namibië)',
    iso2: 'NA',
    dial: '264',
  },
  {
    name: 'Nauru',
    iso2: 'NR',
    dial: '674',
  },
  {
    name: 'Nepal (नेपाल)',
    iso2: 'NP',
    dial: '977',
  },
  {
    name: 'Netherlands (Nederland)',
    iso2: 'NL',
    dial: '31',
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    iso2: 'NC',
    dial: '687',
  },
  {
    name: 'New Zealand',
    iso2: 'NZ',
    dial: '64',
  },
  {
    name: 'Nicaragua',
    iso2: 'NI',
    dial: '505',
  },
  {
    name: 'Niger (Nijar)',
    iso2: 'NE',
    dial: '227',
  },
  {
    name: 'Nigeria',
    iso2: 'NG',
    dial: '234',
  },
  {
    name: 'Niue',
    iso2: 'NU',
    dial: '683',
  },
  {
    name: 'Norfolk Island',
    iso2: 'NF',
    dial: '672',
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    iso2: 'KP',
    dial: '850',
  },
  {
    name: 'Northern Mariana Islands',
    iso2: 'MP',
    dial: '1670',
  },
  {
    name: 'Norway (Norge)',
    iso2: 'NO',
    dial: '47',
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    iso2: 'OM',
    dial: '968',
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    iso2: 'PK',
    dial: '92',
  },
  {
    name: 'Palau',
    iso2: 'PW',
    dial: '680',
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    iso2: 'PS',
    dial: '970',
  },
  {
    name: 'Panama (Panamá)',
    iso2: 'PA',
    dial: '507',
  },
  {
    name: 'Papua New Guinea',
    iso2: 'PG',
    dial: '675',
  },
  {
    name: 'Paraguay',
    iso2: 'PY',
    dial: '595',
  },
  {
    name: 'Peru (Perú)',
    iso2: 'PE',
    dial: '51',
  },
  {
    name: 'Philippines',
    iso2: 'PH',
    dial: '63',
  },
  {
    name: 'Poland (Polska)',
    iso2: 'PL',
    dial: '48',
  },
  {
    name: 'Portugal',
    iso2: 'PT',
    dial: '351',
  },
  {
    name: 'Puerto Rico',
    iso2: 'PR',
    dial: '1',
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    iso2: 'QA',
    dial: '974',
  },
  {
    name: 'Réunion (La Réunion)',
    iso2: 'RE',
    dial: '262',
  },
  {
    name: 'Romania (România)',
    iso2: 'RO',
    dial: '40',
  },
  {
    name: 'Russia (Россия)',
    iso2: 'RU',
    dial: '7',
  },
  {
    name: 'Rwanda',
    iso2: 'RW',
    dial: '250',
  },
  {
    name: 'Saint Barthélemy',
    iso2: 'BL',
    dial: '590',
  },
  {
    name: 'Saint Helena',
    iso2: 'SH',
    dial: '290',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso2: 'KN',
    dial: '1869',
  },
  {
    name: 'Saint Lucia',
    iso2: 'LC',
    dial: '1758',
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    iso2: 'MF',
    dial: '590',
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    iso2: 'PM',
    dial: '508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2: 'VC',
    dial: '1784',
  },
  {
    name: 'Samoa',
    iso2: 'WS',
    dial: '685',
  },
  {
    name: 'San Marino',
    iso2: 'SM',
    dial: '378',
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    iso2: 'ST',
    dial: '239',
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    iso2: 'SA',
    dial: '966',
  },
  {
    name: 'Senegal (Sénégal)',
    iso2: 'SN',
    dial: '221',
  },
  {
    name: 'Serbia (Србија)',
    iso2: 'RS',
    dial: '381',
  },
  {
    name: 'Seychelles',
    iso2: 'SC',
    dial: '248',
  },
  {
    name: 'Sierra Leone',
    iso2: 'SL',
    dial: '232',
  },
  {
    name: 'Singapore',
    iso2: 'SG',
    dial: '65',
  },
  {
    name: 'Sint Maarten',
    iso2: 'SX',
    dial: '1721',
  },
  {
    name: 'Slovakia (Slovensko)',
    iso2: 'SK',
    dial: '421',
  },
  {
    name: 'Slovenia (Slovenija)',
    iso2: 'SI',
    dial: '386',
  },
  {
    name: 'Solomon Islands',
    iso2: 'SB',
    dial: '677',
  },
  {
    name: 'Somalia (Soomaaliya)',
    iso2: 'SO',
    dial: '252',
  },
  {
    name: 'South Africa',
    iso2: 'ZA',
    dial: '27',
  },
  {
    name: 'South Korea (대한민국)',
    iso2: 'KR',
    dial: '82',
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    iso2: 'SS',
    dial: '211',
  },
  {
    name: 'Spain (España)',
    iso2: 'ES',
    dial: '34',
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    iso2: 'LK',
    dial: '94',
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    iso2: 'SD',
    dial: '249',
  },
  {
    name: 'Suriname',
    iso2: 'SR',
    dial: '597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso2: 'SJ',
    dial: '47',
  },
  {
    name: 'Swaziland',
    iso2: 'SZ',
    dial: '268',
  },
  {
    name: 'Sweden (Sverige)',
    iso2: 'SE',
    dial: '46',
  },
  {
    name: 'Switzerland (Schweiz)',
    iso2: 'CH',
    dial: '41',
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    iso2: 'SY',
    dial: '963',
  },
  {
    name: 'Taiwan (台灣)',
    iso2: 'TW',
    dial: '886',
  },
  {
    name: 'Tajikistan',
    iso2: 'TJ',
    dial: '992',
  },
  {
    name: 'Tanzania',
    iso2: 'TZ',
    dial: '255',
  },
  {
    name: 'Thailand (ไทย)',
    iso2: 'TH',
    dial: '66',
  },
  {
    name: 'Timor-Leste',
    iso2: 'TL',
    dial: '670',
  },
  {
    name: 'Togo',
    iso2: 'TG',
    dial: '228',
  },
  {
    name: 'Tokelau',
    iso2: 'TK',
    dial: '690',
  },
  {
    name: 'Tonga',
    iso2: 'TO',
    dial: '676',
  },
  {
    name: 'Trinidad and Tobago',
    iso2: 'TT',
    dial: '1868',
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    iso2: 'TN',
    dial: '216',
  },
  {
    name: 'Turkey (Türkiye)',
    iso2: 'TR',
    dial: '90',
  },
  {
    name: 'Turkmenistan',
    iso2: 'TM',
    dial: '993',
  },
  {
    name: 'Turks and Caicos Islands',
    iso2: 'TC',
    dial: '1649',
  },
  {
    name: 'Tuvalu',
    iso2: 'TV',
    dial: '688',
  },
  {
    name: 'U.S. Virgin Islands',
    iso2: 'VI',
    dial: '1340',
  },
  {
    name: 'Uganda',
    iso2: 'UG',
    dial: '256',
  },
  {
    name: 'Ukraine (Україна)',
    iso2: 'UA',
    dial: '380',
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    iso2: 'AE',
    dial: '971',
  },
  {
    name: 'United Kingdom',
    iso2: 'GB',
    dial: '44',
  },
  {
    name: 'United States',
    iso2: 'US',
    dial: '1',
  },
  {
    name: 'Uruguay',
    iso2: 'UY',
    dial: '598',
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    iso2: 'UZ',
    dial: '998',
  },
  {
    name: 'Vanuatu',
    iso2: 'VU',
    dial: '678',
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    iso2: 'VA',
    dial: '39',
  },
  {
    name: 'Venezuela',
    iso2: 'VE',
    dial: '58',
  },
  {
    name: 'Vietnam (Việt Nam)',
    iso2: 'VN',
    dial: '84',
  },
  {
    name: 'Wallis and Futuna (Wallis-et-Futuna)',
    iso2: 'WF',
    dial: '681',
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    iso2: 'EH',
    dial: '212',
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    iso2: 'YE',
    dial: '967',
  },
  {
    name: 'Zambia',
    iso2: 'ZM',
    dial: '260',
  },
  {
    name: 'Zimbabwe',
    iso2: 'ZW',
    dial: '263',
  },
  {
    name: 'Åland Islands',
    iso2: 'AX',
    dial: '358',
  },
];

export default allCountries;

import { render, staticRenderFns } from "./lsUpload.vue?vue&type=template&id=460d3412&scoped=true&"
import script from "./lsUpload.vue?vue&type=script&lang=js&"
export * from "./lsUpload.vue?vue&type=script&lang=js&"
import style0 from "./lsUpload.vue?vue&type=style&index=0&id=460d3412&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460d3412",
  null
  
)

/* custom blocks */
import block0 from "./lsUpload.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
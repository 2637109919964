<template>
  <div>
    <span v-if="this.labels" class="el-form-item" :class="{'is-required': isRequired}"><label class="el-form-item__label">
      {{ $t('ls._statutar.statutoryBody') }}
      <el-popover placement="top-start" width="350" trigger="hover">
        <i class="el-icon-question" slot="reference" />
        <div style="text-align:justify; word-break: keep-all;">
          {{ $t('ls._statutar.statutoryBodyInfo') }}
        </div>
      </el-popover>
    </label></span>
    <div v-for="(item, index) in model.Statutarni_organ" :key="index">
      <el-divider v-if="index" class="hidden-md-and-up statutar-divider" />
      <el-row>
        <el-col :sm="hasBoth ? 12 : 10" :md="hasBoth ? 6 : 10">
          <el-form-item 
            :prop="prop + '.Statutarni_organ.' + index + '.name'"
            :rules="reqRuleGen()">
            <el-input 
              class="statutar-name" 
              @change="attributeNameFunction(item)" 
              @clear="item.function = item.functionEN = ''"
              v-model="item.name" 
              :placeholder="$t('ls._statutar.nameSurname')" 
              :disabled="model.loading" 
              clearable 
            >
              <template slot="prepend" v-if="model.Statutarni_organ.length>1">
                <el-button icon="el-icon-delete" @click="removeArrayItem(model.Statutarni_organ, index)"/>
              </template>
              <template slot="append" v-if="customCheckbox">
                <el-tooltip :content="customCheckboxTooltip" :disabled="customCheckboxTooltip == null" placement="top">
                  <el-checkbox v-model="item.checkbox" :disabled="model.loading"><i v-if="customCheckboxIcon" :class="customCheckboxIcon" /></el-checkbox>
                </el-tooltip>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="hasBoth ? 12 : 6" :md="hasBoth ? 5 : 6" :lg="6">
          <ls-form-lang :lang="lang" :rules="reqRuleGen()">
            <template #cz="{form}">
              <el-form-item v-bind="form" :prop="prop + '.Statutarni_organ.' + index + '.function'">
                <el-input @change="attributeNameFunction(item)" v-model="item.function" :placeholder="$t('ls._statutar.function')" :disabled="model.loading" clearable />
              </el-form-item>
            </template>

            <template #en="{form}">
              <el-form-item v-bind="form" :prop="prop + '.Statutarni_organ.' + index + '.functionEN'">
                <el-input @change="attributeNameFunction(item)" v-model="item.functionEN" :placeholder="$t('ls._statutar.functionEN')" :disabled="model.loading" clearable />
              </el-form-item>
            </template>
          </ls-form-lang>
        </el-col>
        <!-- person level contacts -->
        <el-col :sm="hasBoth ? 12 : 8" :md="hasBoth ? 6 : 8" v-if="statutarEmail !== undefined">
          <ls-input-email
            v-model="item.email"
            :label="null"
            :prop="prop + '.Statutarni_organ.' + index + '.email'"
            :required="statutarEmail == 'required'"
            :disabled="model.loading"
          />
        </el-col>
        <el-col :sm="hasBoth ? 12 : 8" :md="hasBoth ? 7 : 8" :lg="6" v-if="statutarPhone !== undefined">
          <ls-input-phone 
            v-model="item.phone" 
            :label="null"
            :ip="false"
            :prop="prop + '.Statutarni_organ.' + index + '.phone'"
            :required="statutarPhone == 'required'"
            :disabled="model.loading"
          />
        </el-col>
      </el-row>
    </div>
    <el-button type="primary" @click="addArrayItem(model.Statutarni_organ, {name:'', function:'', name_function:'', email: '', phone: ''})">
      <i class="el-icon-plus"/> {{ $t('ls._statutar.add') }}
    </el-button>
    <br><br>
    <template v-if="model.Statutarni_organ_text">
      <el-alert show-icon :closable="false" :title="model.Statutarni_organ_text" type="info"/>
      <br><br>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .statutar-name::v-deep {
    .el-input-group__prepend {
      padding: 0 10px;
    }
  }
  .statutar-divider::v-deep {
    margin-top: 0;
  }
</style>
<script>
  export default {
    props: {
      value: {},
      prop: {
        type: String,
        required: true
      },
      labels: {
        type: Boolean,
        default: true
      },
      required: {
        type: Boolean,
        default: false
      },
      lang: {
        type: Array,
        default: () => ['cz']
      },

      statutarPhone: undefined,
      statutarEmail: undefined,

      customCheckbox: {
        type: Boolean,
        default: false
      },
      customCheckboxIcon: {
        type: String,
        default: null
      },
      customCheckboxTooltip: {
        type: String,
        default: null
      },
    },
    watch: {
      model:{
        handler(val) {
          this.$emit('input', val);
        },
        deep: true
      },
    },
    data() {
      return {
        model: this.value,
      }
    },
    computed: {
      isRequired() {
        return this.required !== undefined && this.required != false;
      },

      hasBoth() {
        return this.statutarPhone !== undefined && this.statutarEmail !== undefined;
      }
    },
    methods: {
      attributeNameFunction(item) {
        item.name_function = item.name + ', ' + item.function;
      },
      labelGen(label) {
        return this.labels == false ? '' : label;
      },
      reqRuleGen(rule = {}) {
        return this.isRequired ? [this.rl.required, rule] : [rule];
      },

    },

  };
</script>

<i18n>
{
  "en": {
    "ls": {
      "_statutar": {
        "statutoryBody": "Statutory Body:",
        "statutoryBodyInfo": "You can remove members of the statutory body (representatives) by clicking on the Trash icon, which appears next to each representative if there are at least two. You can simply delete the representative's details by clicking on the Cross inside the field on the right.",
        "nameSurname": "Name and surname",
        "function": "Function",
        "functionEN": "Function in English",
        "add": "Add"
      }
    }
  },
  "cz": {
    "ls": {
      "_statutar": {
        "statutoryBody": "Statutární orgán:",
        "statutoryBodyInfo": "Členy statutárního orgánu (zástupce) můžete odstranit kliknutím na ikonu Koše, která se zobrazí u každého zástupce, pokud jsou alespoň dva. Údaje o zástupci můžete jednoduše smazat kliknutím na Křížek vpravo unvnitř pole.",
        "nameSurname": "Jméno a příjmení",
        "function": "Funkce",
        "functionEN": "Function in English",
        "add": "Přidat"
      }
    }
  }
}
</i18n>

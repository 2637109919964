<template>
  <div>
    <BankIdButton
        :text="$t('public.signature.signBankId')"
        :loading="loading"
        @click="create"
    />
  </div>
</template>

<script>
  import BankIdButton from "./BankIdButton.vue";

  export default {
    components: {BankIdButton},
    props: {
      token: String,
      code: String,
    },
    data() {
      return {
        loading: false,
      }
    },
    methods: {
      create() {
        this.loading = true;
        this.$http.post(this.hostname + '/public/bankid/sign/create', {
          token: this.token,
          code: this.code,
        }).then((response) => {
          window.location.href = response.data.url;
        }).catch((error) => {
          this.$alert(error.response.data.message, 'Pozor');
        }).finally(() => this.loading = false);
      }
    }
  }
</script>

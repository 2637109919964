<template>
  <div class="bankid-button__container" v-loading="loading">
    <a class="bankid-button__button" @click="$emit('click')">
      <img :src="hostname + '/images/bankid.svg'" alt="Bank iD" class="bankid-button__img" />
      <span>{{ text }}</span>
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      text: String,
      loading: {
        type: Boolean,
        default: false,
      },
    }
  }
</script>

<style scoped lang="scss">
  .bankid-button__container {
    text-align: center;
  }
  .bankid-button__button {
    background: black;
    border-radius: 8px;
    color: white;
    padding: 9px 12px;
    text-decoration: none;
    display: inline-flex;
    cursor: pointer;
  }
  .bankid-button__img {
    width: 70px;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid white;
  }
</style>

let apiURL = "/external-api/obchodny-rejstrik/"; 

let searchOr = function(url) {
  const req = Vue.prototype.$api.get(apiURL + url)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return Promise.reject(error);
    });

    return req;
}

export default {

    findSubject(ic) {
      return searchOr('get/' + ic);
    },

    findDic(ic) {
      return searchOr('dic/' + ic);
    },

    findSubjectBasic(type, param) { //alias for findSubject
      return searchOr('getBasic?' + type + '=' + param);
    },

}

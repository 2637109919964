<template>
  <div class="flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <H2Title>{{ title }}</H2Title>
      <div class="flex">
        <el-button @click="signUndo" size="small"><i class="fas fa-undo"></i></el-button>
        <el-button @click="signClear" size="small"><i class="fas fa-trash-alt"></i></el-button>
      </div>
    </div>
    <div class="signatureWrapper">
      <div class="signaturepadBorder" :style="style">
        <VueSignaturePad
          :width="width"
          :height="height"
          class="signaturepad"
          ref="signaturepad"
          :options="options"
        ></VueSignaturePad>
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-1">
        <el-color-picker
          v-model="color"
          @change="updateOptions"
          :predefine="colors">
        </el-color-picker>
        <div class="w-24 ml-3">
          <el-slider
            v-model="slider"
            :min="1"
            :max="5"
            @input="updateSlider"
            show-stops>
          </el-slider>
        </div>
      </div>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .signatureWrapper {
    min-width: 200px;
    min-height: 100px;
    width: 50vw;
    height: 25vw;
  }

  @media (max-width: 800px), (max-height: 500px) {
    .signatureWrapper {
      width: 100%;
    }
  }

  @media (max-width: 800px) and (orientation:portrait) {
    .signatureWrapper {
      height: 50vw;
      margin-top: 12vh;
    }
  }

  @media (orientation:landscape) {
    @media (max-width: 800px), (max-height: 500px) {
      .signatureWrapper {
        height: calc(100vh - 170px);
      }
    }
  }

  .signaturepadBorder {
    border-radius: 4px;
    border: 3px double #dcdfe6;
  }

  .signaturepad {
    position: relative;
    background-image: url("/images/signature.svg");
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: 50%;
  }
  .signaturepad:before {
    background-color: black;
    opacity: 0.1;
    content: "";
    display: block;
    width: 90%;
    height: 1px;
    position: absolute;
    left: 5%;
    top: calc(66% - 1px);
  }

</style>

<script>
  import H2Title from "../Tailwind/H2Title.vue";

  export default {
    components: {H2Title},
    props: {
      value: String,
      ratio: {
        type: Number,
        required: false,
        default: 2
      },
      title: {
        type: String,
        required: false,
        default: null
      }
    },
    data () {
      return {
        slider: 3,
        color: '#1D82E4',
        colors: ['#000000', '#666666', '#999999', '#B73286', '#1D82E4', '#02A2A4', '#64C264', '#ffd700', '#ff8c00', '#D3331A'],
        width: "100%",
        height: "100%",
        sign: null,
        options: null,
      }
    },
    computed: {
      style () {
        return "width: "+ this.width + "; height: " + this.height;
      }
    },

    created() {
      this.updateOptions();
    },

    methods: {
      resizeCanvas() {
        window.dispatchEvent(new Event('resize'));
      },

      resizeContent() {
        const svg = this.$refs.signaturepad.toData();

        const allPoints = svg.reduce((acc, lines) => {
          return acc.concat(lines.points);
        }, []);

        const padding = 10;

        var minX = allPoints.reduce((min, p) => p.x < min ? p.x : min, allPoints[0].x)-padding;
        var minY = allPoints.reduce((min, p) => p.y < min ? p.y : min, allPoints[0].y)-padding;
        var maxX = allPoints.reduce((max, p) => p.x > max ? p.x : max, allPoints[0].x)+padding;
        var maxY = allPoints.reduce((max, p) => p.y > max ? p.y : max, allPoints[0].y)+padding;

        var width = maxX - minX;
        var height = maxY - minY;

        //output window should match certain aspect ratio
        if(this.ratio) {
          var ratio = width/height;
          if(ratio < this.ratio) {
            var idealWidth = height*this.ratio;
            var diff = (idealWidth-width)/2;
            minX -= diff;
            maxX += diff;
            width = idealWidth;
          }
          else if(ratio > this.ratio) {
            var idealHeight  = width/this.ratio;
            var diff = (idealHeight-height)/2;
            minY -= diff;
            maxY += diff;
            height = idealHeight;
          }
        }

        svg.forEach((lines, li) => {
          lines.points.forEach((point, pi) => {
            point.x -= minX;
            point.y -= minY;
          });
        });
        this.width = (width)+"px";
        this.height = (height)+"px";
        this.$refs.signaturepad.fromData(svg);
      },

      async done() {
        if(this.$refs.signaturepad.isEmpty()) {
          this.sign = null;
          return this.sign;
        }

        this.resizeContent();
        await this.$nextTick();
        this.resizeCanvas();

        const { isEmpty, data } = this.$refs.signaturepad.saveSignature("image/svg+xml");
        this.sign = !isEmpty ? data : null;
        return this.sign;
      },

      updateOptions() {
        this.options = {
          throttle: 2,
          minDistance: 2,
          minWidth: 0.4+0.3*this.slider,
          maxWidth: 0.6+0.7*this.slider,
          penColor: this.color
        };
      },

      async updateSlider() {
        this.updateOptions();
        //hotfix refresh for resizing
        if(this.$refs.signaturepad) {
          await this.$nextTick();
          this.$refs.signaturepad.fromData(this.$refs.signaturepad.toData());
        }
      },

      signUndo() {
        this.$refs.signaturepad.undoSignature();
        this.updateOptions();
      },
      signClear() {
        this.$refs.signaturepad.clearSignature();
        this.updateOptions();
      },

    }
  }
</script>

<template>
  <el-input :class="editable == undefined ? 'copyInput' : ''" ref="signField" v-bind:value="model" v-on:input="input">
    <el-button slot="append" @click="copy(value)">
      <i v-if="copied === true" class="el-icon-document-checked"></i>
      <i v-else-if="copied === null" class="el-icon-document-copy"></i>
      <i v-else-if="copied === false" class="el-icon-document-delete"></i>
      {{ $t('copy') }}
    </el-button>
  </el-input>
</template>

<style type="text/css" scoped>
  .copyInput::v-deep .el-input__inner {
    cursor: not-allowed;
  }
</style>

<script>
  export default {
    props: [
      'value',
      'editable'
    ],
    data() {
      return {
        model: this.value,
        copied: null
      };
    },
    watch: {
      value(val) {
        this.model = this.value;
      }
    },
    methods: {
      input(val) {
        if(this.editable != undefined) {
          this.model = val;
          this.$emit('input', val);
        }
      },
      copy(val) {
        navigator.clipboard.writeText(val).then(() => {
          this.copied = true;
        }).catch(() => {
          this.copied = false;
        });

        this.$refs['signField'].select();
      }

    },

  };
</script>

<i18n>
  {
    "cz": {
      "copy": "Zkopírovat"
    },
    "sk": {
      "copy": "Skopírovať"
    },
    "en": {
      "copy": "Copy"
    }
  } 
</i18n>

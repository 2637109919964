<template>
  <div>
    <el-upload
      class="docx-uploader"
      :accept="accept"
      drag
      :disabled="disabled"
      action="#"
      :multiple="multiple"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :show-file-list="showFileList"
      :http-request="loadFile">
      <slot v-if="$slots.default"></slot>
      <template v-else>
        <i class="el-icon-upload" />
        <div class="el-upload__text">
          {{$t('ls.upload.text')}}
        </div>
      </template>
    </el-upload>
    <center v-if="showFileList !== false">
      <small>
        <span v-if="maxFileSize">{{$t('ls.upload.max')}} {{currentFileSize}}/{{maxFileSize}} MB. </span>
        {{$t('ls.upload.compress')}} 
        <a target="_blank" href="https://www.adobe.com/cz/acrobat/online/compress-pdf.html">&gt; {{$t('ls.upload.this')}} &lt;</a>
      </small>
    </center>
    <el-dialog append-to-body v-if="imageResize" :visible.sync="dialogVisible" :width="imageResize+'px'">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
  .el-icon-upload {
    margin-top: 25px;
  }

  .imageUpload::v-deep {
    .el-upload__input {
      display: none !important;
    }
     
    .el-upload-dragger {
      width: auto;
      height: auto;
      line-height: 128px;
      width: 158px;
      margin: 0 auto;
    }
     
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 158px;
      height: 120px;
      line-height: 120px;
      text-align: center;
    }
    .avatar {
      max-width: 158px;
      max-height: 128px;
    }
  }
</style>

<script>
  export default {
    name: 'LsUpload',
    props: {
      value: Array,
      disabled: {
        type: Boolean,
        default: false
      },
      imageResize: {
        type: Number,
        default: 1000
      },
      multiple: {
        type: Boolean,
        default: true
      },
      showFileList: {
        type: Boolean,
        default: undefined
      },
      pdfConvert: {
        type: Boolean,
        default: true
      },
      accept: {
        type: String,
        default: undefined
      },
      maxFileSize: {
        type: Number,
        default: 8
      }
    },
    data () {
      return {
        //attachments
        dialogImageUrl: '',
        dialogVisible: false,

        fileList: []
      }
    },
    computed: {
      currentFileSize() {
        if(this.fileList?.length == 0) {
          return 0;
        }

        return this.toMb(this.fileList?.reduce((x, y) => x + y.size, 0));
      }
    },
    mounted() {
      if(this.value)
        this.fileList = this.value;
  	},
    methods: {
      //convert KB to MB
      toMb(value) {
        return Math.round(value / 1024 / 1024 * 100) / 100
      },

      emit() {
        this.$emit('input', this.fileList);

        let base = this.fileList.map(x => { 
          return {
            name: x.name,
            base64: this.toBase64URI(x)
          }
        });
        if(this.multiple)
          this.$emit('base64', base);
        else
          this.$emit('base64', base[0]);
      },

      handleSuccess(response, file, fileList) {
        this.fileList = fileList;
        this.emit();
      },

      handleRemove(file, fileList) {
        this.fileList = fileList;
        this.emit();
      },

      handleError(e, file=null, fileList=null) {
        console.log(e);
        console.log(file);
        this.$catch(e, this.$t('ls.upload.error'));
      },

      handlePreview(file) {
        var base64 = this.toBase64URI(file);
        if(this.imageResize && (file.response.mime == 'image/jpeg' || file.response.mime == 'image/png')) {
          this.dialogImageUrl = base64;
          this.dialogVisible = true;
        }
        else {
          const downloadLink = document.createElement('a');
          document.body.appendChild(downloadLink);

          downloadLink.href = base64;
          downloadLink.download = file.response.name;
          downloadLink.target = '_self';
          downloadLink.click();
        }
      },

      beforeUpload(file) {
        if(!this.multiple)
          this.fileList = [];

        return this.checkFileSize(file);
      },

      checkFileSize(file) {
        if(!this.maxFileSize) {
          return true;
        }

        if (this.imageResize && (file.type == 'image/jpeg' || file.type == 'image/png')) {
          return true;
        }

        let newFileSize = this.currentFileSize + this.toMb(file.size);

        let isSmall = newFileSize < this.maxFileSize;
        if (!isSmall) {
          this.$message.error(this.$t('ls.upload.max') + ' ' + this.maxFileSize + ' MB.');
        }

        return isSmall;
      },

      toBase64URI(file) {
        if(!file?.response?.mime || !file.response.file)
          return;

        if(file.response.mime == 'image/jpeg') 
          return "data:image/jpg;base64,"+file.response.file;
        else 
          return 'data:'+file.response.mime+';base64,'+ file.response.file;
      },

      async loadFile(options) {
        let original = options.file;
        let name = original.name;
        let mime = original.type;

        try {
          var output = await this.convertToBase64(original);
        }
        catch(e) {
          return this.handleError(e);
        }

        if(this.pdfConvert && this.isMicrosoftOfficeDocument(mime)) {
          let converted = await this.convertBase64ToPdf(output, name, mime);
          output = converted.file;
          name = converted.name;
          mime = converted.mime;
        }

        this.fileList.push({
          name: name,
          size: output.length,
          response: {
            name: name,
            mime: mime,
            file: output,
          }
        });
        this.emit();
      },

      async convertBase64ToPdf(file, name, mime) {
        let output = await this.$api.post('proculus-api/podpisy/upload', {
          name: name,
          mime: mime,
          base64: file
        }).then(x => x.data);

        return {
          name: output.name,
          mime: output.mime,
          file: output.file
        };
      },

      convertToBase64(file) {
          if(this.imageResize && (file.type == 'image/jpeg' || file.type == 'image/png')) {
            return this.imgToBase64(this.imageResize, file);
          }
          
          return this.fileToBase64(file);
      },

      fileToBase64(file) {
        return new Promise((resolve, reject) => {
          const FR = new FileReader();
          FR.readAsDataURL(file);
          FR.onerror = reject;
          FR.onload = () => resolve(FR.result.split(',')[1]);
        });
      },

      isMicrosoftOfficeDocument(mime) {
        return mime == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          mime == 'application/msword' ||
          mime == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          mime == 'application/vnd.ms-excel';
      },

      imgToBase64(size, file) {
        return new Promise((resolve, reject) => {
          var FR = new FileReader();
          FR.readAsDataURL(file);
          FR.onerror = reject;
          FR.onloadend = () => {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext("2d");

            var img = new Image();
            img.crossOrigin = "Anonymous"; //cors support
            img.onload = () => {
              var W = img.width;
              var H = img.height;
              canvas.width = W;
              canvas.height = H;
              ctx.drawImage(img, 0, 0); //draw image

              // We deal with image resize, but keeping aspect ratio
              var ratio = Math.min(size / W, size / H);
              this.resample_single(canvas, W * ratio, H * ratio, true);
              
              resolve(canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ""));
            };
            img.src = FR.result;
          };
        });
      },

      /**
       * Hermite resize - fast image resize/resample using Hermite filter. 1 cpu version!
       * 
       * @param {HtmlElement} canvas
       * @param {int} width
       * @param {int} height
       * @param {boolean} resize_canvas if true, canvas will be resized. Optional.
       */
      resample_single(canvas, width, height, resize_canvas) {
        var width_source = canvas.width;
        var height_source = canvas.height;
        width = Math.round(width);
        height = Math.round(height);

        var ratio_w = width_source / width;
        var ratio_h = height_source / height;
        var ratio_w_half = Math.ceil(ratio_w / 2);
        var ratio_h_half = Math.ceil(ratio_h / 2);

        var ctx = canvas.getContext("2d");
        var img = ctx.getImageData(0, 0, width_source, height_source);
        var img2 = ctx.createImageData(width, height);
        var data = img.data;
        var data2 = img2.data;

        for (var j = 0; j < height; j++) {
          for (var i = 0; i < width; i++) {
            var x2 = (i + j * width) * 4;
            var weight = 0;
            var weights = 0;
            var weights_alpha = 0;
            var gx_r = 0;
            var gx_g = 0;
            var gx_b = 0;
            var gx_a = 0;
            var center_y = (j + 0.5) * ratio_h;
            var yy_start = Math.floor(j * ratio_h);
            var yy_stop = Math.ceil((j + 1) * ratio_h);
            for (var yy = yy_start; yy < yy_stop; yy++) {
              var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
              var center_x = (i + 0.5) * ratio_w;
              var w0 = dy * dy; //pre-calc part of w
              var xx_start = Math.floor(i * ratio_w);
              var xx_stop = Math.ceil((i + 1) * ratio_w);
              for (var xx = xx_start; xx < xx_stop; xx++) {
                var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
                var w = Math.sqrt(w0 + dx * dx);
                if (w >= 1) {
                  //pixel too far
                  continue;
                }
                //hermite filter
                weight = 2 * w * w * w - 3 * w * w + 1;
                var pos_x = 4 * (xx + yy * width_source);
                //alpha
                gx_a += weight * data[pos_x + 3];
                weights_alpha += weight;
                //colors
                if (data[pos_x + 3] < 255)
                    weight = weight * data[pos_x + 3] / 250;
                gx_r += weight * data[pos_x];
                gx_g += weight * data[pos_x + 1];
                gx_b += weight * data[pos_x + 2];
                weights += weight;
              };
            };
            data2[x2] = gx_r / weights;
            data2[x2 + 1] = gx_g / weights;
            data2[x2 + 2] = gx_b / weights;
            data2[x2 + 3] = gx_a / weights_alpha;
          };
        };
        //clear and resize canvas
        if (resize_canvas === true) {
          canvas.width = width;
          canvas.height = height;
        } else {
          ctx.clearRect(0, 0, width_source, height_source);
        };

        //draw
        ctx.putImageData(img2, 0, 0);
      }

    }
  }
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "upload": {
          "text": "Přetáhněte přílohu zde, nebo klikněte pro nahrání",
          "max": "Maximální velikost souborů je",
          "compress": "Pro zmenšení velikosti doporučujeme soubor zkomprimovat například",
          "this": "zde",
          "error": "Nahrávání se nezdařilo"
        }
      }
    },
    "en": {
      "ls": {
        "upload": {
          "text": "Drag the attachment here, or click to upload",
          "max": "Maximum file size is",
          "compress": "To reduce file size you can use for example",
          "this": "this",
          "error": "Upload failed"
        }
      }
    },
    "pl": {
      "ls": {
        "upload": {
          "text": "Przeciągnij załącznik tutaj lub kliknij, aby przesłać",
          "max": "Maksymalny rozmiar pliku to",
          "compress": "Aby zmniejszyć rozmiar pliku, możesz go skompresować, na przykład",
          "this": "tutaj",
          "error": "Przesyłanie nie powiodło się"
        }
      }
    },
    "hu": {
      "ls": {
        "upload": {
          "text": "Húzza ide a csatolmányt, vagy kattintson a feltöltéshez",
          "max": "A maximális fájlméret",
          "compress": "A fájl méretének csökkentéséhez használhatja például",
          "this": "ezt",
          "error": "A feltöltés nem sikerült"
        }
      }
    }
  }
</i18n>

<template>
  <el-form ref="form" status-icon :model="doc" label-position="top" v-cloak v-show="vueLoaded" v-loading="vueLoading">

    <!-- intro dialog -->
    <el-dialog
      :close-on-click-modal="false" :close-on-press-escape="false"
      :show-close="false"
      append-to-body
      width="90%"
      custom-class="lsDialog customCard"
      v-loading="smsLoading"
      :visible.sync="introDialog">
      <el-card class="box-card settings-card green-card">
        <div class="message">
          <template v-if="useravatar">
            <img :src="useravatar" class="user-avatar">
          </template>
          <template v-else>
            <i class="fas fa-user user-avatar" style="padding-top: 20px; color: #666 !important"></i>
          </template>
          <div>
            <div class="h4">
              <template v-if="username">
                <span style="color: #033; font-weight: bold;">{{ username }}<template v-if="clientname"> ({{ clientname }})</template></span> {{ $t('open.title.user') }}
              </template>
              <template v-else>{{ $t('open.title.someone') }}</template>
              <template v-if="isReview"> {{ $t('open.title.revision') }} </template>
              <template v-else> {{ $t('open.title.signature') }} </template>
              <span class="h4" style="color: #033; font-weight: bold;">{{ title }}</span>
            </div>
            <div v-if="message" style="font-style: italic;">"{{ message }}"</div>
          </div>
        </div>
        <div class="clear"></div>

        <div class="h4" style="font-size: 16px">
          <template v-if="isReview">
            {{ $t('open.steps.revision') }}
          </template>
          <template v-else>
            {{ $t('open.steps.signature') }}
          </template>
        </div>
        <el-steps class="customSteps" direction="vertical" :active="-1">
          <el-step v-if="verif">
            <template slot="title">{{ $t('open.steps.1') }}<template v-if="doc.subject.phone"> {{ $t('open.steps.1phone') }} {{doc.subject.phone}}</template>.</template>
          </el-step>
          <el-step :title="$t('open.steps.2')"></el-step>
          <template v-if="isReview">
            <el-step :title="$t('open.steps.3r')"></el-step>
          </template>
          <template v-else>
            <el-step :title="$t('open.steps.3s')"></el-step>
            <el-step :title="$t('open.steps.4')"></el-step>
          </template>
        </el-steps>
      </el-card>
      <template slot="footer">
        <template v-if="verif">
          <el-form-item prop="code" ref="code" style="display: inline-block; margin-bottom: 10px;" :rules="[
            rl.required,
            { min: 4, max: 8, message: $t('open.foot.codeLength') }
          ]">
            <el-input v-model="doc.code" :placeholder="$t('open.foot.code')" style="width: 150px; margin-right: 10px"></el-input>
          </el-form-item>
          <el-button type="primary" @click="checkCode"><i class="el-icon-right"></i> {{ $t('open.foot.buttonC') }}</el-button>
        </template>
        <el-button v-else type="primary" @click="introDialog = false"><i class="el-icon-right"></i> {{ $t('open.foot.button') }}</el-button>
        <div style="color: #999; font-style: italic; margin-top: 15px;">
          <template v-if="!isReview">
            {{ $t('open.foot.disclaimer') }}
          </template>
          <template v-if="verif && doc.subject.phone"><br>
            <i18n path="open.foot.resend">
              <template v-slot:action>
                <el-tooltip placement="top-start" :disabled="smsCanResend" :content="$t('open.foot.resendTooltip')" :enterable="false">
                  <el-button :disabled="!smsCanResend" type="text" @click="resendCode">{{ $t('open.foot.resendAction') }}</el-button>
                </el-tooltip>
              </template>
            </i18n>
            <div v-if="smsResendMore" style="color: #F56C6C">{{ $t('open.foot.resendMore') }}</div>
          </template>
        </div>
      </template>
    </el-dialog>

    <!-- finish dialog -->
    <el-dialog
      :visible.sync="finishDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      width="90%"
      top="50vh"
      custom-class="customCard2">
      <div>
        <img :src="hostname+'/images/web/icon-smlouvy.svg'" style="margin: 0 auto">
        <div class="h4" style="margin: 30px 0">
          {{ $t('finish.done') }}
          <template v-if="refuseDialog">{{ $t('finish.refuse') }}</template>
          <template v-else-if="!isReview">{{ $t('finish.sign') }}</template>
        </div>
        <div style="color: #999; margin-bottom: 20px; word-break: break-word;">
          <i18n path="finish.call">
            <template v-slot:cta1>
              <a href="https://legalsystems.cz/elektronicky-podpis">{{ $t('finish.cta1') }}</a>
            </template>
          </i18n>
        </div>
        <el-button type="primary" plain size="small" @click="closePage">{{ $t('finish.close') }}</el-button>
      </div>
    </el-dialog>

    <!-- refuse dialog -->
    <el-dialog
      :visible.sync="refuseDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="90%"
      top="50vh"
      custom-class="customCard2 customCard3">
      <div slot="title"><div class="h4"><i class="fas fa-ban"></i> {{ $t('refuse.title') }}</div></div>
      <div v-loading="onProgress">
        <div style="color: #999; margin-bottom: 20px">
          <i18n path="refuse.text">
            <template v-slot:action>
              <template v-if="isReview">{{ $t('refuse.actionR') }}</template>
              <template v-else>{{ $t('refuse.actionR') }}</template>
            </template>
          </i18n>
        </div>
        <el-input
          type="textarea"
          :rows="2"
          :placeholder="$t('refuse.placeholder')"
          v-model="refuseText">
        </el-input>
        <br><br>
        <div style="text-align: right;">
          <el-button plain size="small" @click="refuseDialog = false">{{ $t('refuse.cancel') }}</el-button>
          <el-button type="danger" size="small" @click="refuseIt">{{ $t('refuse.confirm') }}</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- KYC dialog -->
    <el-dialog v-if="kyc && kycCheck"
        :visible.sync="kycDialog"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="90%"
        top="50px">
        <div slot="title"><div class="h4"><i class="fas fa-passport"></i> {{ $t('public.general.identityVerification') }}</div></div>

        <!-- for signature -->
        <template v-if="kycDialog">
            <ls-kyc-micropayment v-if="!allowPastMicropayment"
                :symbol="kycCheck.micropayment_vs"
                :account="kycCheck.micropayment_cu"
                :comment="kycCheck.micropayment_comment"
                @completed="allowPastMicropayment = true"
            />

            <ls-new-kyc v-else
                :existing-kyc-uuid="kycCheck.uuid"
                :require-additional-document="kycCheck.additional_document_required"
                @finished="kycFinish"
            />
        </template>
    </el-dialog>

    <!-- preview card -->
    <el-card class="box-card" style="overflow: inherit" v-if="!finishDialog">
      <div
        v-if="loading"
        class="loadingPlaceholder"
        element-loading-background="#FFFFFF99"
        v-loading="true"
        :element-loading-text="$t('preview.loading') + '...'"
      ></div>
      <div id="pageContainer" v-loading="onProgress">
        <div id="viewer" class="pdfViewer"></div>
      </div>
      <div id="toolbox" v-loading="onProgress" :element-loading-text="$t('preview.progress') + (isReview ? $t('preview.progressR') : $t('preview.progressS')) + '...'">
        <div id="scrollDown" class="display-xs" style="display: none">
          <el-button size="mini" type="primary" circle plain icon="el-icon-arrow-down" @click="scrollToSignature"></el-button>
        </div>
        <div class="toolboxWrapper">
          <div class="pdftools">
            <el-button-group>
              <el-button type="info" size="mini" icon="el-icon-minus" @click="zoom(-0.2)"></el-button>
              <el-button type="info" size="mini" icon="el-icon-plus" @click="zoom(0.2)"></el-button>
            </el-button-group>
            <div>{{ pdf.pageNumber }} / {{ pdf.pageCount }}</div>
            <el-button-group>
              <el-button type="info" size="mini" icon="el-icon-arrow-left" @click="prevPage"></el-button>
              <el-button type="info" size="mini" icon="el-icon-arrow-right" @click="nextPage"></el-button>
            </el-button-group>
          </div>
          <div style="height: 20px;"></div>
          <div class="signtools display-md" :key="signatureUpdated" v-if="!windowSmall">

            <ls-kyc-overview v-if="kyc" :data="kycData"></ls-kyc-overview>

            <template v-if="showCheckIdentityButton">
              <el-button @click="kycStart">
                {{ $t('public.kyc.verifyIdentity') }}
              </el-button>
            </template>

            <!-- without kyc OR kyc is accepted -->
            <div class="flex flex-col space-y-3" v-else>
              <template v-if="signtype == 'signature' && !isReview">
                <bank-id-sign-button
                    v-if="doc.subject.bankid"
                    :token="token"
                    :code="doc.code"
                />
                <ls-signature
                  v-else
                  label=""
                  :png="type == 'internal'"
                  required
                  buttons="down"
                  v-model="doc.subject.sign"
                  :phone="doc.subject.phone"
                  :subject="doc.subject.zastupce || doc.subject.name"
                  :no-margin="true"
                  :default-input-method="defaultInputMethod"
                ></ls-signature>
                <div class="h4" style="font-weight: bold;"><template v-if="isReview">{{ $t('preview.personR') }}</template><template v-else>{{ $t('preview.personS') }}</template> {{ doc.subject.zastupce || doc.subject.name }}</div>
                <div>
                  <span class="inline-block m-1.5" v-if="doc.subject.sign"><el-button type="success" :disabled="loading" @click="downloadPromt()"><i class="el-icon-check"></i> {{ $t('preview.finish') }}</el-button></span>
                  <span class="inline-block m-1.5" v-if="refuse" ><el-button type="danger" :disabled="loading" @click="refusePromt()"><i class="el-icon-close"></i> {{ $t('preview.refuse') }}</el-button></span>
                  <span class="inline-block m-1.5"><el-button @click="download()" plain><i class="el-icon-download"></i> {{ $t('preview.download') }}</el-button></span>
                </div>
              </template>
              <template v-else>
                <el-form-item prop="subject.signBox" :rules="[{ required: true, message: $t('preview.agreeWarning') }]" style="margin-bottom: 0">
                  <el-checkbox v-model="doc.subject.signBox" border>{{ $t('preview.agree') }}</el-checkbox>
                </el-form-item>
                <div class="h4" style="font-weight: bold;"><template v-if="isReview">{{ $t('preview.personR') }}</template><template v-else>{{ $t('preview.personS') }}</template> {{ doc.subject.zastupce || doc.subject.name }}</div>
                <div>
                  <span class="inline-block m-1.5" v-if="doc.subject.signBox"><el-button type="success" :disabled="loading" @click="downloadPromt()"><i class="el-icon-check"></i> {{ $t('preview.finish') }}</el-button></span>
                  <span class="inline-block m-1.5" v-if="refuse" ><el-button type="danger" :disabled="loading" @click="refusePromt()"><i class="el-icon-close"></i> {{ $t('preview.refuse') }}</el-button></span>
                  <span class="inline-block m-1.5"><el-button @click="download()" plain><i class="el-icon-download"></i> {{ $t('preview.download') }}</el-button></span>
                </div>
              </template>
            </div>

          </div>
        </div>
      </div>
      <div class="signtools display-xs" :key="signatureUpdated" v-if="windowSmall">
        <ls-kyc-overview v-if="kyc" :data="kycData"></ls-kyc-overview>

        <template v-if="showCheckIdentityButton">
          <el-button @click="kycStart">
            {{ $t('public.kyc.verifyIdentity') }}
          </el-button>
        </template>

        <!-- without kyc OR kyc is accepted -->
        <div class="flex flex-col space-y-3" v-else>
          <template v-if="signtype == 'signature' && !isReview">
            <bank-id-sign-button
                v-if="doc.subject.bankid"
                :token="token"
                :code="doc.code"
            />
            <ls-signature
              v-else
              label=""
              :png="type == 'internal'"
              required
              buttons="down"
              v-model="doc.subject.sign"
              :phone="doc.subject.phone"
              :subject="doc.subject.zastupce || doc.subject.name"
              :no-margin="true"
              :default-input-method="defaultInputMethod"
            ></ls-signature>
            <div class="h4" style="font-weight: bold;"><template v-if="isReview">{{ $t('preview.personR') }}</template><template v-else>{{ $t('preview.personS') }}</template> {{ doc.subject.zastupce || doc.subject.name }}</div>
            <div>
              <span class="inline-block m-1.5" v-if="doc.subject.sign"><el-button type="success" :disabled="loading" @click="downloadPromt()"><i class="el-icon-check"></i> {{ $t('preview.finish') }}</el-button></span>
              <span class="inline-block m-1.5" v-if="refuse" ><el-button type="danger" :disabled="loading" @click="refusePromt()"><i class="el-icon-close"></i> {{ $t('preview.refuse') }}</el-button></span>
              <span class="inline-block m-1.5"><el-button @click="download()" plain><i class="el-icon-download"></i> {{ $t('preview.download') }}</el-button></span>
            </div>
          </template>
          <template v-else>
            <el-form-item prop="subject.signBox" :rules="[{ required: true, message: $t('preview.agreeWarning') }]" style="margin-bottom: 0">
              <el-checkbox v-model="doc.subject.signBox" border>{{ $t('preview.agree') }}</el-checkbox>
            </el-form-item>
            <div class="h4" style="font-weight: bold;"><template v-if="isReview">{{ $t('preview.personR') }}</template><template v-else>{{ $t('preview.personS') }}</template> {{ doc.subject.zastupce || doc.subject.name }}</div>
            <div>
              <span class="inline-block m-1.5" v-if="doc.subject.signBox"><el-button type="success" :disabled="loading" @click="downloadPromt()"><i class="el-icon-check"></i> {{ $t('preview.finish') }}</el-button></span>
              <span class="inline-block m-1.5" v-if="refuse" ><el-button type="danger" :disabled="loading" @click="refusePromt()"><i class="el-icon-close"></i> {{ $t('preview.refuse') }}</el-button></span>
              <span class="inline-block m-1.5"><el-button @click="download()" plain><i class="el-icon-download"></i> {{ $t('preview.download') }}</el-button></span>
            </div>
          </template>
        </div>
      </div>
    </el-card>

  </el-form>
</template>

<style lang="scss" scoped>
  .message {
    display: flex;
    align-items: center;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 20px;
    background: #FBFBFB;
    flex-direction: column;
    text-align: center;

  }

  .message > * {
    margin: 5px;
  }

  .user-avatar {
    background-color: #DCDFE6;
    height: 60px;
    width: 60px;
    font-size: 20px;
    border-radius: 60px;
    text-align: center;
    display: inline-block !important;
  }

  .el-step.is-vertical {
    min-height: 35px;
  }
  .signtools::v-deep .signatureimg {
    margin-left: auto;
    margin-right: auto;
  }

  .loadingPlaceholder {
    height: 50vh;
    background: url('/images/dummy-preview.png') top center;
    background-size: cover;
    border: 1px solid #EEE;
  }

  .h4 {
    word-break: break-word;
  }

  #pageContainer {
    border: 1px solid #DDD;
    overflow-x: auto;

    .page {
      outline: 1px solid #DDD;
      margin-bottom: 20px;
    }

    .page:last-child {
      margin-bottom: 0;
    }
  }

  #toolbox {
    position: sticky;
    bottom: 0;

    .toolboxWrapper {
      background: white;
    }

    .pdftools {
      border: 1px solid #DDD;
      background: #EEE;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .signtools {
    padding: 20px;
    padding-top: 0;
    text-align: center;
  }

  #scrollDown {
    text-align: center;
    margin-bottom: 20px;
  }

  .display-xs {
    display: none;
    visibility: hidden;
  }

  @media (max-width: 450px), (max-height: 750px) {
    .display-xs {
      display: block;
      visibility: visible;
    }

    .display-md {
      display: none;
      visibility: hidden;
    }
  }
</style>

<style lang="scss">
  .customCard {
    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
    }

    .box-card {
      border: 0;
      margin-bottom: 0;
    }

    .el-dialog__footer {
      padding-top: -10px;
      text-align: center;
    }

    .customSteps .is-wait {
      color: #555;
    }

    .customSteps .el-step__line {
      background: #666;
    }

    .customSteps .el-step__icon.is-text {
      border-color: #666;
    }
  }

  .customCard2 {
    text-align: center;
    word-break: break-word;
    max-width: 800px;
    margin-bottom: -50vh;
    transform: translate(0, -50%);

    .el-dialog__body {
      padding: 10px 30px 30px;
    }
  }

  .customCard3 {
    text-align: left;
    word-break: break-word;
  }
</style>

<script>
  import LsKycMicropayment from '@/components/checks/kyc/micropayment'
  import axiosRetry from 'axios-retry';
  import BankIdSignButton from "../bankid/BankIdSignButton.vue";

  const PdfJs = require('pdfjs-dist/es5/build/pdf');
  require('@/../css/pdf_viewer.css');
  PdfJs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/es5/build/pdf.worker.entry.js');
  const PdfJsViewer = require('pdfjs-dist/es5/web/pdf_viewer');

  export default {
    components: {
        BankIdSignButton,
        LsKycMicropayment
    },
    props: {
      id: Number,
      type: String,
      token: String,
      verif: Boolean,
      kyc: {
        type: Boolean,
        default: false
      },
      kycCheck: {
        type: Object,
        default: null
      },
      signtype: {
        type: String,
        default: 'signature'
      },
      subj: Object,
      sign: String,
      title: String,
      filename: String,
      message: String,
      username: String,
      useravatar: String,
      clientname: String,
      resendcodefn: Function,
      checkcodefn: Function,
      signitfn: Function,
      refuseitfn: Function,
      refuse: {
        type: Boolean,
        default: true
      },
      fetchpdffn: Function,
      close: String,
      finished: Boolean,
      defaultInputMethod: null
    },
    data() {
      return {
        //PDF viewer
        pdf: {
          container: null,
          eventBus: null,
          pdfViewer: null,
          defaultScale: null,
          pageNumber: 1,
          pageCount: 1,
          pageHeight: 0
        },
        pdfFile: null,

        //helpers
        introDialog: true,
        loading: true,
        onProgress: false,
        finishDialog: false,
        refuseDialog: false,
        refuseText: null,
        windowSmall: window.innerWidth <= 450 || window.innerHeight <= 750,
        signatureUpdated: 0,

        //sms
        smsCanResend: false,
        smsLoading: false,
        smsResendMore: false,

        //kyc
        kycDialog: false,
        kycData: null,
        allowPastMicropayment: this.kycCheck?.micropayment === false,

        //submitted data
        doc: {
          subject: {
            name: null,
            email: null,
            phone: null,
            date: null,
            sign: null,
            signBox: null,
          },
          code: null
        },
      };
    },
    created() {
      if (this.finished) {
        this.introDialog = false;
        this.finishDialog = true;
        return;
      }

      Object.assign(this.doc.subject, this.subj);
      axiosRetry(this.$api, { retries: 3 });

      window.addEventListener("resize", this.resizeHandler);

      if(this.sign) {
        this.doc.subject.sign = this.sign;
      }
    },

    mounted() {
      if (this.finished) {
        return;
      }

      if(!this.doc.subject.codeCount || this.doc.subject.codeCount < 5)
        setTimeout(() => { this.smsCanResend = true; }, 60000);

      setTimeout(() => { this.smsResendMore = true; }, 75000);

      this.preparePdf();
      if(!this.verif)
        this.fetchPdf();
    },
    destroyed() {
      window.removeEventListener("resize", this.resizeHandler);
    },
    computed: {
      url() {
        return "/external-api/sign/"+this.type+"/"+this.id+"/";
      },
      isReview() {
        return this.doc.subject.type == 'remote-review';
      },
      showCheckIdentityButton() {
        if (! this.kyc) {
          return false;
        }
        if (! this.kycCheck) {
          return false;
        }
        if (this.kycData && this.kycData.status === 'ACCEPTED') {
          return false;
        }
        if (this.kycCheck.is_document_skippable && this.kycData && this.kycData.status === 'FAILED') {
          return false;
        }
        return true;
      }
    },
    watch: {
      "doc.subject.sign": function() {
        this.signatureUpdated++;
      }
    },

    methods: {
      kycStart() {
        this.kycDialog = true;
        this.kycData = null;
      },

      kycFinish(data) {
        this.kycDialog = false;
        this.kycData = data;
      },

      download() {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = 'data:application/pdf;base64,'+ this.pdfFile;
        downloadLink.download = this.filename;
        downloadLink.target = '_self';
        downloadLink.click();
      },

      resizeHandler() {
        setTimeout(() => {
          this.windowSmall = window.innerWidth <= 450 || window.innerHeight <= 750;
        }, 50);
      },

      resendCode() {
        //new resend can be made in XY seconds
        this.smsCanResend = false;

        var fn;
        if(this.resendcodefn) {
          fn = this.resendcodefn();
        }
        else {
          var url = "code/"+this.doc.subject.id+"/"+this.token+"/resend";
          fn = this.$api.get(this.url + url);
        }

        fn.then(res => {
          this.doc.subject.codeCount = res.data;

          if(this.doc.subject.codeCount < 5)
            setTimeout(() => { this.smsCanResend = true; }, 120000);
        }).catch(error => {
          this.$message.error(this.$t("message.error.resend"));
          console.log(error);
          this.$sentry.captureException(error);
        });
      },

      checkCode() {
        if(this.$refs['code'].validateState != 'success') {
          this.$refs['code'].validate();
          this.$message({
            showClose: true,
            message: this.$t("message.alert"),
            type: 'error'
          });
          return false;
        }

        this.smsLoading = true;

        var fn;
        if(this.checkcodefn) {
          fn = this.checkcodefn(this.doc.code);
        }
        else {
          var url = "code/"+this.doc.subject.id+"/"+this.token+"/check/"+this.doc.code;
          fn = this.$api.get(this.url + url);
        }
        fn.then(res => {
          this.smsLoading = false;
          if(res.data == "ok") {
            this.introDialog = false;
            this.fetchPdf();
          }
          else {
            this.$message.error('Byl zadán chybný SMS kód.');
          }
        }).catch(error => {
          this.$message.error('Při ověřování kódu došlo k chybě. Náš tým byl informován.');
          this.$sentry.captureException(error);
        });
      },

      closePage() {
        window.location.href = this.close ? this.close : "https://legalsystems.cz/";
      },

      downloadPromt() {
        this.$refs['form'].validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: this.$t("message.alert"),
              type: 'error'
            });
            return false;
          }

          //for review just go directly
          if(this.isReview)
            this.signIt();
          else
            this.$confirm(this.$t("message.confirm"), this.$t("message.warning"), {
              confirmButtonText: this.$t("message.sign"),
              cancelButtonText: this.$t("message.cancel"),
              type: 'warning'
            }).then(() => {
              this.signIt();
            }).catch(() => {});

        });
      },

      refusePromt() {
        this.refuseDialog = true;
      },

      refuseIt() {
        this.onProgress = true;

        var fn;
        if(this.refuseitfn) {
          fn = this.refuseitfn(this.doc.subject, this.verif ? this.doc.code : undefined);
        }
        else {
          fn = this.$api.post(this.url + 'refuse', {
            subject: JSON.stringify(this.doc.subject),
            code: this.verif ? this.doc.code : undefined,
            message: this.refuseText
          });
        }
        fn.then(res => {
          this.onProgress = false;
          this.finishDialog = true; //@TODO
        }).catch(res => {
          this.onProgress = false;

          if(res.response && res.response.status) {
            if(res.response.status == 404 ||
              res.response.status == 403 ||
              res.response.status == 401) {
              this.$alert(res.response.status, this.$t("message.warning"), {type: "error"});
            }
          }

          this.$sentry.captureException(res);
          this.$alert(this.$t("message.error.refuse"), this.$t("message.warning"), {type: "error"});
        });
      },

      signIt() {
        this.onProgress = true;

        var fn;
        if(this.signitfn) {
          fn = this.signitfn(this.doc.subject, this.verif ? this.doc.code : undefined);
        }
        else {
          fn = this.$api.post(this.url, {
            subject: JSON.stringify(this.doc.subject),
            code: this.verif ? this.doc.code : undefined,
            kycCheckUuid: this.kycCheck?.uuid
          });
        }
        fn.then(res => {
          this.onProgress = false;
          this.finishDialog = true;
        }).catch(res => {
          this.onProgress = false;

          if(res.response && res.response.status) {
            if(res.response.status == 404 ||
              res.response.status == 403 ||
              res.response.status == 401) {
              this.$alert(res.response.status, this.$t("message.warning"), {type: "error"});
            }
          }

          this.$sentry.captureException(res);
          this.$alert(this.$t("message.error.sign"), this.$t("message.warning"), {type: "error"});
        });
      },

      //fetch file from backend and display it
      fetchPdf() {
        var fn;
        if(this.fetchpdffn) {
          var fn = this.fetchpdffn(this.verif ? this.doc.code : undefined);
        }
        else {
          var url = "getFile/"+this.doc.subject.id+"/"+this.token;
          //if verification enabled, send code
          if(this.verif)
            url += "/"+this.doc.code;
          fn = this.$api.get(this.url + url);
        }
        fn.then(res => {
          this.loading = false;

          this.pdfFile = res.data;
          var data = atob(res.data);

          //display current PDF
          PdfJs.getDocument({ data: data }).promise.then(pdfDocument => {
            this.pdf.pdfViewer.setDocument(pdfDocument);
            this.pdf.pageCount = pdfDocument.numPages;
            this.handleScrollDown();
          });
        }).catch(res => {
          console.log(res);
          this.$sentry.captureException(res);
          this.$alert(this.$t("message.error.fetch"), this.$t("message.warning"), {type: "error"});
        });
      },

      preparePdf() {
          this.pdf.container = document.getElementById("pageContainer");
          this.pdf.eventBus = new PdfJsViewer.EventBus();
          this.pdf.pdfViewer = new PdfJsViewer.PDFViewer({
            container: this.pdf.container,
            eventBus: this.pdf.eventBus,
            removePageBorders: true,
          });

          this.pdf.eventBus.on("pagesinit", this.recalculatePdfWidth);
          window.addEventListener('resize', this.recalculatePdfWidth);

          window.onscroll = () => {
            var pn = Math.ceil((window.pageYOffset + this.pdf.pageHeight*0.3) / this.pdf.pageHeight);
            if(pn == 0)
              this.pdf.pageNumber = 1;
            else if(pn > this.pdf.pageCount)
              this.pdf.pageNumber = this.pdf.pageCount;
            else
              this.pdf.pageNumber = pn;

            this.handleScrollDown();
          };
      },

      handleScrollDown() {
        if(document.getElementById("scrollDown")) {
          if(this.pdf.pageNumber == this.pdf.pageCount) {
            document.getElementById("scrollDown").style.display = 'none';
          }
          else {
            document.getElementById("scrollDown").style.display = 'block';
          }
        }
      },

      zoom(zoomVal) {
        var newScale = this.pdf.pdfViewer.currentScale + zoomVal;
        if(zoomVal < 0 && newScale < this.pdf.defaultScale/2) {
          this.pdf.pdfViewer.currentScale = this.pdf.defaultScale/2;
          return;
        }

        if((newScale - this.pdf.defaultScale) > 2) return;

        this.pdf.pdfViewer.currentScale += zoomVal;
        this.pdf.pageHeight = document.getElementById("pageContainer").offsetHeight / this.pdf.pageCount;
      },

      nextPage() {
        if(this.pdf.pageNumber >= this.pdf.pageCount) return;

        window.scrollTo({top: document.querySelector('[data-page-number="'+(this.pdf.pageNumber+1)+'"]').offsetTop - 80, behavior: 'smooth'});
      },

      prevPage() {
        if(this.pdf.pageNumber <= 1) return;

        window.scrollTo({top: document.querySelector('[data-page-number="'+(this.pdf.pageNumber-1)+'"]').offsetTop - 80, behavior: 'smooth'});
      },

      scrollToSignature() {
        document.querySelector('.signtools').scrollIntoView({behavior: 'smooth'});
      },

      recalculatePdfWidth() {
        this.pdf.pdfViewer.currentScaleValue = "page-width";
        this.pdf.defaultScale = this.pdf.pdfViewer.currentScale;
        this.pdf.pageHeight = document.getElementById("pageContainer")?.offsetHeight / this.pdf.pageCount;
      }
    }
  };
</script>

<i18n>
  {
    "cz": {
      "open": {
        "title": {
          "user": "Vám posílá",
          "someone": "Byl Vám zaslán",
          "revision": "k revizi dokument",
          "signature": "k podpisu dokument"
        },
        "steps": {
          "revision": "Schvalování dokumentů:",
          "signature": "Podepište se elektronicky, je to jednoduché:",
          "1": "Zadejte ověřovací kód",
          "1phone": ", který Vám byl právě odeslán přes SMS na číslo",
          "2": "Klikněte na tlačítko a projděte si zaslaný dokument.",
          "3r": "Na konci je možnost jej schválit či zamítnout.",
          "3s": "Na konci je možnost jej elektronicky podepsat na počítači, smartphonu či tabletu.",
          "4": "Po potvrzení přijde podepsaný originál na e-mail všem účastníkům."
        },
        "foot": {
          "button": "Přejít k dokumentu",
          "buttonC": "Ověřit kód a přejít k dokumentu",
          "code": "Ověřovací kód",
          "codeLength": "Heslo musí mít 4-8 znaků",
          "disclaimer": "Pokud nic podepisovat nechcete, můžete stránku zavřít.",
          "resend": "Nepřišel Vám ověřovací kód? Zkuste si ho {action}.",
          "resendAction": "zaslat znovu",
          "resendTooltip": "Nové zaslání kódu je možné až po 2 minutách.",
          "resendMore": "Pořád nic? Zkontrolujte telefonní číslo případně zkuste restartovat telefon."
        }
      },
      "preview": {
        "loading": "Dokument se načítá",
        "progress": "Dokument se ",
        "progressS": "podepisuje",
        "progressR": "schvaluje",
        "personR": "Schvalující",
        "personS": "Podepisující",
        "finish": "Dokončit",
        "refuse": "Odmítnout",
        "download": "Stáhnout",
        "agree": "Souhlasím se zněním dokumentu",
        "agreeWarning": "Je nutné odsouhlasit znění dokumentu"
      },
      "finish": {
        "done": "Hotovo. ",
        "refuse": "Odesílatel dokumentu obdrží Vaši poznámku.",
        "sign": "Podepsaný dokument obdržíte na e-mail, jakmile jej podepíší všichni účastníci.",
        "close": "Opustit stránku",
        "call": "Chcete se dozvědět více o elektronickém podepisování? Podívejte se na {cta1}.",
        "cta1": "náš web"
      },
      "refuse": {
        "title": "Odmítnutí dokumentu",
        "text": "Uveďte důvody, proč nechcete dokument {action}.",
        "actionS": "podepsat",
        "actionR": "schválit",
        "placeholder": "Sem můžete uvést důvody.",
        "cancel": "Zrušit",
        "confirm": "Potvrdit"
      },
      "message": {
        "alert": "Zkontrolujte zadané údaje.",
        "warning": "Pozor!",
        "confirm": "Elektronický podpis je závazný, opravdu chcete dokument podepsat? Po potvrzení obdrží všichni účastníci na e-mail podepsaný originál.",
        "sign": "Podepsat",
        "cancel": "Zrušit",
        "error": {
          "resend": "Při odesílání kódu došlo k chybě. Náš tým byl informován.",
          "refuse": "Při ukladání došlo k chybě. Náš tým byl informován.",
          "sign": "Při podepisování došlo k chybě. Náš tým byl informován.",
          "fetch": "Při načítání dokumentu došlo k chybě. Náš tým byl informován."
        }
      }
    },
    "en": {
      "open": {
        "title": {
          "user": "sends you",
          "someone": "Someone sends you",
          "revision": "this document for revision:",
          "signature": "this document to be signed:"
        },
        "steps": {
          "revision": "Document revision:",
          "signature": "Electronic signature is easy as:",
          "1": "Enter a verification code",
          "1phone": "that just has been sent via SMS to telephone number",
          "2": "Click on the button below and check the document.",
          "3r": "You can approve or reject it.",
          "3s": "You can sign it electronically on your computer, smartphone or tablet.",
          "4": "At the end of process, the signed original will be sent to all participants by e-mail."
        },
        "foot": {
          "button": "Open the document",
          "buttonC": "Verify code and open the document",
          "code": "Verification code",
          "codeLength": "Code must be between 4-8 characters",
          "disclaimer": "In case you do not want to sign the document, please close this website.",
          "resend": "In case you have not received any code, please try to {action}.",
          "resendAction": "resend it",
          "resendTooltip": "Resending new code is only possible after 2 minutes.",
          "resendMore": "Still nothing? Check the phone number or try restarting the phone."
        }
      },
      "preview": {
        "loading": "Document is loading",
        "progress": "Document is being ",
        "progressS": "signed",
        "progressR": "approved",
        "personR": "Approving",
        "personS": "Signing",
        "finish": "Finish",
        "refuse": "Refuse",
        "download": "Download",
        "agree": "I agree with the document",
        "agreeWarning": "The document must be approved"
      },
      "finish": {
        "done": "Done. ",
        "refuse": "The sender of the document will receive your note.",
        "sign": "You will receive a signed document by e-mail as soon as all participants have signed it.",
        "close": "Leave the page",
        "call": "Want to know more about electronic signing? look at {cta1}.",
        "cta1": "our website"
      },
      "refuse": {
        "title": "Rejection of the document",
        "text": "You can describe why you don't want to {action} the document.",
        "actionS": "sign",
        "actionR": "approve",
        "placeholder": "Here you can provide explanation.",
        "cancel": "Cancel",
        "confirm": "Confirm"
      },
      "message": {
        "alert": "Check the entered data.",
        "warning": "Warning!",
        "confirm": "The electronic signature is legally binding, do you really want to sign the document? After confirmation, all participants will receive a signed original by e-mail.",
        "sign": "Sign",
        "cancel": "Cancel",
        "error": {
          "resend": "An error occurred while sending the code. Our team has been informed.",
          "refuse": "An error occurred while saving. Our team has been informed.",
          "sign": "An error occurred while signing. Our team has been informed.",
          "fetch": "An error occurred while loading the document. Our team has been informed."
        }
      }
    }
  }
</i18n>

<template>
  <div>
    <draggable v-model="model" @update="updateItem" :options="{ handle: '.handle' }">
      <div v-for="(item, index) in model" :key="index">
        <el-divider class="itemDivider" v-if="divider && index" />

        <el-row type="flex" :gutter="0" style="gap: 20px;">
          <div v-if="draggable">
            <el-tooltip effect="dark" :content="$t('ls.loop.reorder')" placement="left">
              <ls-button :disabled="model.length == 1" class="handle" icon="fas fa-arrows-up-down" style="cursor: grab;" />
            </el-tooltip>
          </div>
          <div style="width: 100%;">
            <div v-if="label" class="itemLabel h4">{{ label }} {{ index + 1 }}</div>
            <LsFormItemProvider :root="vModel" :index="item.index">
              <slot name="item" :item="item" />
            </LsFormItemProvider>
          </div>
          <div v-if="remove">
            <el-button :disabled="!removeEnabled" type="danger" plain @click="removeItem(index)"><i class="fa-regular fa-trash-can" /></el-button>
          </div>
        </el-row>
      </div>
    </draggable>

    <el-form-item v-if="create">
      <el-button :disabled="!createEnabled" type="primary" plain @click="createItem()"><i class="fa-solid fa-plus" /> {{ createText }}</el-button>  
    </el-form-item>
  </div>
</template>

<style lang="scss" scoped>
  .itemLabel {
    margin-top: 0;
    padding: 10px 10px 8px;
    background-color: #FAFAFA;
    border-radius: 8px;
  }

  .itemDivider {
    margin-top: 0;
  }
</style>

<script>
export default {
  components: {
    /**
     * provide root and index for ls-form-item automatically
    */
    LsFormItemProvider: {
      inject: {
        lsFormItem: {
          default: null
        }
      },      
      props: ['root', 'index'],
      computed: {
        //allow nested ls-loop with correctly computed root
        computedRoot() {
          if(!this.lsFormItem?.lsLoop) {
            return this.root;
          }

          let base = `${this.lsFormItem.root}.${this.lsFormItem.index}`;
          let root = Vue.component('el-form-item').extendOptions.methods._removeRoot(this.root);
          root = Vue.component('el-form-item').extendOptions.methods._removeModel(root);

          return base + (root ? `.${root}` : '');
        }
      },
      provide() {
        return {
          lsFormItem: {
            index: this.index,
            root: this.computedRoot,
            lsLoop: true
          }
        }
      },
      template: `<div><slot/></div>`
    }
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    schema: {
      type: Object | String,
      default: ""
    },

    label: {
      type: String,
      default: ''
    },

    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },

    draggable: {
      type: Boolean,
      default: true
    },
    create: {
      type: Boolean,
      default: true
    },
    createText: {
      type: String,
      default: 'Přidat'
    },
    remove: {
      type: Boolean,
      default: true
    },
    divider: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      model: null,
      watchers: []
    }
  },

  computed: {
    createEnabled() {
      return !this.max || (this.model.length < this.max)
    },
    removeEnabled() {
      return !this.min || (this.model.length > this.min)
    },
    vModel() {
      return this.$vnode?.data?.model?.expression;
    }
  },

  watch: {
    value(val) {
      this.nest(val);
    },
  },
  
  created() {
    this.nest(this.value);
  },

  methods: {
    /**
     * Map value to object, so we can use side-effect when exposing data trough slot
     */
    nest(val) {
      //map object
      let out = val.map((x, i) => {
        return {
          index: i,
          model: x,
        }
      });

      //does array contain objects only? 
      let nested = val.find(x => typeof x != 'object') === undefined

      //remove old watchers
      if(!nested) {
        this.watchers.forEach(x => x());
        this.watchers = [];
      }

      //overwrite model
      this.model = out;

      //set new subdata watchers if we have non-object data values in array (so we can emit data without subprop side-effect)
      if(!nested) {
        this.$nextTick(() => {
          out.forEach((_, i) => {
            this.watchers.push(this.$watch('model.'+i+'.model', this.updateItem))
          });
        });
      }
    },

    /**
     * get array without remapped object from nest()
     */
    flatten(val) {
      return val.map(x => x.model);
    },

    updateItem() {
      this.$emit('input', this.flatten(this.model));
    },
    createItem() {
      this.$emit('input', [...this.flatten(this.model), this.schema]);
    },
    removeItem(index) {
      let out = this.flatten(this.model);
      this.removeArrayItem(out, index);
      this.$emit('input', out);
    }
  }
}
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "loop": {
          "reorder": "Změnit pořadí"
        }
      }
    },
    "sk": {
      "ls": {
        "loop": {
          "reorder": "Zmeniť poradie"
        }
      }
    },
    "en": {
      "ls": {
        "loop": {
          "reorder": "Reorder"
        }
      }
    }
  } 
</i18n>

<template>
<div class="ls-select">
  <!-- 
    el-select does not support prepend/append slots, 
    so we need to fix it manually by CSS classes
  -->
  <div :class="'el-input el-input-group ' + (button == 'append' ? 'el-input-group--append' : 'el-input-group--prepend') + ($slots.append ? 'el-input-group--append' : '') + ($slots.prepend ? 'el-input-group--prepend' : '')">
    <el-select 
    :key="'lssel'+key"
    v-model="model"
    :disabled="disabled"
    clearable
    filterable
    :allow-create="allowCreate"
    :multiple="multiple"
    :placeholder="placeholder"
    @change="change">
      <el-option v-for="(x, i) in dataMerged" :key="i" :label="x.value" :value="x"></el-option>
    </el-select>
    <div :class="button == 'append' ? 'el-input-group__append' : 'el-input-group__prepend'">
      <el-button :slot="button" @click="dialogFormVisible = true">
        <i v-if="loading" class="el-icon-loading"></i>
        <i v-else class="el-icon-s-operation"></i>
      </el-button>
    </div>
    <div v-if="button == 'append' && $slots.prepend" class="el-input-group__prepend">
      <slot name="prepend"></slot>
    </div>
    <div v-if="button == 'prepend' && $slots.append" class="el-input-group__append">
      <slot name="append"></slot>
    </div>
  </div>

  <el-dialog
    custom-class="lsDialog" 
    append-to-body
    title="Úprava číselníku" 
    :visible.sync="dialogFormVisible" 
    @close="store" 
    :before-close="validate">
    <el-form :model="data" ref="enum">

      <el-tabs v-model="loc" type="card" v-if="storage == 'combined' && !$context.role.superuser">
        <el-tab-pane name="user" label="moje"></el-tab-pane>
        <el-tab-pane name="client" :label="'sdílené - všichni'"></el-tab-pane>
      </el-tabs>

      <draggable v-model="data[loc]" :options="{ handle: '.fa-bars' }" v-loading="loading">
        <template v-if="!multi">
          <el-form-item class="c_data" v-for="(item, index) in data[loc]" :prop="loc+'.'+index+'.value'" :key="index" :rules="[rl.required]">
            <el-input v-model="item.value" :key="index">
              <template slot="prepend"><i class="fas fa-bars" style="cursor: grab;"></i></template>
              <el-button type="danger" slot="append" @click="removeArrayItem(data[loc], index)"><i class="el-icon-delete"></i></el-button>
            </el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-row :gutter="20" v-for="(item, index) in data[loc]" :key="index">
            <div v-if="index" class="autoSpacing hidden-sm-and-up"></div>
            <el-col :sm="colWidth">
              <el-form-item class="c_data" :prop="loc+'.'+index+'.value'" :rules="[rl.required]">
                <el-input v-model="item.value" :placeholder="placeholder">
                  <template slot="prepend"><i class="fas fa-bars" style="cursor: grab;"></i></template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="colWidth" v-for="(mx, ix) in multi" :key="index+'-'+ix">
              <el-form-item class="c_data" :prop="loc+'.'+index+'.'+ix" :rules="mx.validators ? mx.validators : []">
                <el-input v-model="item[ix]" :placeholder="mx.placeholder ? mx.placeholder : null">
                  <el-button v-if="isLast(ix)" type="danger" slot="append" @click="removeArrayItem(data[loc], index)"><i class="el-icon-delete"></i></el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </draggable>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button style="float:left" type="primary" @click="newItem(loc)">
        <i class="el-icon-plus"></i> Přidat položku
      </el-button>
      <el-button @click="validate(() => dialogFormVisible = false)">Zavřít</el-button>
    </span>
  </el-dialog>

</div>
</template>

<style lang="scss" scoped>
  .c_data {
    margin-bottom:20px !important;
  }

  .autoSpacing {
    margin-bottom: 20px;
  }

  .ls-select::v-deep {
    .el-input-group--append .el-select .el-input.is-focus .el-input__inner {
      border-color: #0096B3;
    }
  }
</style>

<script>
  export default {
    name: 'LsSelect',
    props: {
      value: null,
      placeholder: null,
      disabled: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      //variable name (eg. bank, or orderId)
      suggestions: {
        type: String,
        required: true
      }, 
      button: {
        type: String,
        default: "append"
      },
      allowCreate: {
        type: Boolean,
        default: true
      },
      //client, user or combined
      storage: {
        type: String,
        default: "user"
      },
      //handle multiple input, eg:
      //{ email: { validators: [rl.required, rl.email], placeholder: 'E-mail' } }
      multi: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        key: 0,
        loc: null,
        data: {
          user: [],
          client: []
        },
        model: this.value,
        dialogFormVisible: false,
        loading: true,
        multiObj: {}
      }
    },
    computed: {
      colWidth() {
        if(!this.multi)
          return 24;
        return 24/(Object.keys(this.multi).length+1);
      },
      dataMerged() {
        if(this.storage == 'combined')
          var out = this.data["user"].concat(this.data["client"]);
        else
          var out = this.data[this.storage];

        if(this.model) {
          var vals = new Set(out.map(d => d.value));
          out = [...out, ...this.model.filter(d => !vals.has(d.value))];
        }

        return out;
      }
    },
    watch: {
      value(val) {
        this.model = val;
        this.key++;
      },
    },    
    created: async function() {
      //handle multiple input
      if(this.multi) {
        Object.keys(this.multi).forEach(el => {
          this.multiObj[el] = '';
        });
      }

      this.loading = true;
      var s1, s2;

      this.loc = this.storage;
      if(this.storage == 'combined')
        this.loc = 'user';

      if(this.storage == 'user' || this.storage == 'combined')
        s1 = this.getData("user");
      if((this.storage == "client" || this.storage == 'combined') && !this.$context.role?.superuser)
        s2 = this.getData("client");

      await s1;
      await s2;
      this.loading = false;
    },
    methods: {
      validate(done) {
        this.$refs['enum'].validate((valid) => {
          if (valid)
            return done();
          this.$message({
            showClose: true,
            message: 'Zkontrolujte zadané údaje.',
            type: 'error'
          });
          return false;
        });        
      },

      store: async function() {
        if(!this.loading) { //store only if not loading
          this.loading = true;
          var s1, s2, s3;

          if(this.storage == 'user' || this.storage == 'combined')
            s1 = this.setData("user");
          if((this.storage == 'client' || this.storage == 'combined') && !this.$context.role?.superuser)
            s2 = this.setData("client");

          await s1;
          await s2;
          await s3;
          this.loading = false;
        }
      },

      //handle multiple input
      isLast(index) {
        return Object.keys(this.multi)[Object.keys(this.multi).length-1] == index;
      },

      newItem(loc) {
        //handle multiple input
        var obj = {value:''};
        if(this.multiObj)
          obj = _.merge(obj, this.multiObj);

        this.addArrayItem(this.data[loc], obj);
      },
      change(val) {
        var out = val.map(x => {
          if(typeof x === "string") {
            var obj = _.merge({ value: '' }, this.multiObj);
            obj.value = x;
            return obj;
          }
          else
            return x;
        });
        this.$nextTick(()=>{
          this.$emit('input', out);
          this.$emit('change', out);
        });
      },

      getData(loc) {
        return this.$api.get('/proculus-api/getCustomData/'+loc+'/' + this.suggestions)
        .then(res => {
          if(res.data || res.data === []) {
            this.data[loc] = this.data[loc].concat(res.data);
          }
        })
        .catch(this.justFail);
      },

      setData(loc) {
        return this.$api.post('/proculus-api/setCustomData/'+loc+'/' + this.suggestions, {
          data: this.data[loc],
        }).catch(this.justFail);
      },

      justFail(error) {
        this.$sentry.captureException(error);
      }
    }
  }
</script>

<template>
  <div class="input-with-validate">
    <el-form-item
      :label="label"
      :help="help"
      :prop="cProp"
      ref="input-email-item"
      :rules="[required ? rl.required : {}, rl.email]">
      <component
        :is="contacts ? 'ContactAutocomplete' : 'el-input'"
        value-key="email"
        :placeholder="placeholder"
        @change="checkApi"
        @input="callOnInput"
        :value="value"
        :disabled="loading || disabled">
        
        <!-- allow input slots -->
        <slot name="prepend" slot="prepend" v-if="$slots.prepend" />
        <slot name="append" slot="append" v-if="$slots.append" />

        <!-- display status in prepend slot if append is used externally -->
        <template :slot="$slots.append ? 'prepend' : 'append'">
          <template v-if="loading">
            <i class="fa-fw fas fa-spinner fa-spin" />
          </template>
          <template v-else-if="isEmailApiValid">
            <el-tooltip :content="$t('ls.inputEmail.validTooltip')">
              <i class="fa-fw fas fa-check" />
            </el-tooltip>
          </template>
        </template>

      </component>
      <small class="input-warning">
        <transition name="fade">
          <span v-if="showWarning">
            {{ $t('ls.inputEmail.correction') }} 
            <el-button class="input-warning__button" size="mini" type="text" @click="changeSufix">{{ this.warningEmail }}</el-button>?
          </span>
          <span v-if="showWarningApi">
            {{ warningApiMessage }}
          </span>
        </transition>
      </small>
    </el-form-item>
  </div>
</template>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.input-warning{
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  line-height: normal;
  color: #E6A23C;

  &__button{
    padding: 0;
  }
}
</style>

<script>
import ContactAutocomplete from "./ContactAutocomplete/ContactAutocomplete.vue";
export default {
  components: {
    ContactAutocomplete
  },
  props: {
    value: String,
    label: {
      type: String,
      default: function(){
        return this.$t('ls.inputEmail.label');
      }
    },
    help: String,    
    placeholder: {
      type: String,
      default: function(){
        return this.$t('ls.inputEmail.placeholder');
      }
    }, 
    prop: String,
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showWarning: false,
      showWarningApi: false,
      loading: false,
      warningEmail: "",
      warningApiMessage: "",
      isEmailApiValid : false,
      cProp: this.prop ?? this.$vnode.data.model.expression.replace("doc.", ""),
      typoTimeout: null,
      refEl: null
    };
  },

  computed: {
    regexValidatorPassed() {
      return (this.refEl?.validateState === "success")
    }
  },

  methods: {
    callOnInput(value) {
      this.showWarningApi = false;
      this.isEmailApiValid = false;
      this.$emit('input', value)
      this.checkTypos(value);
    },
    changeSufix() {
      this.showWarning = false;
      this.showWarningApi = false;
      this.$emit('input', this.warningEmail)
      if (!this.loading) {
        this.checkApi();
      }
    },
    checkTypos(value) {
      const [address, sufix] = value.split("@");
      let warningFlag = false

      const gmailSufixTypos = [
        "gamil.com", "gmaill.com", "gmil.com", "gmai.com", "gmal.com", "gml.com", "gail.com", "gmial.com", "gmaik.com", "gmaio.com", "gmaiil.com", "gmail.con", "gmail.cm", "gmail.ocm", "gmaol.com", "gmail.cim", "gmail.xom", "gmaul.com", "gmali.com", "gmqil.com", "gmajil.com", "gmail.comm", "gmail.co", "gmail.cz",
      ];
      const seznamSufixTypos = [
        "sezman.cz", "sezamn.cz", "sznam.cz", "sezna.cz", "sezan.cz", "sesnam.cz", "seznma.cz", "szeman.cz", "seznm.cz", "sezanm.cz", "seznzm.cz", "sezan.cz", "sesnam.cz", "sezman.cz", "sezmam.cz", "seznamc.z", "seznam.c", "seznamc.z", "seznan.cz", "szeman.cz"
      ];

      if (gmailSufixTypos.includes(sufix)) {
        this.warningEmail = address.concat("@", "gmail.com");
        warningFlag = true
      }
      else if (seznamSufixTypos.includes(sufix)) {
        this.warningEmail = address.concat("@", "seznam.cz");
        warningFlag = true
      }

      //timeout for settings and clearing warning to avoid flashing text when typing normal valid email & to avoid overflowing error validation with warning
      clearTimeout(this.typoTimeout)
      if (warningFlag){
        this.typoTimeout = setTimeout(() => {
          this.showWarning = true;
        }, 500)
      }
      else{
        this.showWarning = false
      }
    },

    checkApi() {
      if (this.regexValidatorPassed && !this.showWarning) {
        this.loading = true;
        setTimeout(() => {
          var url = "/proculus-api";
          if(this.$context.access == "external")
            url = "/external-api/" + this.$context.client.slug;

          this.$api.get(url + "/check-email/" + this.value).then(response => {
            if (response?.data?.response?.result === "undeliverable") {
              this.showWarningApi = true;
              this.warningApiMessage = this.$t('ls.inputEmail.undeliverable');
            } 
            else if (response?.data?.response?.result === "risky") {
              if(response.data.response.catch_all) { //this is not a risk for us
                this.isEmailApiValid = true
              }
              else {
                this.showWarningApi = true;
                this.warningApiMessage = this.$t('ls.inputEmail.risky')
              }
            } 
            else if (response?.data?.response?.result === "unknown") {
              this.showWarningApi = true;
              this.warningApiMessage = this.$t('ls.inputEmail.unknown')
            }
            else if (response?.data?.response?.result === 'deliverable'){
              this.isEmailApiValid = true
            }
            this.loading = false;
          })
          .catch(e => {
            this.loading = false;
            this.$sentry.captureException(e);
          });
        }, 100);
      }
    },
  },
  mounted(){
    this.refEl = this.$refs["input-email-item"];
  }
};
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "inputEmail": {
          "label": "E-mail",
          "placeholder": "E-mail",
          "undeliverable": "Email je nejspíš nedosažitelný.",
          "risky": "Email je riskantní.",
          "unknown": "Email je neznámý.",
          "correction": "Měli jste na mysli",
          "validTooltip": "Email je validní"
        }
      }
    },
    "en": {
      "ls": {
        "inputEmail": {
          "label": "E-mail",
          "placeholder": "E-mail",
          "undeliverable": "E-mail is probably unreachable.",
          "risky": "E-mail is risky.",
          "unknown": "E-mail is unknown.",
          "correction": "Did you mean",
          "validTooltip": "E-mail is valid"
        }
      }
    },
    "pl": {
      "ls": {
        "inputEmail": {
          "label": "E-mail",
          "placeholder": "E-mail",
          "undeliverable": "Poczta e-mail jest prawdopodobnie nieosiągalna.",
          "risky": "E-mail jest ryzykowny.",
          "unknown": "E-mail jest nieznany.",
          "correction": "Miałeś na myśli",
          "validTooltip": "E-mail jest poprawny"
        }
      }
    },
    "hu": {
      "ls": {
        "inputEmail": {
          "label": "E-mail",
          "placeholder": "E-mail",
          "undeliverable": "Az e-mail valószínűleg elérhetetlen.",
          "risky": "Az e-mail kockázatos.",
          "unknown": "Az e-mail cím ismeretlen.",
          "correction": "Arra gondoltál",
          "validTooltip": "Az e-mail helyes"
        }
      }
    }
  }
</i18n>

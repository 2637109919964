<template>
  <el-autocomplete
    v-model="model"
    value-key="fullName"
    :fetch-suggestions="
      querySearch(data, 'fullName')
    "
    :disabled="disabled"
    clearable
    :placeholder="placeholder"
    @select="select"
    @change="change">
  </el-autocomplete>
</template>

<script>
  export default {
    name: 'LsAutocomplete',
    inject: ['doc'],    
    props: {
      value: null,
      placeholder: null,
      disabled: {
        type: Boolean,
        default: false
      },
      source: { //contacts, adversaries
        type: String,
        required: true
      },
      filter: { //all, case, client, case_client
        type: String,
        required: true
      }
    },
    data () {
      return {
        model: this.value,
        data: null
      }
    },
    watch: {
      value() {
        this.model = this.value;
      },
      model(val) { 
        this.$emit('input', val);
      }
    },
    created: async function() {
        this.data = this.$singleCase[this.source][this.filter](this.doc);
    },
    methods: {
        change(val) {
            this.$emit('change', val);
        },

        select(val) {
            this.$emit('select', val);
        },
    }
  }
</script>

<template>
    <span v-if="data.numberOfMatches == 0">Žádný záznam</span>
    <div v-else>
      Počet záznamů: {{data.numberOfMatches}}
      <div v-if="data.matches">
        <br><br>

        <div v-for="(x, ix) in data.matches" :key="ix">
          <div><strong>Záznam {{ix+1}}</strong><template v-if="x.matchRate"> (Shoda {{x.matchRate}}%)</template></div>
          <div class="prehladBlok" style="margin-left: 10px">
            <div class="riadok"><strong>{{x.entity.categories.join(', ')}}</strong></div>
            <img v-if="x.entity.images && x.entity.images.length" :src="x.entity.images[0]" style="float:right; max-height: 120px;">
            <div v-if="x.entity.enterDate" class="riadok">
              <strong>Datum vložení záznamu:</strong> {{x.entity.enterDate | formatDate}}
            </div>
            <div class="riadok"><strong>Informace:</strong> <br>
              <div v-for="(info, fi) in x.entity.furtherInformation" :key="fi">
                &nbsp; - {{info}}
              </div>
            </div>
            <br>
            <div class="riadok"><strong>Lokace:</strong> {{x.entity.primaryLocation}}</div>

            <!-- FO -->
            <div v-if="data.type == 'person'">
              <div class="riadok"><strong>Jméno:</strong> {{x.entity.firstName}} {{x.entity.lastName}}</div>
              <div class="riadok" v-if="x.entity.dateOfBirth"><strong>Datum narození:</strong> {{x.entity.dateOfBirth}}</div>
              <div class="riadok" v-if="x.entity.placeOfBirth"><strong>Místo narození:</strong> {{x.entity.placeOfBirth}}</div>
              <div class="riadok" v-if="x.entity.gender"><strong>Pohlaví:</strong> {{x.entity.gender}}</div>

              <div class="riadok"><strong>Role:</strong> <br>
                <div v-for="(role, ri) in x.entity.roles" :key="ri">
                  &nbsp; - {{role.title}}
                </div>
              </div>
            </div>

            <!-- PO -->
            <div v-else>
              <div class="riadok"><strong>Jméno:</strong> {{x.entity.name}}</div>
              <div v-if="x.entity.generalInfo" class="riadok"><strong>O společnosti:</strong> <br>
                <div>&nbsp; - {{x.entity.generalInfo.businessDescription}}</div>
                <div>&nbsp; - {{x.entity.generalInfo.website}}</div>
              </div>
            </div>

            <br>
            <div v-if="x.entity.linkedCompanies && x.entity.linkedCompanies.length" class="riadok"><strong>Související společnosti:</strong> <br>
              <div v-for="(company, ci) in x.entity.linkedCompanies" :key="ci">
                &nbsp; - <i>{{company.name}}</i>: {{company.description}}
              </div>
            </div>
            <div v-if="x.entity.linkedIndividuals && x.entity.linkedIndividuals.length" class="riadok"><strong>Související osoby:</strong> <br>
              <div v-for="(individual, ii) in x.entity.linkedIndividuals" :key="ii">
                &nbsp; - <i>{{individual.firstName}} {{individual.lastName}}</i>: {{individual.description}}
              </div>
            </div>
            <br>
            <div class="riadok"><strong><a @click="openSources(x)">Zdroje (otevřít kliknutím):</a></strong>
              <div v-if="x.sOpen">
                <div v-for="(source, si) in x.entity.sources" :key="si">
                  &nbsp; - ({{source.dates.join(', ')}}): <a :href="source.url">{{source.url}}</a>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>

        <el-divider></el-divider>
      </div>
    </div>
  </template>
  <script>
    export default {
      props: [
        'data',
      ],
      data () {
        return {
        }
      },
      methods: {
        openSources (x) {
          if(x.sOpen === undefined)
            this.$set(x, 'sOpen', true);
          else
            x.sOpen = !x.sOpen;
        }
      }
    }
  </script>

<template>
  <div>
    <p v-if="data.address2">{{ $t('ls._expandOverview.correspondenceAddress') }}: {{ data.address2 | formatAddress }}</p>
    <p v-if="data.email">{{ $t('ls._expandOverview.email') }}: {{ data.email }}</p>
    <p v-if="data.phone">{{ $t('ls._expandOverview.phone') }}: {{ data.phone }}</p>
    <p v-if="data.bank">{{ $t('ls._expandOverview.accountNumber') }}: {{ data.bank }}</p>
    <template v-if="data.representative">
      <p><strong>{{ $t('ls._expandOverview.representative') }}:</strong></p>
      <p>{{ data.representative.function }}</p>
      <p>{{ data.representative.name }}</p>
      <p>
        <template v-if="data.representative.type == 'PO' || data.representative.type == 'OSVC'">
          {{ data.representative.or.ic }}
        </template>
        <template v-else>
          <template v-if="data.representative.person.identificationType == 'rc'">
            {{ data.representative.person.rc }}
          </template>
          <template v-else>
            {{ data.representative.person.birthdate | formatDate }}
          </template>
        </template>
      </p>
      <p v-if="data.representative.address">{{ data.representative.address | formatAddress }}</p>
      <p v-if="data.representative.email">{{ $t('ls._expandOverview.email') }}: {{ data.representative.email }}</p>
      <p v-if="data.representative.phone">{{ $t('ls._expandOverview.phone') }}: {{ data.representative.phone }}</p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<i18n>
{
  "en": {
    "ls": {
      "_expandOverview": {    
        "correspondenceAddress": "Correspondence address",
        "email": "E-mail",
        "phone": "Phone",
        "accountNumber": "Account number",
        "representative": "Representative"
      }
    }
  },
  "cz": {
    "ls": {
      "_expandOverview": {   
        "correspondenceAddress": "Korespondenční adresa",
        "email": "E-mail",
        "phone": "Telefon",
        "accountNumber": "Číslo účtu",
        "representative": "Zástupce"
      }
    }
  }
}
</i18n>

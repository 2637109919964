<template>
  <div>
    <el-upload
      class="imageUpload"
      action="#"
      drag
      :show-file-list="false"
      :http-request="httpReq">
        <img v-if="value" :src="value" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-button type="primary" @click="removeImage" v-if="value != ''">Smazat</el-button>
  </div>
</template>

<style lang="scss" scoped>
  .imageUpload::v-deep {
    .el-upload__input {
      display: none !important;
    }
     
    .el-upload-dragger {
      width: auto;
      height: auto;
      line-height: 128px;
      width: 158px;
      margin: 0 auto;
    }
     
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 158px;
      height: 120px;
      line-height: 120px;
      text-align: center;
    }
    .avatar {
      max-width: 158px;
      max-height: 128px;
    }
  }
</style>

<script>
  export default {
    props: {
      value: {
        required: true
      },
      width: {
        type: String|Number,
        default: 0
      },
      height: {
        type: String|Number,
        default: 0
      }
    },
    data () {
      return {
      }
    },
    mounted() {
      if(this.sign)
        this.signSwitch = true;
  	},
    methods: {
      httpReq(file) {
        /**
         * Hermite resize - fast image resize/resample using Hermite filter. 1 cpu version!
         * 
         * @param {HtmlElement} canvas
         * @param {int} width
         * @param {int} height
         * @param {boolean} resize_canvas if true, canvas will be resized. Optional.
         */
        function resample_single(canvas, width, height, resize_canvas) {
          var width_source = canvas.width;
          var height_source = canvas.height;
          width = Math.round(width);
          height = Math.round(height);
      
          var ratio_w = width_source / width;
          var ratio_h = height_source / height;
          var ratio_w_half = Math.ceil(ratio_w / 2);
          var ratio_h_half = Math.ceil(ratio_h / 2);
      
          var ctx = canvas.getContext("2d");
          var img = ctx.getImageData(0, 0, width_source, height_source);
          var img2 = ctx.createImageData(width, height);
          var data = img.data;
          var data2 = img2.data;
      
          for (var j = 0; j < height; j++) {
            for (var i = 0; i < width; i++) {
              var x2 = (i + j * width) * 4;
              var weight = 0;
              var weights = 0;
              var weights_alpha = 0;
              var gx_r = 0;
              var gx_g = 0;
              var gx_b = 0;
              var gx_a = 0;
              var center_y = (j + 0.5) * ratio_h;
              var yy_start = Math.floor(j * ratio_h);
              var yy_stop = Math.ceil((j + 1) * ratio_h);
              for (var yy = yy_start; yy < yy_stop; yy++) {
                var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
                var center_x = (i + 0.5) * ratio_w;
                var w0 = dy * dy; //pre-calc part of w
                var xx_start = Math.floor(i * ratio_w);
                var xx_stop = Math.ceil((i + 1) * ratio_w);
                for (var xx = xx_start; xx < xx_stop; xx++) {
                  var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
                  var w = Math.sqrt(w0 + dx * dx);
                  if (w >= 1) {
                    //pixel too far
                    continue;
                  }
                  //hermite filter
                  weight = 2 * w * w * w - 3 * w * w + 1;
                  var pos_x = 4 * (xx + yy * width_source);
                  //alpha
                  gx_a += weight * data[pos_x + 3];
                  weights_alpha += weight;
                  //colors
                  if (data[pos_x + 3] < 255)
                      weight = weight * data[pos_x + 3] / 250;
                  gx_r += weight * data[pos_x];
                  gx_g += weight * data[pos_x + 1];
                  gx_b += weight * data[pos_x + 2];
                  weights += weight;
                };
              };
              data2[x2] = gx_r / weights;
              data2[x2 + 1] = gx_g / weights;
              data2[x2 + 2] = gx_b / weights;
              data2[x2 + 3] = gx_a / weights_alpha;
            };
          };
          //clear and resize canvas
          if (resize_canvas === true) {
            canvas.width = width;
            canvas.height = height;
          } else {
            ctx.clearRect(0, 0, width_source, height_source);
          };
      
          //draw
          ctx.putImageData(img2, 0, 0);
        };
      
        var FR = new FileReader();

        var parentThis = this;
        FR.onloadend = function () {
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext("2d");
          
          var img = new Image();
          img.crossOrigin = "Anonymous"; //cors support
          img.onload = function(){
            var W = img.width;
            var H = img.height;
            canvas.width = W;
            canvas.height = H;
            ctx.drawImage(img, 0, 0); //draw image

            if(parentThis.width || parentThis.height) {
              // We deal with image resize, but keeping aspect ratio
              var ratio = Math.min(parentThis.width / this.width, parentThis.height / this.height);
              resample_single(canvas, W * ratio, H * ratio, true);
            }
            
            parentThis.$emit('input', canvas.toDataURL());
          };
          img.src = FR.result;
        };

        FR.readAsDataURL(file.file);
      },
     
      
      removeImage() {
        this.$emit('input', "");
      },

    }
  }
</script>

import stringHelpers from './stringHelpers';


function personTypesToContactTypes(type) {
  return { FO: 'FO', PO: 'PO', OSVC: 'OSVC', SJM: 'FO', TOWN: 'PO' }[type];
}

/** 
 * allows to set default pre-selected country 
 */
function getEmptyAddress(country = "CZE") {
  if(country == "CZE") {
    country = {"a2":"CZ","a3":"CZE","name":"Česko","nameEN":"Czechia"};
  }
  else if(country == "SVK") {
    country = {"a2":"SK","a3":"SVK","name":"Slovensko","nameEN":"Slovakia"};
  }

  return { 
    country: country, 
    WHOLE_ADDRESS: null,
    STREET_AND_NUMBER: null, 
    CITY: null, 
    ZIP: null 
  }
};
  
export default {
  getEmptyAddress, 

  getEmptyPO() {
    return {
      contact: this.getEmptyContact(), //proculus contact object
      loading: false,
      type: 'company',
      name: null,
      ic: null,
      dic: null,
      address: null,
      Oddil_vlozka: null,
      Soud: null,
      email: null,
      phone: null,
      bank_account: null,
      Statutarni_organ: [{
        name: null,
        function: null,
        name_function: null,
        email: null,
        phone: null,
        bank: null,
      }],
      Statutarni_organ_text: null,
      custom: { }
    }
  },

  getEmptyContact(type = null) {
    return {
      id: '',
      contact_id: '',

      type: type,

      full_name: null,
      name: null,
      surname: null,
      middlename: null,
      prefix: null,
      sufix: null,
      
      address: null,
      address_street: null,
      address_city: null,
      address_postal: null,
      address_country: null,
      
      birthdate: null,
      gender: null,
      rc: null,
      ic: null,
      dic: null,
      registration_country: 'Česko',

      id_number: null,
      id_type: 'id',
      id_expiration_date: null,
      id_issued_date: null,
      id_issuer_country: 'Česko',
      id_issuer_authority: null,
      nationality: 'Česko',
      
      email: null,
      phone: null,

      bank_account: null,

      pep: null,
      pep_date: null,
      kyc: null,
      kyc_date: null,

      relations: [],
    }
  },

  personTypesToContactTypes,

  personToContactMapper(model, countriesCode = true, address = true) {
    return contact => {
      contact.id = model.contact_id;

      contact.full_name = model.name;
      contact.type = personTypesToContactTypes(model.type);

      if(contact.type == 'PO') {
        contact.name = model.name;
      }
      else {
        let name = stringHelpers.splitName(model.name);
        contact.name = name.name;
        contact.surname = name.surname;
        contact.middlename = name.middlename;
        contact.prefix = name.prefix;
        contact.sufix = name.sufix;
      }

      if(model.person.identificationType == 'rc') {
        contact.rc = model.person.rc;
      }

      contact.birthdate = model.person.birthdate;
      contact.gender = model.person.gender;

      if(model.or) {
        contact.ic = model.or.ic;
        contact.dic = model.or.dic;
        contact.registration_country = countriesCode ? stringHelpers.addressA2ToString(model.or.registration_country) : model.or.registration_country;
      }
      
      if(model.person.passport) {
        contact.id_type = model.person.passport.typeId;
        contact.id_number = model.person.passport.number;
      }
      contact.nationality = countriesCode ? stringHelpers.addressA2ToString(model.person.nationality) : model.person.nationality;
      contact.id_issuer_country = countriesCode ? stringHelpers.addressA2ToString(model.person.id_issuer_country) : model.person.id_issuer_country;
      contact.id_issuer_authority = model.person.id_issuer_authority;
      contact.id_issued_date = model.person.id_issued_date;
      contact.id_expiration_date = model.person.id_expiration_date;
      contact.email = model.email;
      contact.phone = model.phone;
      contact.bank_account = model.bank;
      if(address) {
        contact.address = model.address?.WHOLE_ADDRESS;
        contact.address_street = model.address?.STREET_AND_NUMBER;
        contact.address_city = model.address?.CITY;
        contact.address_postal = model.address?.ZIP;
        contact.address_country = model.address ? (countriesCode ? stringHelpers.addressA2ToString(model.address.country) : model.address.country) : null;
      }
    };
  },

  contactToPersonMapper(model, countriesCode = true, address = true, basic = true) {
    return contact => {
      model.contact_id = contact?.id;

      if(basic) {
        model.name = contact?.full_name;

        model.person.identificationType = model.person.identificationType || (!contact?.rc && contact?.birthdate ? 'birthdate' : 'rc');

        model.person.birthdate = contact?.birthdate;
        model.person.rc = contact?.rc;
        model.person.gender = contact?.gender;

        if(model.or) {
          model.or.name = contact?.full_name;
          model.or.ic = contact?.ic;
          
          model.or.dic = contact?.dic;
          model.or.registration_country = !contact ? null : countriesCode ? stringHelpers.addressStringToA2(contact.registration_country) : contact.registration_country;
        }
      }

      model.person.passport = {
        typeId: contact?.id_type,
        type: null,
        number: contact?.id_number,
      };
      model.person.nationality = !contact ? null : countriesCode ? stringHelpers.addressStringToA2(contact.nationality) : contact.nationality;
      model.person.id_issuer_country = !contact ? null : countriesCode ? stringHelpers.addressStringToA2(contact.id_issuer_country) : contact.id_issuer_country;
      model.person.id_issuer_authority = contact?.id_issuer_authority;
      model.person.id_issued_date = contact?.id_issued_date;
      model.person.id_expiration_date = contact?.id_expiration_date;

      model.email = contact?.email;
      model.phone = contact?.phone;
      model.bank = contact?.bank_account;

      if(address) {
        if(model.address === null) {
          model.address = getEmptyAddress(countriesCode ? 'cz' : 'Česko');
        }

        var zip_city = [contact?.adress_postaly, contact?.address_city].filter(Boolean).map(s => s.trim()).join(' ');
        model.address.WHOLE_ADDRESS = [contact?.address_street, zip_city].filter(Boolean).map(s => s.trim()).join(', ');

        model.address.STREET_AND_NUMBER = contact?.address_street;
        model.address.CITY = contact?.address_city;
        model.address.ZIP = contact?.address_postal;

        model.address.country = !contact ? null : countriesCode ? stringHelpers.addressStringToA2(contact.address_country) : contact.address_country;
      }
    }
  },

  /* mapping of ls-persons for signatures */
  getPersonSign(x, label) {
    var podpisy = [];

    function getOnePerson(x) {
      var d = {
        category: label,
        type: null,
        sign: null,
        osType: x.type,
        name: x.name,
        nameOnly: x.name,
        zastupce: null,
        email: x.email,
        phone: x.phone,
        contact_id: x.contact_id
      };

      if(x.representative) {
        d.zastupce = x.representative.name;
        d.email = x.representative.email;
        d.phone = x.representative.phone;
        d.contact_id = x.representative.contact_id;
      }

      podpisy.push(d);
    }

    x.forEach(person => {
      if(person.type === 'FO' || person.type === 'OSVC') {
        getOnePerson(person);
      }
      else if(person.type === 'SJM') {
        getOnePerson(person.sjm.p1);
        getOnePerson(person.sjm.p2);
      }
      else if(person.type === 'PO') {
        person.or.Statutarni_organ.forEach(el => {
          var d = {
            category: label,
            type: null,
            sign: null,
            osType: person.type,
            name: el.name_function + " za " + person.name,
            nameOnly: el.name_function,
            zastupce: null,
            firmOnly: person.name,
            email: el.email || person.email,
            phone: el.phone || person.phone,
            contact_id: person.contact_id
          };

          if(person.representative) {
            d.zastupce = person.representative.name;
            d.email = person.representative.email;
            d.phone = person.representative.phone;
            d.contact_id = person.representative.contact_id;
          }

          podpisy.push(d);
        });
      }
    });
    
    return podpisy;
  },

  getPersonName(subj, ident = false) {
    try {
      if(Array.isArray(subj))
        subj = subj[0];
  
      var out = subj.name;
      if(ident) {
        out += ' (';
        out += subj.type == 'PO' ? subj.or.ic : subj.person[subj.person.identificationType];
        out += ')';
      }

      if(subj.type == 'SJM') {
        out = subj.sjm.p1.name;
        if(ident)
          out += ' (' + subj.sjm.p1.person[subj.sjm.p1.person.identificationType] + ')';
        out += ' a ';
        out += subj.sjm.p2.name;
        if(ident)
          out += ' (' + subj.sjm.p2.person[subj.sjm.p2.person.identificationType] + ')';
      }

      return out;
    }
    catch(err) {
      return "";
    }
  },

  csvToJSON(csv) {
    var lines = csv.trim().replace(/\r/g, '').split("\n");
    var headers = lines[0].split(";");

    var result = [];
    for (var i = 1; i < lines.length; i++) {
      if(lines[i] == undefined || lines[i].trim() == "")
        continue;

      var obj = {};
      var words = lines[i].split(";");

      //skip empty rows
      if(!words.filter(x => x != '').length)
        continue;

      for(var j = 0; j < words.length; j++) {
        let key = headers[j].trim();
        if(key)
          obj[key] = words[j];
      }

      result.push(obj);
    }

    return result;
  },

  round(val, precision = null) {
    return _.round(val, precision)
  },

  downloadTextAsFile(content, filename, mime = 'application/octet-stream') {
    let blob = new Blob([content], { type: mime });
    let el = document.createElement('a');
    el.download = filename;
    el.innerHTML = filename;

    if (window.webkitURL != null) {
      el.href = window.webkitURL.createObjectURL(blob);
    } else {
      el.href = window.URL.createObjectURL(blob);
      el.style.display = 'none';
      document.body.appendChild(el);
    }

    el.click();
  },

  /**
   * convert file blob to base64
   * optional parameter to return with/without base64 header
   */
  toBase64(file, split = true) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(await file);
      reader.onload = () => resolve(split ? reader.result.split(',')[1] : reader.result);
      reader.onerror = reject;
    });
  },

}

<template>
  <div>
    <el-upload
      class="docx-uploader"
      :multiple="false"
      ref="upload"
      action="#"
      accept="image/svg+xml"
      drag
      :http-request="handleHttp"
    >
      <i class="el-icon-upload"/>
      <div class="el-upload__text">
        Přeneste soubor zde, anebo <em>klikněte pro nahrání</em>.<br>
        Pozor, aktuálně je podporován pouze formát SVG.
      </div>
    </el-upload>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
  export default {
    data () {
      return {
        sign: null,
      }
    },
    methods: {
      async done() {
        return this.sign;
      },
      handleHttp(options) {
        this.imgToBase64(options);
      },
      imgToBase64(options) {
        let fr = new FileReader();
        if(options.file.type === 'image/png') {
          fr.onload = (e) => {
            this.sign = e.target.result
          }
          fr.readAsDataURL(options.file);
        } else if(options.file.type === 'image/svg+xml') {
          fr.onload = (e) => {
            this.sign = 'data:image/svg+xml;base64,' + Buffer.from(e.target.result).toString('base64')
          }
          fr.readAsText(options.file);
        } else return
      },

    }
  }
</script>

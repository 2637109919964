<template>
  <div class="ls-form-lang">
    <div class="ls-form-lang-item" v-for="(lx, li) in lang" :key="li">
      <slot 
        :name="lx" 
        :form="{
          ...form, 
          //propagate prop with language
          prop: prop ? (prop+'.'+lx) : null, 
          label: !li ? label : null,
          help: (!li && $slots.help) ? $slots.help : null
        }"
      >
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ls-form-lang::v-deep {
  .el-form-item__error {
    background: white;
  }
  
  //top item
  .ls-form-lang-item:first-child:not(:only-child) {
    .el-input__inner, .el-textarea__inner {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .el-form-item {
      margin-bottom: 0;

      .el-form-item__error {
        top: 200%;
      }
    }
  }
  //bottom item
  .ls-form-lang-item:last-child:not(:only-child) {
    .el-input__inner, .el-textarea__inner {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      filter: brightness(98%);
    }

    .el-input__count, .el-input__count .el-input__count-inner {
      background: none;
    }
  }
}
</style>

<script>
  export default {
    props: {
      label: String,
      lang: Array,

      //el-form-item props propagation
      prop: String,
      rules: Array,
      root: String,
      index: Number,
    },
    computed: {
      form() {
        return {
          rules: this.rules,
          root: this.root,
          index: this.index
        }
      },
    }
  };
</script>

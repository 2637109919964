<template>
  <div>
    <el-form-item :label="label != null || label != undefined ? label : $t('ls.signaturePad.label')" :prop="handleProp()" :rules="handleRules()" :style="[noMargin ? {'margin': '0px'} : {}]">
      <template v-if="signatureValue">
        <template v-if="buttons == 'up'">
          <el-button size="small" @click="signRemove"><i class="far fa-trash-alt"></i></el-button>
        </template>
        <div :class="'signatureimg '+(buttons == 'up' ? 'signatureimg-up' : '')" v-loading="loading">
          <img :src="signatureValue" @click="signNew">
        </div>
        <template v-if="buttons != 'up'">
          <el-button size="small" @click="signRemove"><i class="far fa-trash-alt"></i></el-button>
        </template>
      </template>
      <template v-else-if="loading">
        <template v-if="buttons == 'up'">
          <el-button size="medium" type="danger" @click="signPhoneCancel"><i class="far fa-trash-alt"></i> {{ $t('ls.signaturePad.cancel') }}</el-button>
        </template>
        <div class="signatureimg" v-loading="loading" :element-loading-text="$t('ls.signaturePad.waiting')"></div>
        <template v-if="buttons != 'up'">
          <el-button size="medium" type="danger" @click="signPhoneCancel"><i class="far fa-trash-alt"></i> {{ $t('ls.signaturePad.cancel') }}</el-button>
        </template>
      </template>
      <template v-else>
        <el-button size="medium" type="primary" @click="signNew"><i class="fas fa-signature"></i> {{ $t('ls.signaturePad.sign') }}</el-button>
        <el-button v-if="!isMobile.any" size="medium" @click="signPhone"><i class="fas fa-share-square"></i> {{ $t('ls.signaturePad.signPhone') }}</el-button>
      </template>
    </el-form-item>
    <div class="isHidden" v-if="isHidden">
      <SignatureCanvas :title="subject" :ratio="ratio" ref="signaturecanvas" v-bind:value="signatureValue" :key="signatureDialog">
        <el-button size="small" @click="signatureDialog = false">{{ $t('ls.signaturePad.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="confirm">{{ $t('ls.signaturePad.confirm') }}</el-button>
      </SignatureCanvas>
    </div>

    <el-dialog
      custom-class="signDialog ls-tw"
      :top="null"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
      :visible.sync="signatureDialog"
    >
      <SignatureCanvas v-if="isMobile.any" :ratio="ratio" :title="subject" ref="signaturecanvas" v-bind:value="signatureValue" :key="signatureDialog">
        <el-button size="small" @click="signatureDialog = false">{{ $t('ls.signaturePad.cancel') }}</el-button>
        <el-button size="small" type="primary" @click="confirm">{{ $t('ls.signaturePad.confirm') }}</el-button>
      </SignatureCanvas>
      <el-tabs v-else v-model="outputComponent" type="card">
        <el-tab-pane name="drawing" :label="$t('ls.signaturePad.draw')">
          <SignatureCanvas :title="subject" ref="signaturecanvas" v-bind:value="signatureValue" :key="signatureDialog">
            <el-button size="small" @click="signatureDialog = false">{{ $t('ls.signaturePad.cancel') }}</el-button>
            <el-button size="small" type="primary" @click="confirm">{{ $t('ls.signaturePad.confirm') }}</el-button>
          </SignatureCanvas>
        </el-tab-pane>
        <el-tab-pane name="typing" :label="$t('ls.signaturePad.type')" lazy>
          <lssub-signature-typed :ratio="ratio" :name="subject" ref="signaturetyped">
            <el-button size="small" @click="signatureDialog = false">{{ $t('ls.signaturePad.cancel') }}</el-button>
            <el-button size="small" type="primary" @click="confirm">{{ $t('ls.signaturePad.confirm') }}</el-button>
          </lssub-signature-typed>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog
      :title="$t('ls.signaturePad.popup')"
      :close-on-click-modal="false" :close-on-press-escape="false"
      v-loading="smsLoading"
      append-to-body
      :visible.sync="qrDialog">
      <div>
        <el-alert
          type="warning"
          :closable="false"
          show-icon>
          <div slot="title" style="word-break: break-word; text-align: justify;">
            {{ $t('ls.signaturePad.popupDesc') }}
          </div>
        </el-alert>
        <br>

        <el-row :gutter="20">
          <el-col :md="12">
            <el-form-item :label="$t('ls.signaturePad.qr')">
              <div v-loading="qr.code == null" style="width: 200px; height: 200px; clear: both"><img :src="qr.code" width="100%"></div>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form hide-required-asterisk :model="data" ref="signatureSms">
              <el-form-item :label="$t('ls.signaturePad.sms')" prop="phone" :rules="[rl.phone, rl.required]">
                <el-input :placeholder="$t('ls.signaturePad.phoneNumber')" v-model="data.phone">
                  <el-button slot="append" @click="sms(data.phone)">{{ $t('ls.signaturePad.send') }}</el-button>
                </el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>

        <label class="el-form-item__label">{{ $t('ls.signaturePad.device') }}</label>
        <ls-copy v-model="qr.url"></ls-copy>

      </div>
      <div slot="footer">
        <div style="float:left; font-style: italic; line-height: 40px; color: #606266">{{ $t('ls.signaturePad.limit') }}</div>
        <el-button type="primary" @click="qrDialog = false">{{ $t('ls.signaturePad.close') }}</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<style lang="scss" scoped>
  .signatureimg::v-deep {
    border: 1px solid #DCDFE6;
    width: 212px;
    height: 112px;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    cursor: pointer;

    img {
      max-width: 200px;
      max-height: 100px;
    }

    .el-loading-spinner {
      margin-top: -37px;

      .el-loading-text {
        margin: 0;
        line-height: initial;
      }
    }
  }

  .isHidden {
    visibility: hidden !important;
  }

  .signatureimg-up {
    margin-bottom: 0;
    margin-top: 10px;
  }
</style>

<script>
  import lsSignatureTyped from './lsSignatureTyped.vue';
  import lsSignatureUpload from './lsSignatureUpload.vue';
  import SignatureCanvas from "./SignatureCanvas.vue";

  export default {
    components: {
      SignatureCanvas,
      'lssub-signature-typed':lsSignatureTyped,
      'lssub-signature-upload':lsSignatureUpload,
    },
    props: {
      value: {
        required: true
      },
      saved: {
        default: null
      },
      required: {
        type: Boolean,
        default: false
      },
      prop: {
        type: String
      },
      label: {
        type: String
      },
      subject: {
        type: String
      },
      png: {
        type: Boolean,
        default: false
      },
      buttons: {
        type: String,
        default: 'down'
      },
      phone: {
        type: String,
        default: ''
      },
      noMargin: {
        type: Boolean,
        default: false
      },
      defaultInputMethod: {
        default: null, // drawing, typing
      }
    },
    data () {
      return {
        signatureValue: this.value,
        outputComponent: this.defaultInputMethod || 'drawing',
        ratio: 2,
        signatureDialog: false,
        //phone sign
        qrDialog: false,
        loading: false,
        fetchHandler: null,
        pCode: null,
        qr: {
          code: null,
          url: null
        },
        isHidden: "",
        data: {
          phone: null
        },
        smsLoading: false
      }
    },
    async mounted() {
      if(this.phone)
        this.data.phone = this.phone;

      new ResizeObserver(this.resizeCanvas).observe(document.querySelector(".signDialog"));

      if(this.saved){
        await this.$nextTick();
        await this.$nextTick();
        this.emit(this.saved)
      }

      if(this.value){
        await this.$nextTick();
        await this.$nextTick();
        this.emit(this.value)
      }
  	},
    methods: {
      resizeCanvas() {
        window.dispatchEvent(new Event('resize'));
      },

      signRemove() {
        this.emit(null)
      },
      signNew() {
        this.signRemove();
        this.signatureDialog = true;
      },

      emit(val) {
        if(val && this.png) {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");

          var img = document.createElement("img");
          img.setAttribute("src", val);

          img.onload = () => {
            canvas.width = parseFloat(img.width);
            canvas.height = parseFloat(img.height);
            ctx.drawImage(img, 0, 0);
            val = canvas.toDataURL("image/png");
            this.$emit('input', val);
            this.signatureValue = val
          }
        }
        else {
          this.$emit('input', val);
          this.signatureValue = val
        }
      },

      async done() {
        // get data from active tab (drawn or typed signature)
        if(this.isMobile.any || this.outputComponent === 'drawing') {
          return await this.$refs.signaturecanvas.done()
        } else if (this.outputComponent === 'typing') {
          return await this.$refs.signaturetyped.done()
        }
      },

      async confirm() {
        var out = await this.done();
        if(out === null) {
          this.$message.error(this.$t('ls.signaturePad.error.missing'));
          return;
        }
        this.emit(out);

        this.signatureDialog = false;
      },

      signPhone() {
        this.pCode = null;
        this.qr.code = null;
        this.qr.url = null;

        this.qrDialog = true;
        this.$api.get('/external-api/signPhone/code/'+this.$i18n.locale)
        .then(res => {
          this.pCode = res.data.code;
          this.qr.code = res.data.qr;
          this.qr.url = res.data.url;

          this.startFetch();
        }).catch(err => {
          this.stopFetch();
          this.$message.error(this.$t('ls.signaturePad.error.sign'));
          this.$sentry.captureException(err);
        });
      },
      startFetch() {
        this.loading = true;
        this.fetchHandler = setInterval(this.fetch, 5000);
      },
      stopFetch() {
        this.loading = false;
        clearInterval(this.fetchHandler);
      },

      fetch() {
        this.$api.get('/external-api/signPhone/fetch/'+this.pCode)
        .then(res => {
          if(res.data) {
            this.stopFetch();
            this.qrDialog = false;
            this.emit(res.data.sign);
          }
        }).catch(error => {
          this.stopFetch();
          this.qrDialog = false;
          if(error.response && error.response.status && error.response.status == 498) {
            this.$message.error({duration: 0, showClose: true, message: this.$t('ls.signaturePad.error.timeout')});
            return;
          }
          this.$message.error({duration: 0, showClose: true, message: this.$t('ls.signaturePad.error.sign')});
          this.$sentry.captureException(error);
        });
      },
      signPhoneCancel() {
        this.stopFetch();
        this.$api.get('/external-api/signPhone/fetchCancel/'+this.pCode);
      },

      sms(phone) {
        this.$refs['signatureSms'].validate((valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: this.$t('ls.signaturePad.error.validate'),
              type: 'error'
            });
            return false;
          }

          this.smsLoading = true;
          this.$api.post('/external-api/signPhone/sms', {
            url: this.qr.url,
            phone: this.data.phone
            }).then(res => {
              this.qrDialog = false;
              this.smsLoading = false;
            }).catch(error => {
              this.smsLoading = false;
              this.$message.error(this.$t('ls.signaturePad.error.sms'));
              this.$sentry.captureException(error);
            });
        });
      },

      isRequired() {
        return this.required != undefined && this.required !== false;
      },
      handleProp() {
        return this.isRequired() ? this.prop ? this.prop : this.$vnode.data.model.expression.replace("doc.", "") : undefined;
      },
      handleRules() {
        return this.isRequired() ? [{ required: true, message: this.$t('ls.signaturePad.rule') }] : [];
      }
    }
  }
</script>
<i18n>
  {
    "cz": {
      "ls": {
        "signaturePad": {
          "draw": "Nakreslete podpis",
          "upload": "Nahrát podpis",
          "type": "Napsat podpis",
          "label": "Podpis",
          "cancel": "Zrušit",
          "confirm": "Potvrdit",
          "new": "Zde se podepište",
          "close": "Zavřít",
          "rule": "Je nutné zadat podpis",
          "popup": "Podepsat na mobilu či tabletu",
          "popupDesc": "Podpis můžete provést na svém mobilním telefonu s dotykovou obrazovkou. Podpisový rámeček si zobrazíte načtením QR kódu anebo si jej můžete poslat přes SMS.",
          "sign": "Podepsat",
          "send": "Odeslat",
          "signPhone": "Podepsat na mobilu",
          "waiting": "Čekám na podpis...",
          "qr": "Naskenujte QR kód:",
          "sms": "Odeslat přes SMS:",
          "device": "Anebo otevřete odkaz v prohlížeči na Vašem zařízení:",
          "limit": "Platnost odkazu je 10 minut.",
          "phoneNumber": "Telefonní číslo",
          "error": {
            "sign": "Nastala chyba! Podpis přes telefon selhal. Náš tým byl informován.",
            "timeout": "Pozor! Platnost odkazu pro podpis vypršela.",
            "missing": "Chybí váš podpis. Do bíleho pole se podepište myší anebo prstem přes dotykovou obrazovku.",
            "validate": "Zkontrolujte zadané údaje.",
            "sms": "Nastala chyba při odesílání SMS! Náš tým byl informován."
          }
        }
      }
    },
    "cs": {
      "ls": {
        "signaturePad": {
          "draw": "Nakreslete podpis",
          "upload": "Nahrát podpis",
          "type": "Napsat podpis",
          "label": "Podpis",
          "cancel": "Zrušit",
          "confirm": "Potvrdit",
          "new": "Zde se podepište",
          "close": "Zavřít",
          "rule": "Je nutné zadat podpis",
          "popup": "Podepsat na mobilu či tabletu",
          "popupDesc": "Podpis můžete provést na svém mobilním telefonu s dotykovou obrazovkou. Podpisový rámeček si zobrazíte načtením QR kódu anebo si jej můžete poslat přes SMS.",
          "sign": "Podepsat",
          "send": "Odeslat",
          "signPhone": "Podepsat na mobilu",
          "waiting": "Čekám na podpis...",
          "qr": "Naskenujte QR kód:",
          "sms": "Odeslat přes SMS:",
          "device": "Anebo otevřete odkaz v prohlížeči na Vašem zařízení:",
          "limit": "Platnost odkazu je 10 minut.",
          "phoneNumber": "Telefonní číslo",
          "error": {
            "sign": "Nastala chyba! Podpis přes telefon selhal. Náš tým byl informován.",
            "timeout": "Pozor! Platnost odkazu pro podpis vypršela.",
            "missing": "Chybí váš podpis. Do bíleho pole se podepište myší anebo prstem přes dotykovou obrazovku.",
            "validate": "Zkontrolujte zadané údaje.",
            "sms": "Nastala chyba při odesílání SMS! Náš tým byl informován."
          }
        }
      }
    },
    "en": {
      "ls": {
        "signaturePad": {
          "draw": "Draw signature",
          "type": "Type signature",
          "upload": "Upload signature",
          "label": "Signature",
          "cancel": "Cancel",
          "confirm": "Confirm",
          "new": "Sign here",
          "close": "Close",
          "rule": "Signature is required",
          "popup": "Sign on mobile or tablet",
          "popupDesc": "You can sign on your mobile phone with a touch screen. You can view the signature frame by reading the QR code or you can send it via SMS.",
          "sign": "Sign",
          "send": "Send",
          "signPhone": "Sign on mobile",
          "waiting": "Waiting for signature...",
          "qr": "Scan the QR code:",
          "sms": "Send via SMS:",
          "device": "Or open the link in a browser on your device:",
          "limit": "The link is valid for 10 minutes.",
          "phoneNumber": "Phone number",
          "error": {
            "sign": "An error has occurred! Phone signature failed. Our team has been informed.",
            "timeout": "Warning! The signature link has expired.",
            "missing": "Your signature is missing. Create it using a mouse or using your finger on the touch screen.",
            "validate": "Check the entered data.",
            "sms": "An error occurred while sending the SMS! Our team has been informed."
          }
        }
      }
    },
    "hu": {
      "ls": {
        "signaturePad": {
          "draw": "Rajzolj aláírást",
          "type": "Írja be az aláírást",
          "upload": "Aláírás feltöltése",
          "label": "Aláírás",
          "cancel": "Megszünteti",
          "confirm": "megerősít",
          "new": "Itt írd alá",
          "close": "Bezárás",
          "rule": "Aláírás szükséges",
          "popup": "Jelentkezzen be a mobil vagy tabletta",
          "popupDesc": "A mobiltelefonjával érintőképernyővel jelentkezhet be. Az aláírási keret megtekintheti a QR-kód olvasásával, vagy SMS-en keresztül küldheti el.",
          "sign": "Jel",
          "send": "Küld",
          "signPhone": "Jelentkezzen be a mobilon",
          "waiting": "Az aláírásra várva ...",
          "qr": "A QR-kód beolvasása:",
          "sms": "SMS-en keresztül küldje el:",
          "device": "Vagy nyissa meg a linket a készülék böngészőjében:",
          "limit": "A link 10 percig érvényes.",
          "phoneNumber": "Telefonszám",
          "error": {
            "sign": "Hiba történt! A telefon aláírása sikertelen. A csapatunkat tájékoztatták.",
            "timeout": "Figyelem! Az aláírási link lejárt.",
            "missing": "Az aláírása hiányzik. Hozzon létre egy egérrel, vagy az ujjával az érintőképernyőn.",
            "validate": "Ellenőrizze a megadott adatokat.",
            "sms": "Hiba történt az SMS küldése közben! A csapatunkat tájékoztatták."
          }
        }
      }
    },
    "pl": {
      "ls": {
        "signaturePad": {
          "draw": "Narysuj podpis",
          "type": "Wpisz podpis",
          "upload": "Prześlij podpis",
          "label": "Podpis",
          "cancel": "Anuluj",
          "confirm": "Potwierdzać",
          "new": "Podpisz tutaj",
          "close": "Blisko",
          "rule": "Wymagany jest podpis",
          "popup": "Zaloguj się na telefon komórkowy lub tablet",
          "popupDesc": "Możesz podpisać na telefonie komórkowym z ekranem dotykowym. Możesz zobaczyć ramkę podpisu, czytając kod QR lub możesz wysłać go za pomocą SMS.",
          "sign": "Podpisać",
          "send": "Wysłać",
          "signPhone": "Zaloguj się na telefonie komórkowym",
          "waiting": "Czekam na podpis ...",
          "qr": "Zeskanuj kod QR:",
          "sms": "Wyślij przez SMS:",
          "device": "Lub otwórz link w przeglądarce na urządzeniu:",
          "limit": "Link jest ważny przez 10 minut.",
          "phoneNumber": "Numer telefonu",
          "error": {
            "sign": "Wystąpił błąd! Podpis telefonu nie powiódł się. Nasz zespół został poinformowany.",
            "timeout": "Ostrzeżenie! Link podpisu wygasł.",
            "missing": "Brakuje twojego podpisu. Utwórz go za pomocą myszy lub za pomocą palca na ekranie dotykowym.",
            "validate": "Sprawdź wprowadzone dane.",
            "sms": "Wystąpił błąd podczas wysyłania SMS! Nasz zespół został poinformowany."
          }
        }
      }
    }
  }
</i18n>

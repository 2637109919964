import VueI18n from 'vue-i18n/dist/vue-i18n.esm.js';
Vue.use(VueI18n);

import localeCZ from 'element-ui/lib/locale/lang/cs-CZ';
import localeSK from 'element-ui/lib/locale/lang/sk';
import localeEN from 'element-ui/lib/locale/lang/en';
import localePL from 'element-ui/lib/locale/lang/pl';
import localeHU from 'element-ui/lib/locale/lang/hu';
import localeRU from 'element-ui/lib/locale/lang/ru-RU';

const elementLocale = {
  cz: localeCZ,
  sk: localeSK,
  en: localeEN,
  pl: localePL,
  hu: localeHU,
  ru: localeRU,
};

import {messages as cMessages} from './plugins/customRules';

const mixinsMessages = {
  cz: {
    next: 'Zkontrolujte zadané údaje.',
    unfinish: 'Uzavřený dokument již není možné upravit.',
    error: {
      generic: "Omlouváme se, došlo k neočekávané chybě. Náš tým byl informován. Pokud je to možné, pokračujte v práci.",
      default: 'Nastala chyba',
      team: 'Náš tým byl informován.'
    },
    ares: {
      collision: "Se zadaným jménem bylo v ARES nalezeno příliš mnoho subjektů. Název upřesnete, nebo vyhledejte podle ič.",
      collisionTitle: "Kolize subjektů ARES",
      notFoundTitle: 'Subjekt nenalezen v ARES',
      notFoundByName: 'Dle zadaného jména se nepodařilo nalézt žádný subjekt.',
      notFoundByIC: 'Dle zadaného IČ se nepodařilo nalézt žádný subjekt.',
      error: 'Omlouváme se, obchodní rejstřík právě teď neodpovídá. Zkuste to prosím párkrát znovu a pokud se data nestáhnou, můžete pokus opakovat později anebo je vyplnit ručně. Odezva obchodní rejstříku se zpravidla zlepší během pár desítek minut.',
      errorTitle: 'Někde je problém',
    }
  },
  sk: {
    next: 'Skontrolujte zadané údaje.',
    unfinish: 'Uzavretý dokument už nie je možné upraviť.',
    error: {
      generic: "Ospravedlňujeme sa, došlo k neočakávanej chybe. Náš tím bol informovaný. Pokiaľ je to možné, pokračujte v práci.",
      default: 'Nastala chyba',
      team: 'Náš tím bol informovaný.'
    },
    ares: {
      collision: "So zadaným menom bolo v ARES nájdených príliš veľa subjektov. Názov upresnite, alebo vyhľadajte podľa IČ.",
      collisionTitle: "Kolízia subjektov ARES",
      notFoundTitle: 'Subjekt nenájdený v ARES',
      notFoundByName: 'Podľa zadaného mena sa nepodarilo nájsť žiadny subjekt.',
      notFoundByIC: 'Podľa zadaného IČ sa nepodarilo nájsť žiadny subjekt.',
      error: 'Ospravedlňujeme sa, obchodný register práve teraz neodpovedá. Skúste to prosím párkrát znovu a ak sa dáta nestiahnu, môžete pokus opakovať neskôr alebo ich vyplniť ručne. Odozva obchodného registra sa zvyčajne zlepší behom pár desiatok minút.',
      errorTitle: 'Niekde je problém',
    }
  },
  en: {
    next: 'Check the entered data.',
    unfinish: 'The closed document can no longer be edited.',
    error: {
      generic: "Sorry, an unexpected error occurred. Our team has been notified. If possible, continue working.",
      default: 'An error has occurred',
      team: 'Our team has been notified.'
    },
    ares: {
      collision: "Too many subjects were found in ARES with the entered name. Specify the name, or search by CIN.",
      collisionTitle: "ARES subjects collision",
      notFoundTitle: 'Subject not found in ARES',
      notFoundByName: 'No subject was found according to the entered name.',
      notFoundByIC: 'No subject was found according to the entered CIN.',
      error: 'Sorry, the commercial register is not responding right now. Please try again a few times and if the data is not downloaded, you can try again later or fill it in manually. The response of the commercial register usually improves within a few tens of minutes.',
      errorTitle: 'There is a problem somewhere',
    }
  },
  pl: {
    next: 'Sprawdź wprowadzone dane.',
    unfinish: 'Zamkniętego dokumentu nie można już edytować.',
    error: {
      default: 'Wystąpił błąd',
      team: 'Nasz zespół został poinformowany.'
    },
    ares: {
      collision: "Za dużo podmiotów znaleziono w ARES pod wprowadzoną nazwą. Uściślij nazwę lub wyszukaj według NIP.",
      collisionTitle: "Kolizja podmiotów ARES",
      notFoundTitle: 'Podmiot nie znaleziony w ARES',
      notFoundByName: 'Nie udało się znaleźć żadnego podmiotu według wprowadzonej nazwy.',
      notFoundByIC: 'Nie udało się znaleźć żadnego podmiotu według wprowadzonego NIP.',
      error: 'Przepraszamy, rejestr handlowy nie odpowiada teraz. Proszę spróbować kilka razy i jeśli dane nie zostaną pobrane, można spróbować ponownie później lub wypełnić je ręcznie. Odpowiedź z rejestru handlowego zwykle poprawia się w ciągu kilkudziesięciu minut.',
      errorTitle: 'Gdzieś jest problem',
    }
  },
  hu: {
    next: 'Ellenőrizze a megadott adatokat.',
    unfinish: 'A lezárt dokumentum már nem szerkeszthető.',
    error: {
      default: 'Hiba történt',
      team: 'Csapatunk tájékoztatást kapott.'
    },
    ares: {
      collision: "Túl sok alanyt találtak az ARES-ben a megadott névvel. Pontosítsa a nevet, vagy keressen az adószám alapján.",
      collisionTitle: "ARES alanyok ütközése",
      notFoundTitle: 'Alany nem található az ARES-ben',
      notFoundByName: 'A megadott név alapján nem találtak alanyt.',
      notFoundByIC: 'A megadott adószám alapján nem találtak alanyt.',
      error: 'Elnézést, a kereskedelmi nyilvántartás jelenleg nem válaszol. Kérjük, próbálja meg újra néhányszor, és ha az adatok nem kerülnek letöltésre, újra megpróbálhatja később, vagy kézzel kitöltheti. A kereskedelmi nyilvántartás válasza általában néhány tucat percen belül javul.',
      errorTitle: 'Valahol probléma van',
    }
  },
  ru: {
    next: 'Проверить введенные данные.',
    unfinish: 'Закрытый документ больше нельзя редактировать.',
    error: {
      default: 'произошла ошибка',
      team: 'Наша команда проинформирована.'
    },
    ares: {
      collision: "Слишком много субъектов было найдено в ARES с введенным именем. Уточните имя или найдите по ИНН.",
      collisionTitle: "Столкновение субъектов ARES",
      notFoundTitle: 'Субъект не найден в ARES',
      notFoundByName: 'По введенному имени субъект не был найден.',
      notFoundByIC: 'По введенному ИНН субъект не был найден.',
      error: 'Извините, торговый регистр сейчас не отвечает. Пожалуйста, попробуйте еще несколько раз, и если данные не загружаются, вы можете попробовать позже или заполнить их вручную. Ответ торгового регистра обычно улучшается в течение нескольких десятков минут.',
      errorTitle: 'Где-то есть проблема',
    }
  },
};

const general = {
  cz: {
    contacts: {
      relations: {
        "Parent": "Rodič",
        "Child": "Dítě",
        "Sibling": "Sourozenec",
        "Partner": "Partner",
        "Spouse": "Manžel/Manželka",
        "Relative": "Příbuzný",
        "Statutory authority": "Statutární orgán",
        "Is Statutory authority": "Je Statutární orgán v",
        "Owner/Co-owner": "Vlastník/Spoluvlastník",
        "Is Owner/Co-owner": "Je Vlastník/Spoluvlastník v",
        "Employee": "Zaměstnanec",
        "Employer": "Zaměstnavatel",
        "Supplier": "Dodavatel",
        "Customer": "Odběratel/Zákazník",
      }
    }
  }
}

import publicCS from "../../lang/public/cs.json";
import publicEN from "../../lang/public/en.json";

const messages = {
  cz: { ...elementLocale.cz, ...{ customRules: cMessages.cz, mixins: mixinsMessages.cz, general: general.cz, public: publicCS } },
  sk: { ...elementLocale.sk, ...{ customRules: cMessages.sk, mixins: mixinsMessages.sk, general: general.sk } },
  en: { ...elementLocale.en, ...{ customRules: cMessages.en, mixins: mixinsMessages.en, general: general.en, public: publicEN } },
  pl: { ...elementLocale.pl, ...{ customRules: cMessages.pl, mixins: mixinsMessages.pl, general: general.pl } },
  hu: { ...elementLocale.hu, ...{ customRules: cMessages.hu, mixins: mixinsMessages.hu, general: general.hu } },
  ru: { ...elementLocale.ru, ...{ customRules: cMessages.ru, mixins: mixinsMessages.ru, general: general.ru } },
};

const i18n = new VueI18n({
  locale: "cz",
  fallbackLocale: 'cz',
  messages: messages
});

import ElementLocale from 'element-ui/lib/locale'
ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.mixin({ i18n });

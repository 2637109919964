<template>
  <div v-if="value" class="input-with-validate input-with-select-validate">
    <el-form-item
      :label="label"
      :prop="cProp+'number'"
      ref="input-id-item"
      :rules="[(required ? rl.required : {}), { validator: rl_id_type }, { validator: rl_id }]">
      <el-input @change="check" :placeholder="placeholder" :value="value.number" @input="handleNumberInput" class="input-with-select">
        <el-select @change="check" :value="value.typeId" @input="handleTypeInput" slot="prepend" :placeholder="$t('ls.inputId.select_placeholder')">

          <el-option :label="$t('ls.inputId.types.id')" value="id"/>
          <el-option :label="$t('ls.inputId.types.card')" value="card"/>
          <el-option :label="$t('ls.inputId.types.passport')" value="passport"/>
          <el-option :label="$t('ls.inputId.types.driving')" value="driving"/>

        </el-select>
        <el-tooltip slot="append" v-if="loading" :content="$t('ls.inputId.tooltip.loading')">
          <i class="fa-fw fas fa-spinner fa-spin"/>
        </el-tooltip>
        <el-tooltip slot="append" v-else-if="showDone" :content="$t('ls.inputId.tooltip.valid')">
          <i class="fa-fw fas fa-check"/>
        </el-tooltip>
        <el-tooltip slot="append" v-else-if="showWarning" :content="$t('ls.inputId.tooltip.warning')">
          <i class="fa-fw fas fa-exclamation-triangle"/>
        </el-tooltip>
      </el-input>
    </el-form-item>
  </div>
</template>

<style lang="scss" scoped>
  .input-with-select::v-deep {
    .el-select {
      .el-input {
        width: 160px;
      }

      .el-input__validateIcon {
        display: none;
      }
    }

    .el-input-group__append {
      padding: 0 10px;
    }
  }

  .input-with-select-validate::v-deep {
    .el-form-item.is-error .input-with-select .el-input-group__prepend {
      border-color: #F56C6C;
    }
  }
</style>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    country: {
      type: String,
      default: 'cz'
    },
    label: {
      type: String,
      default: function(){
        return this.$t('ls.inputId.label');
      }
    },
    placeholder: {
      type: String,
      default: function(){
        return this.$t('ls.inputId.placeholder');
      }
    },  
    prop: String,
    required: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    country() {
      this.handleTypeInput(null);
      this.loading = false;
      this.showWarning = false;
      this.showDone = false;
    }
  },

  data() {
    return {
      loading: false,
      showWarning: false,
      showDone: false,
    };
  },
  computed: {
    canId() {
      return ['cz', 'sk', 'pl', 'de', 'at', 'hu', 'it'].includes(this.country);
    },
    cProp() {
      var prop = "";
      if(this.prop == undefined)
        prop = this.$vnode.data.model.expression.replace("doc.", "");
      else if(this.prop)
        prop = this.prop;

      if(prop)
        prop = prop + '.';
      
      return prop;
    }
  },
  created() {
    if(!this.value) {
      if(this.canId)
        var def = 'id';
      else
        var def = 'passport';

      this.$emit('input', {
        typeId: def,
        type: this.$t('ls.inputId.types.'+def),
        number: null
      })
    }

  },

  mounted() {
    if(this.value?.number && this.value?.typeId) {
      this.$refs["input-id-item"].validate();
      this.check();
    }
  },

  methods: {
    handleNumberInput(value){
      this.showWarning = false;
      this.showDone = false;
      this.$emit('input', {
        typeId: this.value.typeId,
        type: this.value ? this.$t('ls.inputId.types.'+this.value.typeId) : null,
        number: value
      })
    },

    handleTypeInput(value){
      this.showWarning = false;
      this.showDone = false;
      this.$emit('input', {
        typeId: value,
        type: value ? this.$t('ls.inputId.types.'+value) : null,
        number: this.value.number,
      })
    },

    async check(){
      await this.$nextTick();

      //only when input is filled
      if(!this.value.number)
        return;

      //only for ID and passport
      if(this.value.typeId != 'id' && this.value.typeId != 'passport')
        return;

      //only when input is valid
      if(this.$refs["input-id-item"]?.validateState !== "success")
        return;

      //only CZ & SK
      let url = '';
      if(this.country === "cz")
        url = '/external-api/checking-identity-card-CZ';
      else if(this.country === "sk")
        url = '/external-api/checking-identity-card-SK';
      else
        return;

      this.loading = true;
      try {
        if(await this.callApi(url))
          this.showWarning = true;
        else
          this.showDone = true;        
      }
      catch(e) {
        this.$catch(e, "Nepodařilo se ověřit doklad");
      }
      this.loading = false;
    },

    async callApi(url) {
      //CZ API
      //0 = Občianský preukaz
      //4 = Cestovní pas vydaný centrálně (fialová barva)
      //5 = Cestovní pas vydaný okresním úřadem (zelená barva)
      if(this.country == 'cz') {
        //CZ ID
        if(this.value.typeId == 'id')
          return this.api(url, 0);

        //CZ passport - 2 requests (for id 4 and 5)
        if(this.value.typeId == 'passport') {
          var res = await this.api(url, 4);
          if(!res)
            res = await this.api(url, 5);
          return res;
        }
      }
      //SK API
      //1 = Občianský preukaz
      //2 = Cestovní pas
      if(this.country == 'sk') {
        //SK ID
        if(this.value.typeId == 'id')
          return this.api(url, 1);

        //SK passport
        if(this.value.typeId == 'passport')
          return this.api(url, 2);
      }

      return true;
    },

    //make AXIOS request to URL with provided type
    async api(url, typeId) {
      return await this.$api.post(url, {
        'druh': typeId,
        'cislo': this.value.number
      }).then(x => x.data.invalid);
    },
    
    rl_id_type(rule, value, callback) {
      if(this.required && !this.value.typeId)
        return callback(new Error(this.$i18n.t('ls.inputId.validatorTypeId')));
      return callback();
    },

    rl_id(rule, value, callback) {
      if(!this.value.number || (this.country !== "cz" && this.country !== "sk"))
        return callback();

      //CZ driving license
      if(this.country == "cz" && this.value.typeId == "driving") 
        return this.$customRules.validateDrivingLicenceCZ(rule, this.value.number, callback);

      //ID card must be between 8-10 symbols
      if(this.value.typeId == "id" && (this.value.number.length < 8 || this.value.number.length > 10)) 
        return callback(new Error(this.$i18n.t('ls.inputId.validatorId')));

      return callback();
    }

  },
};
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "inputId": {
          "label": "Doklad totožnosti",
          "placeholder": "Číslo dokladu",
          "select_placeholder": "Typ dokladu",
          "types": {
            "id": "Občanský průkaz",
            "passport": "Cestovní pas",
            "driving": "Řidičský průkaz",
            "card": "Karta pobytu"
          },
          "tooltip": {
            "warning": "Doklad je evidován jako neplatný",
            "loading": "Doklad se ověřuje",
            "valid": "Doklad nebyl nalezen v rejstříku neplatných dokladů"
          },
          "validatorTypeId": "Zvolte typ dokladu",
          "validatorId": "Délka čísla občanského průkazu musí být mezi 8 - 10 znaky"
        }
      }
    }
  }
</i18n>
<template>
  <div>
    <el-popover
      placement="top"
      trigger="manual"
      v-model="popover">
      <p>{{ $t("ls.contacts.enableSaving") }}</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="popover = false; editingContact = false">{{ $t("ls.contacts.no") }}</el-button>
        <el-button type="primary" size="mini" @click="popover = false; editingContact = true; save()">{{ $t("ls.contacts.yes") }}</el-button>
      </div>
      <el-autocomplete
        slot="reference"
        class="full-width"
        v-bind:value="model[name]"
        @input="changed"
        @select="selected"
        :disabled="disabled"
        clearable
        :fetch-suggestions="querySearch(contacts, 'full_name')"
        :value-key="name"
        :placeholder="placeholder || $t('ls.contacts.chooseContact')"
        @clear="cleared"
      >
        <slot v-if="$slots.append" name="append" slot="append" />
        <slot v-if="$slots.prepend" name="prepend" slot="prepend" />

        <!-- append  icon for contact status -->
        <div class="contact-status" :slot="status ? status : $slots.append ? 'prepend' : 'append'">
          <!-- loading -->
          <template v-if="loading">
            <i class="fa-fw fas fa-spinner fa-spin"/>
          </template>
          <!-- add new contact -->
          <template v-else-if="creatingContact">
            <el-tooltip placement="top-start" :content="$t('ls.contacts.detailsSavedAsNew')" :enterable="false">
              <i class="fa-fw fas fa-user-plus"/>
            </el-tooltip>
          </template>
          <!-- using contact - changes are saved -->
          <template v-else-if="editingContact">
            <el-tooltip placement="top-start" :content="$t('ls.contacts.editingContact') + ' ' + prev_name" :enterable="false">
              <i class="fa-fw fas fa-user-edit"/>
            </el-tooltip>
          </template>
          <!-- using contact - without edit -->
          <template v-else-if="model.id">
            <el-tooltip placement="top-start" :content="$t('ls.contacts.loadedContact') + ' ' + model.full_name" :enterable="false">
              <i class="fa-fw fas fa-user"/>
            </el-tooltip>
          </template>
          <!-- not started -->
          <template v-else-if="!model.full_name">
            <el-tooltip placement="top-start" :content="$t('ls.contacts.editOrCreateNew')" :enterable="false">
              <i class="fa-fw fas fa-question"/>
            </el-tooltip>
          </template>
          <!-- without contacts -->
          <template v-else>
            <el-tooltip placement="top-start" :content="$t('ls.contacts.notSavedToContacts')" :enterable="false">
              <i class="fa-fw fas fa-user-slash"/>
            </el-tooltip>
          </template>
        </div>
        <template slot-scope="{ item }">
          <div class="newContact" v-if="item.new"><i class="fas fa-user-plus"/> {{ item.full_name }}</div>
          <span v-else>{{ item.full_name }} <i class="fa-regular fa-star" v-if="item.favorite" /></span>
        </template>
      </el-autocomplete>
    </el-popover>

    <!-- Collision dialog -->
    <el-dialog 
      custom-class="lsDialog" 
      append-to-body
      :title="$t('ls.contacts.currentData') + ' - ' + (collision.data ? collision.data.full_name : '')" 
      :visible.sync="collision.visible">
      <div v-if="collision.data" style="word-break: break-word;">
        <el-row>
          <el-col :offset="6" :span="9"><strong>{{ $t("ls.contacts.currentData") }}</strong></el-col>
          <el-col :span="9"><strong>{{ $t("ls.contacts.contactData") }}</strong></el-col>
        </el-row>
        <el-row v-if="isColumn('full_name') && model.full_name != collision.data.full_name">
          <el-col :span="6"><strong>{{ $t("ls.contacts.name") }}:</strong></el-col>
          <el-col :span="9">{{model.full_name || "-"}}</el-col>
          <el-col :span="9">{{collision.data.full_name || "-"}}</el-col>
        </el-row>
        <el-row v-if="isColumn('address_street') && model.address_street != collision.data.address_street">
          <el-col :span="6"><strong>{{ $t("ls.contacts.street") }}:</strong></el-col>
          <el-col :span="9">{{model.address_street || "-"}}</el-col>
          <el-col :span="9">{{collision.data.address_street || "-"}}</el-col>
        </el-row>
        <el-row v-if="isColumn('address_city') && model.address_city != collision.data.address_city">
          <el-col :span="6"><strong>{{ $t("ls.contacts.city") }}:</strong></el-col>
          <el-col :span="9">{{model.address_city || "-"}}</el-col>
          <el-col :span="9">{{collision.data.address_city || "-"}}</el-col>
        </el-row>
        <el-row v-if="isColumn('address_postal') && model.address_postal != collision.data.address_postal">
          <el-col :span="6"><strong>{{ $t("ls.contacts.postalCode") }}:</strong></el-col>
          <el-col :span="9">{{model.address_postal || "-"}}</el-col>
          <el-col :span="9">{{collision.data.address_postal || "-"}}</el-col>
        </el-row>
        <el-row v-if="isColumn('address_country') && model.address_country != collision.data.address_country">
          <el-col :span="6"><strong>{{ $t("ls.contacts.country") }}:</strong></el-col>
          <el-col :span="9">{{model.address_country || "-"}}</el-col>
          <el-col :span="9">{{collision.data.address_country || "-"}}</el-col>
        </el-row>
        <el-row v-if="isColumn('birthdate') && model.birthdate != collision.data.birthdate">
          <el-col :span="6"><strong>{{ $t("ls.contacts.birthdate") }}:</strong></el-col>
          <el-col :span="9">
            <template v-if="!model.birthdate">-</template>
            <template v-else>{{ model.birthdate | formatDate }}</template>
          </el-col>
          <el-col :span="9">
            <template v-if="!collision.data.birthdate">-</template>
            <template v-else>{{ collision.data.birthdate | formatDate }}</template>
          </el-col>
        </el-row>
        <el-row v-if="isColumn('rc') && model.rc != collision.data.rc">
          <el-col :span="6"><strong>{{ $t("ls.contacts.personalNumber") }}:</strong></el-col>
          <el-col :span="9">{{model.rc || "-"}}</el-col>
          <el-col :span="9">{{collision.data.rc || "-"}}</el-col>
        </el-row>
        <el-row v-if="isColumn('email') && model.email != collision.data.email">
          <el-col :span="6"><strong>{{ $t("ls.contacts.email1") }}</strong></el-col>
          <el-col :span="9">{{model.email || "-"}}</el-col>
          <el-col :span="9">{{collision.data.email || "-"}}</el-col>
        </el-row>
        <el-row v-if="isColumn('phone') && model.phone != collision.data.phone">
          <el-col :span="6"><strong>{{ $t("ls.contacts.phone") }}</strong></el-col>
          <el-col :span="9">{{model.phone || "-"}}</el-col>
          <el-col :span="9">{{collision.data.phone || "-"}}</el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="collisionDiscard">{{ $t("ls.contacts.discardCurrentData") }}</el-button>
        <el-button type="primary" @click="collisionApply">{{ $t("ls.contacts.loadChanges") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
  .newContact {
    font-weight: bold;
    border-bottom: 1px solid #EEE;
  }
</style>

<script>
  export default {
    name: 'LsContacts',
    props: {
      placeholder: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      status: { //where should status icon be placed (append/prepend)
        type: String,
        default: null //automatic
      },
      contact: { //replicates proculus contact
        type: Object,
        required: true
      },
      subprop: { //is contact stored directly in JSON or under certain subproperty?
        type: String,
        default: undefined
      },
      name: { //which column is exposed as input
        type: String,
        default: "full_name"
      },
      type: { //get only certain type of contact ["FO", "OSVC", "PO"]
        type: Array,
        default: null
      },
      columns: { //load only certain columns, eg. ["name", "surname", "rc"] - few columns are loaded always
        type: Array,
        default: null
      },
      changeInternal: { //internal change (new item loaded or name changed)
        type: Function
      },
      changeExternal: { //external change (outside of component)
        type: Function
      },
    },
    data () {
      return {
        loading: true,
        contacts: [],

        //saving and editing
        popover: false,
        prev_name: null,
        saveLock: null,

        //data collision
        collision: {
          visible: false,
          data: null
        },

        //state variables
        creatingContact: undefined,
        editingContact: undefined,

        //default columns to be loaded
        defaultColumns: ['id', 'full_name', 'name', 'middlename', 'surname', 'prefix', 'sufix', 'favorite', 'updated_at']
      }
    },
    
    computed: {
      cColumns() {
        return this.defaultColumns.concat(this.columns);
      },
      //access contact prop
      model() {
        if(this.subprop)
          return this.contact[this.subprop];

        return this.contact;
      },
    },

    watch: {
      //watch for person change in general
      contact: {
        handler: async function() {
          this.changeExternal?.(this.model);
        },
        deep: true
      }
    },

    async created() {
      this.all();

      //contact is null
      if(!this.model && this.subprop)
        this.contact[this.subprop] = this.$globalHelpers.getEmptyContact();

      this.changeExternal?.(this.model);
    },

    async mounted() {
      //load contact
      if(this.model?.id) {
        var contact = await this.loadContact(this.model.id);

        if(!contact)
          return;

        if(!this.isEqual(contact)) {
          //data mismatch
          this.collision.data = contact;
          this.collision.visible = true;
        } else {
          this.editListener();
        }
      }
      this.watchStart();

      //listen for event - contact editing status change
      this.$root.$on('contact-editing', (val) => {
        this.editing = val;
      });
    },
    methods: {
      /**
       * Watch for change just in relevant columns
       */
      watchStart() {
        this.cColumns.filter(x => x != 'updated_at').forEach(prop => {
          this.$watch('model.'+prop, this.watchHandler(prop));
        });
      },

      /**
       * Universal watch handler - called for each changed property
       */
      watchHandler(prop) {
        return async function(_, old) {
          if(!this.model.id)
            return; 
          if(this.editing === true)
            this.save();
          else if(this.editing === null) {
            this.popover = true;
            if(prop == 'full_name')
              this.prev_name = old;
          }
        }
      },

      /**
       * Collision handler - Override actual data (with values from DB)
       */
      collisionApply() {
        this.setData(this.collision.data);
        this.collision.visible = false;
        this.collision.data = null;
        this.changeInternal?.(this.model);

        this.editListener();
      },

      /**
       * Collision handler - Keep actual data
       */
      collisionDiscard() {
        this.collision.visible = false;
        this.collision.data = null;
        
        this.editListener();
      },

      /**
       * wait for change propagation and start listening for first change
       */
      editListener() {
        setTimeout(() => {
          this.editing = null;
        }, 500);
      },

      /**
       * Check if new contact data are equal to existing
       * Skip address (checked trough partial columns)
       */
      isEqual(contact) {
        var eq = true;
        this.cColumns.filter(x => x != 'updated_at' && x != 'address').forEach(prop => {
          if(this.model[prop] && contact[prop] && this.model[prop] != contact[prop]) {
            eq = false;
          }
        });
        return eq;
      },

      /**
       * Contact name changed
       */
      changed($event) {
        if($event != this.$t('ls.contacts.newContact'))
          this.model[this.name] = $event;

        //if type filter only one (eg PO), prefill
        if(this.type?.length == 1) {
          this.model.type = this.type[0];
        }

        //split name for single field full_name
        if(this.name == "full_name") {
          //preset contact as company 
          //@TODO - inform user about automagic change
          if((this.type == null || this.type.includes('PO')) && this.model.type == null && this.isCompany(this.model.full_name)) {
            this.model.type = 'PO';
          }

          if(this.model.type != 'PO') {
            this.setData(this.$stringHelpers.splitName(this.model?.full_name));
          }
          if(this.model.type == 'PO') {
            this.model.name = this.model.full_name;
          }
        } 

        this.changeInternal?.(this.model);
      },

      /**
       * Is provided name a potential company name (does it include legal form, eg 's.r.o.')?
       */
      isCompany(x) {
        return !x ? false : this.$stringHelpers.removeLegalForm(x) != x?.replace(/\s+/g, ' ').trim();
      },

      /**
       * Check if column (contact property) is loaded
       */
      isColumn(col) {
        return !this.columns || this.cColumns.includes(col);
      },

      /**
       * Save changes to contact (throttling)
       */
      async save() {
        if(!this.saveLock) { //save once a while at max
          this.saveLock = true;
          await new Promise(r => setTimeout(r, 2000));
          this.saveLock = false;
          var out = await this.store(this.model);
          this.model.updated_at = out.updated_at;
          if(this.creatingContact)
            this.model.id = out.id;
        }
      },

      /**
       * Store data to DB
       */
      async store(data) {
        try {
          var contact = _.cloneDeep(data);

          contact.name = contact.name || contact.full_name;
          contact.type = contact.type || 'FO';

          //save just requested columns
          if(this.columns)
            Object.keys(contact).forEach(key => this.columns.includes(key) || delete contact[key]);

          if(data.id) {
            return this.$api.put(`/proculus-api/kontakty/${data.id}/update`, contact).then(x => x.data);
          }

          return this.$api.post("/proculus-api/kontakty/new", contact).then(x => x.data);
        }
        catch(err) {
          this.$catch(err, this.$t('ls.contacts.error.saving'));
        };
      },

      /**
       * Load all contacts from DB
       */
      async all() {
        this.loading = true;
        try {
          this.contacts = await this.$api.post('/proculus-api/kontakty/basic', {
            types: this.type
          }).then(x => x.data);
        }
        catch(err) {
          this.$catch(err, this.$t('ls.contacts.error.loading'));
        }
        finally {
          this.loading = false;
        }
      },

      /**
       * Contact selected from dropdown
       */
      async selected(el) {
        //create new contact button
        if(el.new) {
          this.creatingContact = true;
          this.editing = true;
          this.save();
          return;
        }

        //reset to clear state
        this.reset();

        //load existing contact
        var res = await this.loadContact(el.id);
        this.setData(res);
        this.changeInternal?.(this.model);

        this.editListener();
      },

      /**
       * Clear button pressed
       * 
       * If contact loaded, clear all fields
       */
      cleared() {
        if(this.model.id || this.creatingContact)
          this.reset();
      },

      /**
       * Reset fields
       */
      reset() {
        this.editing = undefined;
        this.creatingContact = undefined;

        //prepare JSON for loading existing contact
        this.setData(this.$globalHelpers.getEmptyContact());
        this.changeInternal?.(this.model);
      },

      /**
       * Load contact 'id' from DB
       */
      async loadContact(id) {
        try {
          this.loading = true;
          return await this.$api.get('/proculus-api/kontakty/get/' + id).then(x => x.data);
        }
        catch(err) {
          this.$catch(err, this.$t('ls.contacts.error.details'));
        }
        finally {
          this.loading = false;
        }
      },

      /**
       * Vue reactivity set contact data
       */
      setData(data) {
        for(const col in data) {
          if(this.isColumn(col))
            this.$set(this.model, col, data[col]);
        }
      },

      /**
       * Custom query for data filtering
       * 
       * Inject [New contact] button to the top
       */
      querySearch(promise, param) {
        return async (query, cb) => {
          if(this.model.id || this.creatingContact)
            return cb([]);

          var data = await promise; 
          cb([
            { new: true, name: this.$t('ls.contacts.newContact'), surname: this.$t('ls.contacts.newContact'), full_name: this.$t('ls.contacts.newContact') },
            ...(query ? data.filter(this.basicFilter(query, param)) : data)
          ]);
        }
      },       

    }
  }
</script>

<i18n>
{
  "cz": {
    "ls": {
      "contacts": {
        "enableSaving": "Chcete zapnout ukádání změn ke kontaktu?",
        "no": "Ne",
        "yes": "Ano",
        "chooseContact": "Zvolte kontakt",
        "detailsSavedAsNew": "Údaje osoby budou uloženy jako nový kontakt",
        "editingContact": "Upravujete kontakt",
        "loadedContact": "Máte načtený kontakt",
        "editOrCreateNew": "Načtete a upravte kontakt, anebo založte nový",
        "notSavedToContacts": "Osoba nebude uložena do kontaktů",
        "currentData": "Aktuální údaje",
        "contactData": "Údaje z kontaktu",
        "name": "Jméno",
        "street": "Ulice",
        "city": "Město",
        "postalCode": "PSČ",
        "country": "Krajina",
        "birthdate": "Datum narození",
        "personalNumber": "Rodné číslo",
        "email1": "E-mail 1",
        "phone": "Telefon",
        "discardCurrentData": "Zanechat aktuální data",
        "loadChanges": "Načíst změny",
        "newContact": "Nový kontakt",
        "error": {
          "saving": "Při ukládání dat nastala chyba",
          "loading": "Nepodařilo se načíst seznam kontaktů",
          "details": "Nepodařilo se načíst podrobnosti kontaktu"
        }
      }
    }
  },
  "en": {
    "ls": {
      "contacts": {
        "enableSaving": "Do you want to enable saving changes to the contact?",
        "no": "No",
        "yes": "Yes",
        "chooseContact": "Choose a contact",
        "detailsSavedAsNew": "The person's details will be saved as a new contact",
        "editingContact": "You are editing the contact",
        "loadedContact": "You have loaded the contact",
        "editOrCreateNew": "Load and edit a contact, or create a new one",
        "notSavedToContacts": "The person will not be saved to contacts",
        "currentData": "Current data",
        "contactData": "Contact data",
        "name": "Name",
        "street": "Street",
        "city": "City",
        "postalCode": "Postal Code",
        "country": "Country",
        "birthdate": "Birthdate",
        "personalNumber": "Personal Number",
        "email1": "Email 1",
        "phone": "Phone",
        "discardCurrentData": "Keep current data",
        "loadChanges": "Load changes",
        "newContact": "New contact",
        "error": {
          "saving": "An error occurred while saving the data",
          "loading": "Failed to load the contact list",
          "details": "Failed to load contact details"
        }
      }
    }
  }
}
</i18n>

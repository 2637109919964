<template>
  <div>
    <strong class="h4">{{ $t('ls._zastupce.representative') }}</strong>

    <el-form-item style="margin-bottom: 4px" :label="$t('ls._zastupce.isPersonRepresented')">
      <el-radio-group :value="enableModel" @input="enableSwitch">
        <el-radio :label="false" :disabled="sjm_shared && !sjm_p1">{{ $t('ls._zastupce.no') }}</el-radio>
        <el-radio :label="true">{{ $t('ls._zastupce.yes') }}</el-radio>
        <el-radio v-if="sjm_p1" :label="2">{{ $t('ls._zastupce.sameAsSpouse') }}</el-radio>
      </el-radio-group>
    </el-form-item>

    <template v-if="enableModel === true">
      <el-form-item :label="$t('ls._zastupce.typeOfRepresentative')">
        <el-radio-group v-model="model.representative.type" size="mini">
          <el-radio-button label="FO">{{ $t('ls._zastupce.individual') }}</el-radio-button>
          <el-radio-button label="OSVC">{{ $t('ls._zastupce.freelancer') }} ({{ $t('ls._zastupce.lawyer') }})</el-radio-button>
          <el-radio-button label="PO">{{ $t('ls._zastupce.legalEntity') }}</el-radio-button>
        </el-radio-group>
      </el-form-item>
      
      <sub-person 
        v-if="model.representative.type"
        :contacts="contacts"
        v-model="model.representative"
        :prop="prop"
        :lang="lang"
        :countries-code="countriesCode"
        :person_identification="person_identification"
        :person_identification-default="person_identificationDefault"
        :person_gender="person_gender"
        :address="address"
        :or_dic="or_dic"
        :or_countries="or_countries"
        :required="required"    
        :person_identity-document="false"
        :email="email"
        :phone="phone"
        :statutory_email="statutory_email"
        :statutory_phone="statutory_phone"
        :_representative="true"
      />
    </template>

  </div>
</template>
<script>
  import personComponent from './_person';

  export default {
    components: {
      'sub-person': personComponent,
    },
    props: {
      contacts: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        required: true
      },
      prop: {
        type: String,
        required: true
      },
      sjm_p1: {
        type: Object,
        default: null
      },
      sjm_shared: {
        type: Boolean,
        default: false
      },
      lang: {
        type: Array,
        default: () => ['cz']
      },
      countriesCode: true,
      person_identification: null,
      person_identificationDefault: null,
      person_gender: undefined,
      address: true,
      or_dic: undefined,
      or_countries: undefined,
      email: false,
      phone: false,
      statutory_email: false,
      statutory_phone: false,
      required: null,
    },
    data() {
      return {
        model: this.value,
        backup: null
      };
    },
    watch: {
      model: {
        handler(val) {
          this.$emit('input', val);
        },
        deep: true
      },

      //link changes for SJM
      sjm_p1: {
        handler(val) {
          if(this.enableModel === 2)
            this.model.representative = val;
        },
        deep: true
      }
    },

    computed: {
      enableModel() {
        if(this.sjm_shared && this.sjm_p1 !== null)
          return 2;
        return this.model.representative !== null;
      }
    },
    methods: {
      /**
       * backup prefilled data when switching between YES and NO
       */
      enableSwitch(val) {
        this.$emit('sjm_shared', val === 2);
        
        if(val) {
          if(val === 2) { //link SJM partner 1 with partner 2
            this.model.representative = this.sjm_p1;
          }
          else if(this.backup) { //switching yes -> no -> YES (recover)
            this.model.representative = _.cloneDeep(this.backup);
            this.backup = null;
          }
          else { //create new representative
            this.model.representative = {
              ...personComponent.methods.getEmpty(),
              type: 'OSVC'
            }
          }
        }
        else {
          this.backup = _.cloneDeep(this.model.representative);
          this.model.representative = null;
        }
      },

    },
  };
</script>

<i18n>
{
  "en": {
    "ls": {
      "_zastupce": { 
        "representative": "Representative",
        "isPersonRepresented": "Is the person represented?",
        "no": "No",
        "yes": "Yes",
        "sameAsSpouse": "Same as spouse",
        "typeOfRepresentative": "Type of representative",
        "individual": "Individual",
        "freelancer": "Freelancer",
        "lawyer": "Lawyer",
        "legalEntity": "Legal entity"
      }
    }
  },
  "cz": {
    "ls": {
      "_zastupce": { 
        "representative": "Zástupce",
        "isPersonRepresented": "Je osoba zastoupena?",
        "no": "Ne",
        "yes": "Ano",
        "sameAsSpouse": "Stejně jako manžel",
        "typeOfRepresentative": "Typ zástupce",
        "individual": "Fyzická osoba",
        "freelancer": "OSVČ",
        "lawyer": "Advokát",
        "legalEntity": "Právnická osoba"
      }
    }
  }
}
</i18n>

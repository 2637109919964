<template>
    <ls-pep-legacy-protocol v-if="data.matches != null" :data="data" />
    <div v-else>
        <span dusk="pep-result" v-if="data.matchedNumber == 0">Žádný záznam</span>
        <div dusk="pep-result" v-else>
            Počet záznamů: {{data.matchedNumber}}
            <div v-if="data.matchedEntities">
            <br><br>

            <div v-for="(x, ix) in data.matchedEntities" :key="ix">
                <div><strong>Záznam {{ix+1}}</strong><template v-if="x.matchRate"> (Shoda {{x.matchRate}}%)</template></div>
                <div class="prehladBlok" style="margin-left: 10px">
                <div class="riadok"><strong>{{x.resultEntity.categories}}</strong></div>

                <img v-if="x.resultEntity.image" :src="x.resultEntity.image" style="float:right; max-height: 120px;">
                <div v-if="x.resultEntity.enterDate" class="riadok">
                    <strong>Datum vložení záznamu:</strong> {{x.resultEntity.enterDate | formatDate}}
                </div>

                <div class="riadok">
                    <strong>Informace:</strong>
                    <p>{{x.resultEntity.furtherInformation}}</p>
                </div>

                <br>

                <div v-if="x.resultEntity.primaryLocation" class="riadok"><strong>Lokace:</strong> {{x.resultEntity.primaryLocation}}</div>

                <!-- FO -->
                <div v-if="data.type == 'person'">
                    <div class="riadok"><strong>Jméno:</strong> {{x.firstName}} {{x.lastName}}</div>
                    <div class="riadok" v-if="x.resultEntity.dateOfBirth"><strong>Datum narození:</strong> {{x.resultEntity.dateOfBirth}}</div>
                    <div class="riadok" v-if="x.resultEntity.placeOfBirth"><strong>Místo narození:</strong> {{x.resultEntity.placeOfBirth}}</div>
                    <div class="riadok" v-if="x.resultEntity.gender"><strong>Pohlaví:</strong> {{x.resultEntity.gender}}</div>

                    <div class="riadok"><strong>Role:</strong> <br>
                    <div v-for="(role, ri) in x.resultEntity.roles" :key="ri">
                        &nbsp; - {{role.title}}
                    </div>
                    </div>
                </div>

                <!-- PO -->
                <div v-else>
                    <div class="riadok"><strong>Jméno:</strong> {{x.entity.name}}</div>
                    <div v-if="x.entity.generalInfo" class="riadok"><strong>O společnosti:</strong> <br>
                    <div>&nbsp; - {{x.entity.generalInfo.businessDescription}}</div>
                    <div>&nbsp; - {{x.entity.generalInfo.website}}</div>
                    </div>
                </div>



                <br>
                <div v-if="x.resultEntity.linkedCompanies && x.resultEntity.linkedCompanies.length" class="riadok"><strong>Související společnosti:</strong> <br>
                    <div v-for="(company, ci) in x.resultEntity.linkedCompanies" :key="ci">
                    &nbsp; - <i>{{company.name}}</i>: {{company.description}}
                    </div>
                </div>

                <div v-if="x.resultEntity.linkedIndividuals && x.resultEntity.linkedIndividuals.length" class="riadok"><strong>Související osoby:</strong> <br>
                    <div v-for="(individual, ii) in x.resultEntity.linkedIndividuals" :key="ii">
                    &nbsp; - <i>{{individual.firstName}} {{individual.lastName}}</i>: {{individual.description}}
                    </div>
                </div>

                <br>
                <div class="riadok"><strong><a @click="openSources(x)">Zdroje (otevřít kliknutím):</a></strong>
                    <div v-if="x.sOpen">
                    <div v-for="(source, si) in x.resultEntity.sources" :key="si">
                        &nbsp; - <a :href="source.url">{{source.url}}</a>
                    </div>
                    </div>
                </div>
                </div>
                <br>
            </div>

            <el-divider></el-divider>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    props: [
      'data',
    ],
    data () {
      return {
      }
    },
    methods: {
      openSources (x) {
        if(x.sOpen === undefined)
          this.$set(x, 'sOpen', true);
        else
          x.sOpen = !x.sOpen;
      }
    }
  }
</script>

import * as Sentry from '@sentry/vue';

Vue.prototype.$sentry = Sentry;

//production and non-superuser only
if(__PROD__ && !window.superuser)
  var dsn = "https://51ed12b314eb4013a90cf82fc5886abb@o348381.ingest.sentry.io/2211843";

// init Sentry (production only)
Sentry.init({
  Vue,
  dsn: dsn || null,

  //allow only production app and errors inside
  allowUrls: [ /proculus\.legalsystems\.cz/i ],
  tunnel: "https://proculus.legalsystems.cz/external-api/sentry",

  trackComponents: true,
  integrations: [
    /*
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    }),
    */
    new Sentry.browserTracingIntegration({
      trackComponents: true,
    }),    
  ],
  tracesSampleRate: 0.15,

  //replaysSessionSampleRate: 0.05,
  //replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",

    //LS section
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',

    //external clients js hotfix
    '$bgVideo.size is not a function',
    '$(...).size is not a function',
    '$(...).colorpicker is not a function',
    "$('.smoothscroll-sharplink').size is not a function",
    "$('.header-vertical').size is not a function",
    "e.indexOf is not a function",
    "$(...).grido is not a function"
  ]
});


//https://www.raymondcamden.com/2019/05/01/handling-errors-in-vuejs
let errorMessage = null;
Vue.config.errorHandler = (err, _, info) => {
  console.error(err);
  console.error(info);
  Sentry.captureException(err);

  if(!errorMessage || errorMessage.closed)
    errorMessage = Vue.prototype.$message.error({
      message: Vue.options.i18n.t('mixins.error.generic'),
      duration: 10000,
      offset: 84
    });
}

//Universal try catch helper
Vue.prototype.$catch = function(err, message = null) {
  console.error(err);
  Sentry.captureException(err);

  Vue.prototype.$message.error({
    message: (message ?? Vue.options.i18n.t('mixins.error.default')) + "! " + Vue.options.i18n.t('mixins.error.team'),
    duration: 5000,
    offset: 84
  });
};

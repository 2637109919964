<template>
    <div>
        <p>Zvolte:</p>

        <el-radio-group v-model="pepRadio" size="mini" @change="value => save({ pep: value })">
            <el-radio-button :label="true">Osoba je PEP</el-radio-button>
            <el-radio-button :label="false">Osoba není PEP</el-radio-button>
        </el-radio-group>

        <br>

        <el-radio-group v-model="sanctionsRadio" size="mini" @change="value => save({ sanctions: value })">
            <el-radio-button :label="true">Osoba je na sankčním seznamu</el-radio-button>
            <el-radio-button :label="false">Osoba není na sankčním seznamu</el-radio-button>
        </el-radio-group>

        <br>

        <el-button @click="$emit('complete');" :disabled="pepRadio === null || sanctionsRadio === null" v-if="showCloseButton">
            Potvrdit kontrolu
        </el-button>
    </div>
</template>

<script>
export default {
    name: "lsPepEvaluation",
    props: {
        pep: {
            type: Object,
            required: true,
        },
        showCloseButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            pepRadio: this.pep.result ? this.pep.result : null,
            sanctionsRadio: this.pep.sanctions ? this.pep.sanctions : null,
        }
    },
    methods: {
        async save({ sanctions, pep }) {
            try {
                await this.$api.post('/proculus-api/pep/store', {
                    result: pep,
                    sanctions: sanctions,
                    uuid: this.pep.uuid
                });

                if (sanctions !== undefined){
                    this.$emit('done-sanctions', sanctions)
                }
                if (pep !== undefined){
                    this.$emit('done-pep', pep)
                }
            }
            catch(error) {
                this.$catch(error, 'Pozor, ukládání dat selhalo');
            }
        },
    },
    mounted() {
        console.log(this.pep, this.pepRadio, this.sanctionsRadio);
    }
}
</script>

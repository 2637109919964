<template>
    <el-form ref="sms"
        :model="form"
        style="width: 100%"
        hide-required-asterisk
    >
        <el-form-item prop="number"
            :rules="[rl.phone, rl.required]"
        >
            <el-input v-model="form.number">
                <el-button :disabled="loading || done" slot="append" @click="send">
                    <i v-if="loading" class="fa-fw fas fa-spinner fa-spin"></i>
                    <i v-else-if="done" class="fa-fw fas fa-check"></i>
                    <i v-else class="fa-fw far fa-paper-plane"></i>
                    {{ $t('Send') }}
                </el-button>
            </el-input>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    props: {
        smsRoute: {
            type: String,
            required: true,
            // route for sending sms
        }
    },
    data() {
        return {
            done: false,
            loading: false,
            form: {
                number: null,
            }
        }
    },
    methods: {
        send() {
            this.$refs.sms.validate(valid => {
                if (!valid) return;

                this.loading = true;
                this.$api.post(this.smsRoute, { number: this.form.number })
                    .then(() => { this.$message({ message: this.$t('SMS was sent to') + ' ' + this.form.number, type: 'success'}); this.done = true })
                    .catch(() => this.$message({ message: this.$t('Failed to send SMS to') + ' ' + this.form.number, type: 'error'}))
                    .finally(() => this.loading = false)
            });
        },
    }
}
</script>

<i18n>
    {
        "cz": {
            "Send": "Odeslat",
            "SMS was sent to": "SMS byla odeslána na číslo",
            "Failed to send SMS to": "Nepodařilo se odeslat SMS na číslo"
        },
        "en": {
            "Send": "Send",
            "SMS was sent to": "SMS was sent to",
            "Failed to send SMS to": "Failed to send SMS to"
        }
    }
</i18n>

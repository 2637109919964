<template>
  <div>
    <span class="el-form-item" :class="{ 'is-required': required }"><label class="el-form-item__label">
      <template v-if="identification == 'combined'">
        {{ $t('ls._personIdent.identification') }}
      </template>
      <template v-else-if="value.identificationType == 'rc'">
        {{ $t('ls._personIdent.personalIdNumber') }}
      </template>
      <template v-else-if="value.identificationType == 'birthdate'">
        {{ $t('ls._personIdent.dateOfBirth') }}
      </template>
    </label></span>
    <el-row :gutter="0">
      <div v-if="identification">
        <div class="person_identification">
          <el-form-item class="type_selector" v-if="identification == 'combined'" :prop="handleProp('identificationType')" :rules="[rl.required]">
            <el-select v-model="value.identificationType" :placeholder="$t('ls._personIdent.identificationData')">
              <el-option value="rc" :label="$t('ls._personIdent.personalIdNumber')"/>
              <el-option value="birthdate" :label="$t('ls._personIdent.dateOfBirth')"/>
            </el-select>
          </el-form-item>
          <div style="width: 100%;">
            <ls-input-rc 
              v-if="value.identificationType == 'rc'"
              :class="{ 
                'no_radius_left': identification == 'combined',
              }" 
              :prop="handleProp('rc')"
              :label="null"
              :required="required"
              v-model="value.rc"
              @update="x => { if(!x) return; value.gender = x.gender; value.birthdate = x.date;}"
            />
            <el-form-item 
              v-else-if="value.identificationType == 'birthdate'" 
              :class="{ 
                'no_radius_left': identification == 'combined', 
                'no_radius_right': gender,                
              }" 
              :prop="handleProp('birthdate')" 
              :rules="[required ? rl.required : {}]"
            >
              <el-date-picker v-bind="bind.date.birth" :placeholder="$t('ls._personIdent.date')" v-model="value.birthdate"/>
            </el-form-item>
          </div>
          <template v-if="value.identificationType == 'birthdate'">
            <el-form-item class="gender_selector" v-if="gender" :prop="handleProp('gender')" :rules="[required ? rl.required : {}]">
              <el-select v-model="value.gender">
                <el-option value="muž" :label="$t('ls._personIdent.male')"/>
                <el-option value="žena" :label="$t('ls._personIdent.female')"/>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </div>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.person_identification::v-deep {
  display: flex;
  .type_selector {
    min-width: 180px;
    .el-input__inner {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .gender_selector {
    min-width: 100px;
    .el-input__inner {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .no_radius_left .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;    
  }

  .no_radius_right .el-input__inner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    identification: {
      type: String,
      default: "rc",
    },
    gender: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    prop: String,
  },

  data() {
    return {
    };
  },
  watch: {
    "value.identificationType": function() {
      this.resetType();
    }
  },
  mounted() {
    this.resetType();
  },
  methods: {
    handleProp(prop) {
      return this.prop + '.' + prop;
    },
    resetType() {
      if(this.identification != 'combined' && this.value?.identificationType) {
        this.value.identificationType = this.identification;
      }
    }
  },
};
</script>

<i18n>
{
  "en": {
    "ls": {
      "_personIdent": {
        "identification": "Identification",
        "personalIdNumber": "Personal ID number",
        "dateOfBirth": "Date of birth",
        "identificationData": "Identification data",
        "date": "Date",
        "male": "Male",
        "female": "Female"
      }
    }
  },
  "cz": {
    "ls": {
      "_personIdent": {    
        "identification": "Identifikace",
        "personalIdNumber": "Rodné číslo",
        "dateOfBirth": "Datum narození",
        "identificationData": "Identifikační údaj",
        "date": "Datum",
        "male": "Muž",
        "female": "Žena"
      }
    }
  }
}
</i18n>

<template>
    <el-autocomplete
      :fetch-suggestions="querySearchAsync"
      class="full-width"
      value-key="nazev"
      clearable
      :placeholder="placeholder"
      :autofocus="autofocus"
      v-model="model"
      @select="handleSelect"
      @clear="handleSelect"
    ></el-autocomplete>
</template>

<script>
  export default {
    props: [
      'placeholder',
      'value',
      'autofocus'
    ],
    data() {
      return {
        options: [],
        model: this.value?.nazev,
      };
    },
    watch: {
      value: function (val) {
        this.model = val?.nazev;
      }
    },
    methods: {
      querySearchAsync(queryString, callback) {
        var results = [];
        if(queryString && queryString.length > 2)
          results = this.options.filter(this.createFilter(queryString));

        callback(results);
      },
      createFilter(queryString) {
        return obec => this.$stringHelpers.removeDiacritics(obec.nazev.toLowerCase())
                        .indexOf(this.$stringHelpers.removeDiacritics(queryString.toLowerCase())) === 0
      },

      handleSelect(item) {
        this.$emit('input', item);
      }
    },
    created() {
      if(this.value) {
        this.options.push(this.value);
      }

      this.$api.get('/external-api/obce')
        .then(res => {
          this.options = res.data;
        });
    }
  };
</script>

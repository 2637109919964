<template>
  <div>
    <div class="typedWrapper" style="text-align: center">
      <el-input
        style="width: 100%; margin-bottom: 20px;"
        @input="generateSvg"
        :placeholder="$t('signature-typed.placeholder')"
        v-model="input"
      />

      <el-radio-group v-model="chosenFont" @change="generateSvg" size="small">
        <template v-for="(font, index) in fonts">
          <el-radio-button :label="font" :value="font" :key="index+font">Font {{index+1}}</el-radio-button>
        </template>
      </el-radio-group>

      <div class="signature-border p-5 mt-3">
        <div id="svg_output" v-loading="loading">
          <svg
            v-if="svg_output"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            v-html="svg_output"
            style="max-width: 100%"
            :width="width"
            :height="heightReal"
            :viewBox="'0 '+heightPadding+' '+width+' '+heightReal">
          </svg>
        </div>
      </div>

      <!-- display invisible fonts so they get loaded -->
      <div style="height: 20px; font-size: 1px">
        <span v-for="font in fonts" :key="font" :style="'color: white; font-family: '+font">.</span>
      </div>

      <div class="flex justify-between">
        <el-color-picker
          v-model="color"
          size="medium"
          @change="generateSvg"
          :predefine="colors"
        />
        <div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @font-face {
      font-family: 'Bestfriend Signature';
      src: url('/fonts/ls/Bestfriend-Signature.ttf');
  }
  @font-face {
      font-family: 'Inkgraphy Demo';
      src: url('/fonts/ls/inkgraphy.ttf');
  }
  @font-face {
      font-family: 'JaneAusten';
      src: url('/fonts/ls/JaneAusU.ttf');
  }
  @font-face {
      font-family: 'Sweets Holy';
      src: url('/fonts/ls/sweets-holly.ttf');
  }

  .signature-border {
    border-radius: 4px;
    border: 3px double #dcdfe6;
  }

  #svg_output {
    max-width: 100%;
    min-height: 120px;
  }

  .font-button {
    width: 100%;
    border: 1px solid;
    color: inherit;

    p {
      line-height: 40px;
      margin: 0;
    }
  }

  .typedWrapper {
    width: 500px;
    height: auto;
  }

  @media (max-width: 800px), (max-height: 500px) {
    .font-button p {
      font-size: 30px;
    }

    .typedWrapper {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    .font-button p {
      font-size: 30px;
    }
  }

  @media (max-width: 450px) {
    .font-button p {
      font-size: 25px;
    }
  }

</style>

<script>
import vectorizeText from "vectorize-text";

  export default {
    props: [
      'ratio',
      'name'
    ],
    data () {
      return {
        input: '',
        loading: false,

        color: '#1D82E4',
        colors: ['#000000', '#666666', '#999999', '#B73286', '#1D82E4', '#02A2A4', '#64C264', '#ffd700', '#ff8c00', '#D3331A'],
        width: null,
        height: null,

        heightReal: null,
        heightPadding: 0,
        sign: null,
        svg_output: '',
        chosenFont: 'Bestfriend Signature',
        fonts: [
          'Bestfriend Signature',
          'Inkgraphy Demo',
          'JaneAusten',
          'Sweets Holy',
        ]
      }
    },
    created() {
      if(this.name) {
        this.input = this.name;
      }
    },

    //wait for font CSS to load
    async mounted() {
      if(this.input) {
        this.loading = true;

        for (let index = 0; index < 5; index++) {
          if(document.fonts.check('1em "Bestfriend Signature"')) break;
          await this.sleep(1000);
        }

        this.vectorize();
        this.generateSvg();
      }
    },

    methods: {
      async done() {
        this.sign = null;
        if(this.svg_output)
        {
          let svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${this.width}" height="${this.height}" viewBox="0 0 ${this.width} ${this.height}">`;
          svg += this.svg_output;
          svg += '</svg>';

          this.sign = 'data:image/svg+xml;base64,' + Buffer.from(svg).toString('base64')
        }

        return this.sign;
      },

      vectorize() {
        return vectorizeText(this.input, {
          triangles: true,
          font: this.chosenFont,
          fontWeight: 200,
          size: 400,
          height: 120,
          textBaseline: "top",
        })
      },

      generateSvg() {
        if(!this.input){
         this.svg_output = ""
         return
        }

        this.loading = true;
        let generatedData = this.vectorize();

        let xs = generatedData.positions.map(x => x[0]);
        let ys = generatedData.positions.map(x => x[1]);

        let minX = Math.min(...xs);
        let maxX = Math.max(...xs);
        let minY = Math.min(...ys);
        let maxY = Math.max(...ys);

        this.width = maxX - minX;
        this.height = this.heightReal = maxY - minY;

        //output window should match certain aspect ratio
        if(this.ratio) {
          var ratio = this.width/this.height;
          if(ratio < this.ratio) {
            var idealWidth = this.height*this.ratio;
            var diff = (idealWidth-this.width)/2;
            minX -= diff;
            maxX += diff;
            this.width = idealWidth;
            this.heightPadding = 0;
          }
          else if(ratio > this.ratio) {
            var idealHeight  = this.width/this.ratio;
            var diff = (idealHeight-this.height)/2;
            minY -= diff;
            maxY += diff;
            this.height = idealHeight;
            this.heightPadding = diff;
          }
        }

        generatedData.positions.forEach((c) => {
          c[0]-= minX;
          c[1]-= minY;
        });

        let paths = []
        generatedData.cells.forEach(c => {
          paths.push('<path d="')
          for(var j=0; j<3; ++j) {
            var p0 = generatedData.positions[c[j]]
            var p1 = generatedData.positions[c[(j+1)%3]]

            if(j == 0)
              paths.push(`M ${p0}  ${p1} `)
            else
              paths.push(`L ${p0}  ${p1} `)

            if(j == 2)
              paths.push('Z')
          }
          paths.push('" fill-rule="even-odd" fill="'+this.color+'"></path>')
        })

        this.svg_output = paths.join("");
        this.loading = false;
      }
    }
  }
</script>
<i18n>
  {
    "cz": {
      "signature-typed": {
        "placeholder": "Jméno pro podpis",
        "signature": "Podpis"
      }
    },
    "en": {
      "signature-typed": {
        "placeholder": "Name for signature",
        "signature": "Sign"
      }
    },
    "hu": {
      "signature-typed": {
        "placeholder": "Név az aláíráshoz",
        "signature": "Aláírás"
      }
    },
    "pl": {
      "signature-typed": {
        "placeholder": "Nazwisko do podpisu",
        "signature": "Podpis"
      }
    }
  }
</i18n>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.modelsLoaded),expression:"!modelsLoaded"}]},[_c('p',{staticClass:"pb-2 text-lg font-bold"},[_vm._v(_vm._s(_vm.$t('Face verification with selfie')))]),_vm._v(" "),_c('camera-initiator',{attrs:{"title":_vm.$t('Face verification with selfie'),"subtitle":_vm.$t('Click here to start the verification'),"icon":'far fa-smile',"side":'user',"image":_vm.image,"selected-device-id":_vm.selectedDeviceId},on:{"start":_vm.openWithStream}}),_vm._v(" "),(_vm.opened)?_c('camera-wrapper',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-background":"rgba(0, 0, 0, 0.8)","stream":_vm.stream,"mirror":_vm.mirror},on:{"play":function (element) { return _vm.video = element; },"resume":_vm.startDetecting,"pause":_vm.stopDetecting,"mirror":function($event){_vm.mirror = !_vm.mirror},"close":function($event){_vm.reset(); _vm.close()}},scopedSlots:_vm._u([{key:"overlay-guide",fn:function(ref){
var resume = ref.resume;
return [_c('i',{class:'far fa-smile',staticStyle:{"font-size":"40px","background":"#dddddd","padding":"1.25rem 1.5rem","border-radius":"5px"}}),_vm._v(" "),_c('span',{staticClass:"h4"},[_vm._v(_vm._s(_vm.$t('Face verification with selfie')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Make sure your face is sufficiently visible, then point your face into the highlighted circle')))]),_vm._v(" "),_c('el-button',{staticStyle:{"margin-top":"1rem"},on:{"click":resume}},[_vm._v(_vm._s(_vm.$t('Lets go')))])]}},{key:"header-controls",fn:function(ref){
var pause = ref.pause;
var close = ref.close;
var clearVideo = ref.clearVideo;
var mirror = ref.mirror;
var resume = ref.resume;
return [_c('el-button',{staticStyle:{"margin-right":"auto"},attrs:{"type":"text"},on:{"click":close}},[_c('span',{staticClass:"header-text"},[_c('i',{staticClass:"fa-solid fa-chevron-left",staticStyle:{"margin-right":"10px","font-size":"26px"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Back')))])])]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDeviceId),expression:"selectedDeviceId"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedDeviceId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.reset(); clearVideo(); resume()}]}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t('Default camera')))]),_vm._v(" "),_vm._l((_vm.devices),function(device){return _c('option',{key:device.deviceId,domProps:{"value":device.deviceId}},[_vm._v("\n                    "+_vm._s(device.label)+"\n                ")])})],2),_vm._v(" "),_c('el-button',{staticClass:"icon-button",staticStyle:{"margin-left":"auto","margin-right":"10px","transform":"rotate(90deg)"},attrs:{"type":"text","size":"medium","icon":"fas fa-arrow-down-up-across-line"},on:{"click":mirror}}),_vm._v(" "),_c('el-button',{staticClass:"icon-button",attrs:{"type":"text","size":"medium","icon":"far fa-question-circle"},on:{"click":pause}})]}},{key:"video-synced-container",fn:function(ref){
var width = ref.width;
var height = ref.height;
return [_c('div',{ref:"target",class:{'target': true, 'target--scanning': _vm.isFaceOkay},style:(_vm.targetSize(width, height))},[_c('p',{staticClass:"guide"},[(!_vm.isFaceInside)?[_vm._v(_vm._s(_vm.$t('Move your face into the circle')))]:(_vm.isFaceTooSmall)?[_vm._v(_vm._s(_vm.$t('Move closer')))]:(_vm.isFaceOkay)?[_vm._v(_vm._s(_vm.$t('Scanning')))]:_vm._e()],2),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.done)?_c('div',{staticStyle:{"height":"100%","width":"100%","background":"#FFFFFF80","position":"absolute","border-radius":"50%","z-index":"-1","display":"flex","align-items":"center","justify-content":"center"}},[_c('i',{staticClass:"fa-solid fa-check",staticStyle:{"font-size":"100px","color":"#0096B3"}})]):_vm._e()])],1)]}}],null,false,623347783)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
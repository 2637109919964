<template>
  <div v-if="model">

    <!-- countries -->
    <el-col :xs="24" :sm="12" :md="7" v-if="withCountry">
      <el-form-item :label="handleLabels($t('ls.address.country'))" :prop="handleProp(country)" :rules="handleRules()">
        <ls-countries2
          key="code"
          v-if="countriesCode"
          :code="true"
          :simple="false"
          :disabled="disabled" 
          :limit="countries" 
          v-model="model[country]" 
          :placeholder="$t('ls.address.country')"
        />        
        <ls-countries2
          key="simple"
          v-else-if="countriesSimple"
          :code="false"
          :simple="true"
          :disabled="disabled" 
          :limit="countries" 
          v-model="model[country]" 
          :placeholder="$t('ls.address.country')"
        />
        <ls-countries 
          v-else
          :disabled="disabled" 
          :limit="countries" 
          v-model="model[country]" 
          :placeholder="$t('ls.address.country')"
        />
      </el-form-item>
    </el-col>

    <!-- separated inputs -->
    <template v-if="type == 'separated'">
      <el-col :xs="24" :sm="12" :md="withCountry ? 7 : 10">
        <el-form-item :label="handleLabels($t('ls.address.street'))" :prop="handleProp(street)" :rules="handleRules()">
          <el-autocomplete 
            v-model="model[street]" 
            :placeholder="$t('ls.address.street')" 
            :fetch-suggestions="getAddress('STREET_AND_NUMBER', false)"
            value-key="WHOLE_ADDRESS"
            @select="selectAddress"
            :ref="cProp+'addrAutocomplete'"
            clearable
            :disabled="disabled"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="withCountry ? 7 : 10">
        <el-form-item :label="handleLabels($t('ls.address.city'))" :prop="handleProp(city)" :rules="handleRules()">
          <el-autocomplete 
            v-model="model[city]" 
            :placeholder="$t('ls.address.city')" 
            :fetch-suggestions="getAddress('CITY')"
            :value-key="city"
            @select="selectAddress"
            clearable
            :disabled="disabled"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="withCountry ? 3 : 4">
        <el-form-item :key="'ZIP.'+countryCode" :label="handleLabels($t('ls.address.ZIP'))" :prop="handleProp(postal)" :rules="handleRules()">
          <el-autocomplete
            v-if="countryCode == 'CZ' || countryCode == 'SK'"
            v-model="model[postal]"
            v-mask="['### ##']"
            :placeholder="$t('ls.address.ZIP')" 
            :fetch-suggestions="getAddress('ZIP')"
            :value-key="postal"
            @select="selectAddress"
            clearable
            :disabled="disabled"
          />
          <el-autocomplete
            v-else
            v-model="model[postal]"
            :placeholder="$t('ls.address.ZIP')" 
            :fetch-suggestions="getAddress('ZIP')"
            :value-key="postal"
            @select="selectAddress"
            clearable
            :disabled="disabled"
          />          
        </el-form-item>
      </el-col>
    </template>

    <!-- one input for whole address -->
    <template v-else>
      <el-col :xs="24" :sm="withCountry ? 12 : 24" :md="withCountry ? 17 : 24">
        <el-form-item :label="handleLabels($t('ls.address.address'))" :prop="handleProp(whole)" :rules="handleRules()">
          <el-autocomplete
            v-model="model[whole]"
            :placeholder="$t('ls.address.whole-address')" 
            :fetch-suggestions="getAddress('WHOLE_ADDRESS', false)"
            :value-key="whole"
            @select="selectAddress"
            :ref="cProp+'addrAutocomplete'"
            clearable
            :disabled="disabled"
          />
        </el-form-item>
      </el-col>
    </template>

  </div>
</template>

<script>
  export default {
    inject: {
      lsFormItem: {
        default: null
      }
    },    
    props: {
      value: {},
      prop: {
        type: String,
        default: undefined
      },
      labels: {
        type: Boolean,
        default: true
      },
      required: {
        type: Boolean,
        default: false
      },
      countries: {
        type: [Array, Boolean],
        default: null
      },
      countriesSimple: {
        type: Boolean,
        default: false
      },
      countriesCode: {
        type: Boolean,
        default: true
      },
      country: {
        type: String,
        default: "country"
      },
      street: {
        type: String,
        default: "STREET_AND_NUMBER"
      },
      city: {
        type: String,
        default: "CITY"
      },
      postal: {
        type: String,
        default: "ZIP"
      },
      whole: {
        type: String,
        default: 'WHOLE_ADDRESS'
      },
      type: {
        type: String,
        default: "separated" //"separated", "merged"
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        model: this.value,
        withCountry: this.countries === false ? false : true
      };
    },
    created() {
      //value is null - create address object
      if(!this.value) {
        const addr = this.$globalHelpers.getEmptyAddress(this.countriesCode ? 'cz' : this.countriesSimple ? 'Česko' : undefined);
        this.$emit('input', addr); //fix el-form-item :prop being undefined at init
        this.model = addr;
      }
    },
    mounted() {
      //value is string - try parsing to address object format
      if(typeof this.value === "string") {
        var addr = this.$globalHelpers.getEmptyAddress(this.countriesCode ? 'cz' : this.countriesSimple ? 'Česko' : undefined);
        addr[this.whole] = this.value;
        //for separated input type, fetch from API
        if(this.type == 'separated') {
          addr[this.street] = this.value;
          this.model = addr;

          this.fetch('STREET_AND_NUMBER').then(suggestions => {
            //we got one exact result, fill
            if(suggestions.length == 1)
              this.selectAddress(suggestions[0], false);
          });
        }
        
      } 
    },
    computed: {
      /**
       * Try to automagically calculate prop for el-form-item
       */
      cProp() {
        //prop is set, use it
        if(this.prop) {
          return this.prop;
        }

        //prop is not set, extract it from v-model
        let vModel = this.$vnode.data.model.expression;
        let property = Vue.component('el-form-item').extendOptions.methods._removeRoot(vModel);

        //special case when inside ls-loop
        if(this.lsFormItem) {
          let root = Vue.component('el-form-item').extendOptions.methods._removeRoot(this.lsFormItem.root);
          property = Vue.component('el-form-item').extendOptions.methods._removeModel(property);

          return `${root}.${this.lsFormItem.index}.${property}`;
        }

        //normal case, just return property from v-model
        return property;
      },
      countryCode() {
        if(!(this.countriesCode || this.countriesSimple))
          return this.model[this.country]?.a2;
        if(this.model[this.country] == 'Česko' || this.model[this.country] == 'cz')
          return "CZ";
        if(this.model[this.country] == 'Slovensko' || this.model[this.country] == 'sk')
          return "SK";
        
        return null;
      }
    },
    watch: {
      value(val) { //data changed outside component
        this.model = val;
      },
      model: {
        handler(val) {
          if(val) {
            if(this.type == "separated") { //merge WHOLE_ADDRESS manually allowing to work with non-confirmed changes 
              var zip_city = [val[this.postal], val[this.city]].filter(Boolean).map(s => s.trim()).join(" ");
              val[this.whole] = [val[this.street], zip_city].filter(Boolean).map(s => s.trim()).join(", ");
            }
            val.flags = undefined;
          }
          this.$emit('input', val);
        },
        deep: true
      }
    },
    methods: {
      isRequired() {
        return this.required != undefined && this.required != false;
      },
      handleProp(prop) {
        return this.isRequired() ? this.cProp+'.'+prop : undefined;
      },
      handleRules() {
        return this.isRequired() ? [this.rl.required] : [];
      },
      handleLabels(label) {
        return this.labels == false ? '' : label;
      },

      selectAddress(input, focus = true) {
        if(input.flags.WHOLE_ADDRESS != "true" && focus)
          this.$refs[this.cProp+'addrAutocomplete'].focus();

        this.model = Object.assign({}, this.model, this.mapAddress(input));
      },

      mapAddress(input) {
        var output = {};

        if(input.STREET_AND_NUMBER) {
          output[this.street] = input.STREET_AND_NUMBER;
        }

        if(input.CITY) {
          output[this.city] = input.CITY;
        }

        let zip = input.ZIP;
        if(zip) {
          if(this.countryCode == 'CZ' || this.countryCode == 'SK') {
            output[this.postal] = this.splitZip(zip);
          }
          else {
            output[this.postal] = zip;
          }
        }

        if(input.WHOLE_ADDRESS) {
          if(output[this.street] && output[this.postal] && output[this.city]) {
            output[this.whole] = output[this.street] + ", " + output[this.postal] + " " + output[this.city];
          }
          else {
            output[this.whole] = input.WHOLE_ADDRESS;
          }
        }

        return output;
      },

      splitZip(zip) {
        return zip.replace(/(\d{3})(\d{2})/g, "$1 $2");
      },

      getAddress(type, allowEmpty = true) { 
        return (input, cb) => {
          if((allowEmpty || input) && (this.countryCode == 'CZ' || this.countryCode == 'SK')) {
            this.fetch(type).then(suggestions => {
              cb(suggestions);
            }).catch(() => {
              cb([]);
            });
          }
          else {
            cb([]);
          }
        }
      },

      fetch(type) {
        if(type == "WHOLE_ADDRESS") {
          var data = {
            COUNTRY: this.countryCode,
            WHOLE_ADDRESS: this.model[this.whole]
          };
        } 
        else {
          var data = {
            COUNTRY: this.countryCode,
            STREET_AND_NUMBER: this.model[this.street],
            CITY: this.model[this.city],
            ZIP: this.model[this.postal]
          };
        }

        return this.$api({
          url: '/external-api/smartform/' + type,
          method: "post",
          data: data,
        }).then(x => Object.values(x.data?.suggestions)?.map(x => { x.values.flags = x.flags; return x.values; }));
      },

    },

  };
</script>

<i18n>
  {
    "cz": {
      "ls": {
        "address": {
          "country": "Země",
          "street": "Ulice",
          "city": "Město",
          "ZIP": "PSČ",
          "address": "Adresa",
          "whole-address": "Ulice, PSČ a Město"
        }
      }
    },
    "sk": {
      "ls": {
        "address": {
          "country": "Krajina",
          "street": "Ulica",
          "city": "Mesto",
          "ZIP": "PSČ",
          "address": "Adresa",
          "whole-address": "Ulica, PSČ a Mesto" 
        }
      }
    },
    "en": {
      "ls": {
        "address": {
          "country": "Country",
          "street": "Street",
          "city": "City",
          "ZIP": "ZIP",
          "address": "Address",
          "whole-address": "Street, ZIP & City"
        }
      }
    },
    "de": {
      "ls": {
        "address": {
          "country": "Land",
          "street": "Straße",
          "city": "Stadt",
          "ZIP": "PLZ",
          "address": "Adresse",
          "whole-address": "Straße, PLZ und Stadt"
        }
      }
    },
    "pl": {
      "ls": {
        "address": {
          "country": "Kraj",
          "street": "Ulica",
          "city": "Miasto",
          "ZIP": "Kod pocztowy",
          "address": "Adres",
          "whole-address": "Ulica, kod pocztowy i miasto"
        }
      }
    },
    "hu": {
      "ls": {
        "address": {
          "country": "Ország",
          "street": "Utca",
          "city": "Város",
          "ZIP": "Irányítószám",
          "address": "Cím",
          "whole-address": "Utca, ZIP és város"
        }
      }
    }
  }
</i18n>

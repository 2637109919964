<template>
    <el-row v-if="compareFunction(prop, contact, candidate)">
        <el-col :span="6" style="text-align: right; padding-right: 30px">
            <strong>{{title}}:</strong>
        </el-col>
        <el-col :span="9">
            <span :class="{ crossed: truthSwitch == 'contact'}">{{ print(contact[prop]) }}</span>
            <span :class="{ hidden: truthSwitch != 'contact'}">{{ print(candidate[prop]) }}</span>
        </el-col>
        <el-col :span="9">
            <span :class="{ crossed: truthSwitch == 'document'}">{{ print(candidate[prop]) }}</span>
            <span :class="{ hidden: truthSwitch != 'document'}">{{ print(contact[prop]) }}</span>
        </el-col>
    </el-row>
</template>

<script>
export default {
    props: {
        prop: { 
            type: String,
            required: true
        },
        title: { 
            type: String,
            required: true
        },
        contact: { 
            type: Object,
            required: true
        },
        candidate: { 
            type: Object,
            required: true
        },
        truthSwitch: { 
            type: String|null,
            required: true
        },
        compareFunction: { 
            type: Function,
            required: true
        },
        format: { 
            type: String
        },
    },

    data() {
        return {

        }
    },

    methods: {
        /**
         * Apply vue formatting filter to value if set
         */
        print(value) {
            if(this.format) {
                value = this.$options.filters[this.format](value);
            }

            return value || "-";
        }
    },
};
</script>

<style lang="scss" scoped>
    .crossed {
        text-decoration: line-through;
    }

    .hidden {
        display: none;
    }
</style>

import isMobile from 'ismobilejs';

import { setOne as vueSetPath } from 'vue-set-path';

/*
 * Vue.js global mixins - app and external forms
 */

function rangePickerConstuctor(array, weekends = false, range = false) {
  function picker(count, type) {
    var date1 = new Date();
    var date2 = new Date();

    var name;
    if(type == 'day') {
      date1.setDate(date1.getDate() + count);
      name = Vue.options.filters.pluralize(Math.abs(count), 'den', 'dny', 'dnů');
    }
    else if(type == 'week') {
      date1.setDate(date1.getDate() + 7*count);
      name = Vue.options.filters.pluralize(Math.abs(count), 'týden', 'týdny', 'týdnů');
    }
    else if(type == 'month') {
      date1 = moment().add(count, 'M').toDate();
      name = Vue.options.filters.pluralize(Math.abs(count), 'měsíc', 'měsíce', 'měsíců');
    }
    else if(type == 'year') {
      date1.setFullYear(date1.getFullYear() + count);
      name = Vue.options.filters.pluralize(Math.abs(count), 'rok', 'roky', 'let');
    }

    return {
      text: (count > 0 ? '+' : '-') + name,
      onClick: (picker) => {
        if(range && count < 0)
          picker.$emit('pick', [date1, date2]);
        else if(range && count > 0)
          picker.$emit('pick', [date2, date1]);
        else
          picker.$emit('pick', date1);

        if(weekends) {
          var day = date1.getDay();
          // 6 = Saturday, 0 = Sunday
          if (day === 6)
            Vue.prototype.$alert('Zvolené datum vychází na sobotu.', 'Pozor');
          if (day === 0)
            Vue.prototype.$alert('Zvolené datum vychází na neděli.', 'Pozor');
        }
      }
    }
  }

  return {
    ...(range ? { defaultTime: ["00:00:00", "23:59:59"] } : {}),
    pickerOptions: {
      firstDayOfWeek: 1,
      shortcuts: array.map(entry => picker(entry[0], entry[1], weekends, range))
    }
  }
};


Vue.mixin({
  data: function () {
    return {
      //vue is loaded and ready to go!
      vueLoaded: true,
      vueLoading: false,

      //just some useful values
      hostname: window.proculus,

      isMobile: isMobile(),

      bind: {
        picker: rangePickerConstuctor,
        date: {
          birth: {
            'default-value': "1970-01-01",
          },
          past1: rangePickerConstuctor([
            [-1, 'week'],
            [-1, 'month'],
            [-3, 'month'],
            [-1, 'year'],
          ], false),
          past2: rangePickerConstuctor([
            [-1, 'week'],
            [-2, 'week'],
            [-3, 'week'],
            [-1, 'month'],
            [-2, 'month'],
            [-3, 'month'],
            [-4, 'month'],
            [-5, 'month'],
            [-6, 'month'],
          ], true),
          future1: rangePickerConstuctor([
            [1, 'week'],
            [1, 'month'],
            [3, 'month'],
            [1, 'year'],
          ], false),
          future2: rangePickerConstuctor([
            [1, 'week'],
            [2, 'week'],
            [3, 'week'],
            [1, 'month'],
            [2, 'month'],
            [3, 'month'],
            [4, 'month'],
            [5, 'month'],
            [6, 'month'],
          ], true),
        },
        range: {
          past1: rangePickerConstuctor([
            [-1, 'week'],
            [-1, 'month'],
            [-3, 'month'],
            [-1, 'year'],
          ], false, true),
          past2: rangePickerConstuctor([
            [-1, 'week'],
            [-2, 'week'],
            [-3, 'week'],
            [-1, 'month'],
            [-2, 'month'],
            [-3, 'month'],
            [-4, 'month'],
            [-5, 'month'],
            [-6, 'month'],
          ], true, true),
          future1: rangePickerConstuctor([
            [1, 'week'],
            [1, 'month'],
            [3, 'month'],
            [1, 'year'],
          ], false, true),
          future2: rangePickerConstuctor([
            [1, 'week'],
            [2, 'week'],
            [3, 'week'],
            [1, 'month'],
            [2, 'month'],
            [3, 'month'],
            [4, 'month'],
            [5, 'month'],
            [6, 'month'],
          ], true, true),
        },
      },

      /* custom validation rules helpers */
      rl: {
        required: { required: true, validator: this.$customRules.validateRequired },
        checked: { required: true, validator: this.$customRules.validateCheckbox },
        email: { validator: this.$customRules.validateEmail },
        number: { validator: this.$customRules.validateNumber },
        numberString: { validator: this.$customRules.validateNumberString },
        rc: { validator: this.$customRules.validateRC },
        cu: { validator: this.$customRules.validateCU },
        ico: { validator: this.$customRules.validateICO },
        phone: { validator: this.$customRules.validatePhone },
        drivingLicenceCZ: { validator: this.$customRules.validateDrivingLicenceCZ },
        tillToday: { validator: this.$customRules.tillToday },
        fromToday: { validator: this.$customRules.fromToday },
      }
    }
  },

  methods: {
    /** get data from path in object */
    dataGet(path, data = null) {
      if(!data) {
        data = this.doc;
      }
      return _.get(data, path);
    },

    /** set data to path in object */
    dataSet(path, value, data = null) {
      if(!data) {
        data = this.doc;
      }

      vueSetPath(data, path, value);
    },

    /* await sleep(ms) */
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    /* return filter function to compare values (fe. for autocomplete) */
    basicFilter(query, param = null) {
        return (d) => {
          var data = param ? d[param] : d;
          return (this.$stringHelpers.removeDiacritics(data ? data.toLowerCase() : "").indexOf(this.$stringHelpers.removeDiacritics(query.toLowerCase())) >= 0);
        };
    },

    /* Return query search function for autocomplete. Default value for param is default of el-autocomplete */
    querySearch(promise, param = 'value') {
      return async (queryString, cb) => {
        var data = await promise;
        var results = queryString ? data.filter(this.basicFilter(queryString, param)) : data;
        if(param == null)
          results = this.mapToValue(results);
        cb(results);
      }
    },

    /* transform simple array to array of objects with parameter "value" */
    mapToValue(simpleArr) {
      return simpleArr.map(x => { return { value: x }; });
    },

    /* group array by function and map it to array of objects with parameter "value" */
    groupBy: async function(arrayPromise, groupByFunction) {
      var arr = await arrayPromise;
      arr = arr.filter(x => { try { return groupByFunction(x); } catch(e) {} });
      var group = Object.getOwnPropertyNames(_.groupBy(arr, groupByFunction));
      return this.mapToValue(group.filter(x => x && x != "null"));
    },

    groupBy2: async function(arrayPromise, groupByFunction) {
      var arr = await arrayPromise;
      arr = arr.filter(x => { try { return groupByFunction(x); } catch(e) {} });
      var namesNew = _.groupBy(arr, groupByFunction);

      var newArray = [];
      for (var key in namesNew) {
        if (namesNew.hasOwnProperty(key)) {
          newArray.push({
            value: key,
            data: namesNew[key]
          });
        }
      }

      return newArray;
    },

    /* use compare function "fn" to find item in array and return it */
    findItem: async function(arrayPromise, fn) {
      var array = await arrayPromise;
      return array[array.findIndex(fn)];
    },

    /* add new item to array (usually object) */
    addArrayItem(array, template) {
      array.push(template);
    },

    /* remove item from array */
    removeArrayItem(array, index) {
      array.splice(index, 1);
    },

    /*
      obchodny rejstrik - search by name and deal with multiple subjects trough popup
      for basic = false, selected item is send to "orSearchByIco" (to obtain more information)
      for basic = true, just ic, name and address are obtained - otoh, this also works for ZR and other registers
    */
    orSearchByName(person, basic=false) {
      if(!person.name)
        return;
      var fn = this.$orAPI.findSubjectBasic('name', person.name);

      person.loading = true;
      fn.then(res => {
        console.log(res, basic);
        person.loading = false;
        if(res.error && res.error === "-1") {
          this.$alert(this.$i18n.t('mixins.ares.collision'), this.$i18n.t('mixins.ares.collisionTitle'));
          return;
        }

        if(res.length == 1) {
          if(basic || !res[0].ic) { // if we do not have ICO we are unable to searching by it, so we go basic instead
            var t = person.type;
            var c = Object.assign({}, person.contact);
            let registration_country = person.registration_country;
            this.resetObj(person);
            person.type = t;
            person.contact = c;
            person.registration_country = registration_country;
            person = _.merge(person, res[0]);
          } else {
            person.loading = true;
            person = _.merge(person, res[0]);
            this.orSearchByIco(person);
          }

        } else if(res.length > 1) {//multiple results returned - handle popup
          const h = this.$createElement;
          var buttonsArr = [];

          for (var i = res.length - 1; i >= 0; i--) {
            buttonsArr.push( //construct button with handler function for popup
              h("div", { class: 'multiButtons-wrapper' }, [
                h("el-button", { class: 'multiButtons-button', attrs: { 'data-ref': i }, on: { click: (test) => {
                  if(basic) {
                    var t = person.type;
                    var c = Object.assign({}, person.contact);
                    this.resetObj(person);
                    person.type = t;
                    person.contact = c;
                    person = _.merge(person, res[test.target.dataset.ref]);
                  } else {
                    person = _.merge(person, res[test.target.dataset.ref]);
                    this.orSearchByIco(person);
                  }

                  this.$msgbox.close();
                  } } }, [
                  h("span", {class:"h5", attrs: { 'data-ref': i }}, res[i].name + " (" + res[i].ic + ")"),
                  h("div", {class:"bottom clearfix", attrs: { 'data-ref': i }}, [
                    h("span", {class:"small-text", attrs: { 'data-ref': i }}, res[i].address)
                  ])
                ])
              ])
            );
          }

          this.$msgbox({title: 'Vyberte subjekt z ARES', customClass: "halfWidth", closeOnClickModal: false,
            showConfirmButton: false, showCancelButton: true, cancelButtonText: "Zrušit",
            message: h("div", {style: 'overflow-y: auto; max-height: calc(100vh - 150px)'}, buttonsArr)
          });
        } else {
          this.$alert(this.$i18n.t('mixins.ares.notFoundByName'), this.$i18n.t('mixins.ares.notFoundTitle'));
        }
      })
      .catch(error => {
        console.log(error);
        this.$alert('Omlouváme se, obchodní rejstřík právě teď neodpovídá. Zkuste to prosím párkrát znovu a pokud se data nestáhnou, můžete pokus opakovat později anebo je vyplnit ručně. Odezva obchodní rejstříku se zpravidla zlepší během pár desítek minut.', 'Někde je problém');
        person.loading = false;
        this.$sentry.captureException(error);
      });

    },

    /*
      obchodny rejstrik - search by ICO
      for basic = true, just ic, name and address are obtained - otoh, this also works for ZR and other registers
    */
    orSearchByIco(person, basic=false) {
      if(!person.ic)
        return;

      var fn;
      if(basic)
        fn = this.$orAPI.findSubjectBasic('ic', person.ic);
      else
        fn = this.$orAPI.findSubject(person.ic);

      person.loading = true;
      fn.then(res => {
        console.log(res);
        person.loading = false;

        var t = person.type;
        var c = Object.assign({}, person.contact);
        let registration_country = person.registration_country;
        this.resetObj(person);
        person.type = t;
        person.contact = c;
        person.registration_country = registration_country;

        if(basic)
          res = res[0];
        person = _.merge(person, res);

        //hotfix for reactivity
        if(res && res.Statutarni_organ && res.Statutarni_organ.length) {
          person.Statutarni_organ = res.Statutarni_organ;
        }
      })
      .catch(error => {
        if(error?.response?.status == 404) {
          this.$alert(this.$i18n.t('mixins.ares.notFoundByIC'), this.$i18n.t('mixins.ares.notFoundTitle'));
        }
        else {
          this.$alert(this.$i18n.t('mixins.ares.error'), this.$i18n.t('mixins.ares.errorTitle'));
          this.$sentry.captureException(error);
        }

        person.loading = false;
      });

    },

    //alias for basic orSearchByName
    orSearchByNameBasic(person) {
      this.orSearchByName(person, true);
    },

    //alias for basic orSearchByIco
    orSearchByIcoBasic(person) {
      this.orSearchByIco(person, true);
    },

    getAnabixUrl() {
      const anabix = this.hostname + "/proculus-api/anabix/";
    return this.$context.client.id && this.$context.role?.superuser ? anabix + this.$context.client.id : anabix;
    },

    //reset object obj properties to "defaults" - works with nested too
    resetObj(obj) {
      var defaults = {
        String: null,
        number: null,
        integer: null,
        Array: [],
        bool: false,
        boolean: false,
      }

      var thiss = this;
      _(obj).forEach(
        function(value, key, objRef) {
          if (_.isObject(value)) {
              thiss.resetObj(objRef[key]);
          } else {
            var myvarType = typeof value;
            objRef[key] = defaults[myvarType];
          }
        }
      );
    },

    scrollTo(id, offset = 0) {
      let el = document.getElementById(id);
      let distance = el?.getBoundingClientRect()?.top + window.scrollY + offset;


      this.$nextTick(async () => {
        await this.$nextTick();
        await this.sleep(100);
        await this.$nextTick();

        window.scrollTo({
          top: distance,
          behavior: 'smooth'
        })
      });
    },

    /* multi-step navigation in form */
    next(validate = true) {
      if(validate) { //validate before switching page
        this.$refs['form'].validate((valid) => {
          if (valid) {//NEXT
          this.doc.active++;
          this.scrollTo('ls-component');
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t('mixins.next'),
              type: 'error'
            });
            return false;
          }
        });
      }
      else { //just go NEXT
        this.doc.active++;
        this.scrollTo('ls-component');
      }

    },
    prev() {
      this.doc.active--;
      this.scrollTo('ls-component');
    },

    goTo(active) {
      if(this.doc.active == active)
        return;
      if(this.doc.active > active)
        this.doc.active = active;
      else {
        var steps = active - this.doc.active;
        for (var i = 0; i < steps; i++) {
          this.next();
        }
      }
    },

    /**
     * validate and finish form
     * RL hotfix: store first finish document as sign_file and sign_rename
     * @TODO: rework to more robust with REAL support of multiple files
     */
    finish(inputs = null) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.doc.finished = true;
          this.next();
          if(inputs?.[0]?.file) {
            this.$api.put('/proculus-api/dokumenty/save/'+this.doc.id, {
              sign_file: inputs[0].file,
              sign_rename: inputs[0].rename ?? inputs[0].name
            });
          }
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t('mixins.next'),
            type: 'error'
          });

          return false;
        }
      });
    },

    /* back to editing of previously finished form */
    unfinish() {
      if(this.doc.closed) {
        this.$message({
          showClose: true,
          message: this.$i18n.t('mixins.unfinish'),
          type: 'warning'
        });

        return;
      }
      this.doc.finished = false;
      this.prev();
    },

    /**
     * Get extension from filename
     */
    getExtension(filename) {
      var re = /(?:\.([^.]+))?$/;
      return re.exec(filename)[1];
    },

    /* HELPER fn - if "filename" extension does not match "extension", change it (or add if it's without extension) */
    addExtension(filename, extension) {
      var template = null;
      if(filename && typeof filename === 'object') {
        template = filename.template;
        filename = filename.file;
      }

      var ext = this.getExtension(filename);

      if(ext != extension) {
        if(ext) {
          filename = filename.slice(0, -(ext.length+1));
        }
        filename += "."+extension;
      }

      if(template) {
        return {
          template: template,
          file: filename
        };
      }

      return filename;
    },

    /* store versions for download */
    downloadS: async function(type=null, rename="") {
      this.download(type, rename, undefined, true);
    },

    downloadXlsx: async function(type=null, rename="", store=false) {
      rename = this.$stringHelpers.removeFilenameIllegals(rename);

        if(!type) //use default docx
          type = this.template.defaultFile;
        if(rename) //rename output file?
          rename="/"+rename;

        //send download request
        this.justDownload(type, rename, store);
    },

    /* Handle submit button - download primary document or different "type" document */
    download: async function(type=null, rename="", changeExt=true, store=false, attachments=null) {
      rename = this.$stringHelpers.removeFilenameIllegals(rename);

      if(!type) //use default docx
        type = this.template.defaultFile;

      if(changeExt) { //handle correct file extensions -> default docx
        type = this.addExtension(type, "docx");
        if(rename)
          rename = this.addExtension(rename, "docx");
      }

      //send download request
      this.justDownload(type, rename, store, attachments);
    },

    /* download button returning file converted to PDF */
    downloadPdf: async function(type=null, rename="", store=false, attachments=null) {
      if(!type)
        type = this.template.defaultFile;

      type = this.addExtension(type, "pdf");
      if(rename)
        rename = this.addExtension(rename, "pdf");

      this.download(type, rename, false, store, attachments);
    },

    /* download button with validation (useful for forms without "overview step") */
    downloadFinish: async function(type=null, rename="", pdf=false, store=false) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(pdf)
            this.downloadPdf(type, rename, store);
          else
            this.download(type, rename, store);
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t('mixins.next'),
            type: 'error'
          });
          return false;
        }
      });
    },

    /* download finish button returning file converted to PDF */
    downloadPdfFinish: async function(type=null, rename="", store=false) {
      this.downloadFinish(type, rename, true, store);
    },

    /* returns a URL promise for download file */
    getDownloadUrl: async function(type=null, keep=false, rename="", changeExt=true, attachments = null) {
      rename = this.$stringHelpers.removeFilenameIllegals(rename);

      if(!type)
        type = this.template.defaultFile;

      if(changeExt) { //handle correct file extensions -> default docx
        type = this.addExtension(type, "docx");
        if(rename)
          rename = this.addExtension(rename, "docx");
      }

      return this.justGetUrl(type, keep, rename, attachments);
    },

    /* getDownloadUrl PDF alternative */
    getDownloadPdfUrl: async function(type=null, keep=false, rename="", attachments = null) {
      if(!type)
        type = this.template.defaultFile;

      type = this.addExtension(type, "pdf");
      if(rename)
        rename = this.addExtension(rename, "pdf");

      return this.getDownloadUrl(type, keep, rename, false, attachments);
    },

  }

});

<template>
  <el-table :data="data" style="width: 100%" class="remove-header" :show-header="false">
    <!-- name -->
    <el-table-column type="expand" width="50">
      <template slot-scope="scope">
        <template v-if="scope.row.type == 'SJM'">
          <el-row>
            <el-col :sm="12">
              <strong>Partner 1:</strong><br/>
              <Expand :data="scope.row.sjm.p1" style="margin-left: 10px" />
            </el-col>
            <el-col :sm="12">
              <strong>Partner 2:</strong><br/>
              <Expand :data="scope.row.sjm.p2" style="margin-left: 10px" />
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <Expand :data="scope.row" />
        </template>
      </template>
    </el-table-column>

    <!-- name -->
    <el-table-column width="200" class-name="table-style-name">
      <template slot-scope="scope">
        <template v-if="scope.row.type == 'SJM'">
          {{ scope.row.sjm.p1.name }}
          <br>
          {{ scope.row.sjm.p2.name }}
        </template>
        <template v-else>
          {{ scope.row.name }}
        </template>
      </template>
    </el-table-column>

    <!-- identification -->
    <el-table-column width="130">
      <template slot-scope="scope">
        <template v-if="scope.row.type == 'PO' || scope.row.type == 'OSVC'">
          {{ scope.row.or.ic }}
        </template>
        <template v-else-if="scope.row.type == 'FO'">
          <template v-if="scope.row.person.identificationType == 'rc'">
            {{ scope.row.person.rc }}
          </template>
          <template v-else>
            {{ scope.row.person.birthdate | formatDate }}
          </template>
        </template>
        <template v-if="scope.row.type == 'SJM'">
          <template v-if="scope.row.sjm.p1.identificationType == 'rc'">
            {{ scope.row.sjm.p1.rc }}
          </template>
          <template v-else>
            {{ scope.row.sjm.p1.birthdate | formatDate }}
          </template>
          <br>
          <template v-if="scope.row.sjm.p2.identificationType == 'rc'">
            {{ scope.row.sjm.p2.rc }}
          </template>
          <template v-else>
            {{ scope.row.sjm.p2.birthdate | formatDate }}
          </template>          
        </template>
      </template>
    </el-table-column>

    <!-- address -->
    <el-table-column>
      <template slot-scope="scope">
        <template v-if="scope.row.type == 'SJM'">
          {{ scope.row.sjm.p1.address | formatAddress }}
          <br>
          {{ scope.row.sjm.p2.address | formatAddress }}
        </template>
        <template v-else>
          {{ scope.row.address | formatAddress }}
        </template>
      </template>
    </el-table-column>

  </el-table>
</template>

<style lang="scss" scoped>

</style>

<script>
  import Expand from './_expandOverview';

  export default {
    components: {
      Expand
    },
    props: {
      data: {
        type: Array,
        required: true
      }
    }
  }
</script>

<template>
    <div v-loading="!modelsLoaded">
        <p class="pb-2 text-lg font-bold">{{ $t('Face verification with selfie') }}</p>
        <camera-initiator
            :title="$t('Face verification with selfie')"
            :subtitle="$t('Click here to start the verification')"
            :icon="'far fa-smile'"
            :side="'user'"
            :image="image"
            :selected-device-id="selectedDeviceId"
            @start="openWithStream"
        />

        <camera-wrapper v-if="opened"
            v-loading="loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :stream="stream"
            :mirror="mirror"
            @play="element => video = element"
            @resume="startDetecting"
            @pause="stopDetecting"
            @mirror="mirror = !mirror"
            @close="reset(); close()">

            <template #overlay-guide="{ resume }">
                <i :class="'far fa-smile'" style="font-size: 40px; background: #dddddd; padding: 1.25rem 1.5rem; border-radius: 5px"></i>
                <span class="h4">{{ $t('Face verification with selfie') }}</span>
                <span>{{ $t('Make sure your face is sufficiently visible, then point your face into the highlighted circle') }}</span>
                <el-button @click="resume" style="margin-top: 1rem">{{ $t('Lets go') }}</el-button>
            </template>

            <template #header-controls="{ pause, close, clearVideo, mirror, resume }">
                <el-button type="text" @click="close" style="margin-right: auto">
                    <span class="header-text">
                        <i class="fa-solid fa-chevron-left" style="margin-right: 10px; font-size: 26px"></i>
                        <span>{{ $t('Back') }}</span>
                    </span>
                </el-button>

                <select v-model="selectedDeviceId" @change="reset(); clearVideo(); resume()">
                    <option :value="null">{{ $t('Default camera') }}</option>
                    <option v-for="device in devices"
                        :key="device.deviceId"
                        :value="device.deviceId">
                        {{ device.label }}
                    </option>
                </select>

                <el-button @click="mirror"
                    style="margin-left: auto; margin-right: 10px; transform: rotate(90deg);"
                    class="icon-button"
                    type="text"
                    size="medium"
                    icon="fas fa-arrow-down-up-across-line"
                />

                <el-button @click="pause"
                    class="icon-button"
                    type="text"
                    size="medium"
                    icon="far fa-question-circle"
                />
            </template>

            <template #video-synced-container="{ width, height }">
                <div ref="target" :class="{'target': true, 'target--scanning': isFaceOkay}" :style="targetSize(width, height)">
                    <p class="guide">
                        <template v-if="!isFaceInside">{{ $t('Move your face into the circle') }}</template>
                        <template v-else-if="isFaceTooSmall">{{ $t('Move closer') }}</template>
                        <template v-else-if="isFaceOkay">{{ $t('Scanning') }}</template>
                    </p>
                    <transition name="fade">
                        <div v-if="done" style="height: 100%; width: 100%; background: #FFFFFF80; position: absolute; border-radius: 50%; z-index: -1; display:flex; align-items:center; justify-content: center;">
                            <i class="fa-solid fa-check" style="font-size: 100px; color: #0096B3"></i>
                        </div>
                    </transition>
                </div>
            </template>
        </camera-wrapper>
    </div>
</template>

<script type="text/javascript">
import * as faceapi from 'face-api.js';
import CameraWrapper from '@/components/checks/kyc/camera/camera-wrapper.vue'
import CameraInitiator from '@/components/checks/kyc/camera/camera-initiator.vue'

export default {
    components: {
        CameraWrapper,
        CameraInitiator,
    },
    data() {
        return {
            progress: 0,
            bufferRatio: 1,

            detectionInterval: null,
            targetPosition: null,
            facePosition: null,
            stream: null,
            video: null,
            image: null,

            devices: null,
            selectedDeviceId: null,

            modelsLoaded: false,
            mirror: false,
            loading: false,
            opened: false,
            done: false,
        }
    },
    created() {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri(this.hostname + '/faceapi-models'),
            faceapi.nets.faceLandmark68TinyNet.loadFromUri(this.hostname + '/faceapi-models'),
            faceapi.nets.faceExpressionNet.loadFromUri(this.hostname + '/faceapi-models'),
        ]).then(() => this.modelsLoaded = true)
    },
    watch: {
        progress(value) {
            if (this.detectionInterval && value >= 100) {
                clearInterval(this.detectionInterval);
                this.done = true;
            }
        },
        done(value) {
            if (value) {
                this.capture(() => {
                    setTimeout(() => {
                        this.$emit('submit', this.image)
                        this.reset()
                        this.close()
                    }, 1800)
                })
            }
        },
    },
    computed: {
        isFaceInside() {
            if (!this.facePosition || !this.targetPosition) return false;

            const buffer = 0.1;

            const isFaceInsideX =
                this.facePosition.x >= this.targetPosition.x - this.targetPosition.width * buffer &&
                this.facePosition.x + this.facePosition.width <= this.targetPosition.x + this.targetPosition.width + this.targetPosition.width * buffer;

            const isFaceInsideY =
                this.facePosition.y >= this.targetPosition.y - this.targetPosition.height * buffer &&
                this.facePosition.y + this.facePosition.height <= this.targetPosition.y + this.targetPosition.height + this.targetPosition.height * buffer;

            return isFaceInsideX && isFaceInsideY
        },
        isFaceTooSmall() {
            if (!this.facePosition || !this.targetPosition) return false;

            const widthRatio = this.facePosition.width / this.targetPosition.width * this.bufferRatio;
            const heightRatio = this.facePosition.height / this.targetPosition.height * this.bufferRatio;

            return widthRatio < 0.8 && heightRatio < 0.8
        },
        isFaceOkay() {
            return this.isFaceInside && !this.isFaceTooSmall
        },
    },
    methods: {
        close() {
            this.opened = false;
        },
        targetSize(width, height) {
            const size = Math.min(width, height);
            return {
                'width': (size * 0.8) + 'px',
                'height': (size * 0.8) + 'px',
            }
        },
        startDetecting() {
            this.loading = true;
            this.detectionInterval = setInterval(async () => {
                if(!this.video || !this.video.videoWidth || !this.video.videoHeight) return;

                const face = await faceapi
                    .detectSingleFace(this.video, new faceapi.TinyFaceDetectorOptions())
                    .withFaceLandmarks(true)

                this.loading = false;
                this.updateFacePosition(face);
                this.syncTargetPosition();
                this.updateProgress();
                this.handleBuffer();
            }, 250);
        },
        handleBuffer() {
            if (!this.isFaceOkay) {
                this.bufferRatio = 1;
                return
            }
            if (this.bufferRatio === 1) {
                this.bufferRatio = 1.1;
            }
        },
        updateFacePosition(face = null) {
            if (face === null) {
                this.facePosition = null
                return;
            }

            const resizedDetection = faceapi.resizeResults(face, {
                width: this.video.clientWidth, height: this.video.clientHeight
            });

            this.facePosition = {
                width: resizedDetection.detection.box.width,
                height: resizedDetection.detection.box.height * 1.2,
                x: resizedDetection.detection.box.x,
                y: resizedDetection.detection.box.y - resizedDetection.detection.box.height * 0.3,
            }
        },
        updateProgress() {
            if (!this.isFaceOkay) {
                this.progress = 0;
                return;
            }
            if (this.progress < 100) this.progress += 15;
        },
        syncTargetPosition() {
            const target = this.$refs.target?.getBoundingClientRect();
            const wrapper = this.$refs.target?.parentNode?.getBoundingClientRect();

            if (!target || !wrapper) return null;

            this.targetPosition = {
                width: target.width,
                height: target.height,
                x: target.x - wrapper.x,
                y: target.y - wrapper.y,
            };
        },
        stopDetecting() {
            clearInterval(this.detectionInterval)
        },
        reset() {
            clearInterval(this.detectionInterval)
            this.progress = 0
            this.done = false
            this.facePosition = null
        },
        capture(callback) {
            const canvas = document.createElement('canvas')
            canvas.width = this.video.videoWidth
            canvas.height = this.video.videoHeight

            const context = canvas.getContext('2d')

            if (this.mirror) {
                context.translate(canvas.width, 0);
                context.scale(-1, 1);
            }

            context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight)

            canvas.toBlob((blob) => {
                this.image = new File([blob], 'selfie.png', { type: blob.type })
                callback()
            }, 'image/png')
        },
        openWithStream({ stream, devices }) {
            this.devices = devices
            this.stream = stream
            this.opened = true
        },
    },
}
</script>

<style lang="scss" scoped>
.target {
    box-sizing: content-box;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: solid 2px rgb(255 255 255 / 55%);
    border-radius: 50%;
    outline: 100vw solid rgba(black, .85);
    transition: .4s;

    &--scanning {
        border-color: #337ab7;
        border-width: 10px;
        box-shadow: 0 0 20px 0px #337ab7;
    }
}

.guide{
    top: -5rem;
    align-self: flex-start;
    position: relative;
    text-align: center;
    background: white;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    display: inline-flex;
    font-weight: bold;
    min-width: 230px;
    justify-content: center;
    font-size: 2rem;
    box-shadow: 0 15px 15px -15px rgba(black, .5);

    @media (min-width: 768px) {
        top: -1rem;
    }
}
</style>

<style>
.fade-enter-active, .fade-leave-active {
  transition: .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

<i18n>
    {
        "cz": {
            "Face verification with selfie": "Ověření obličeje a selfie",
            "Click here to start the verification": "Kliknutím zde zahájíte ověřování",
            "Make sure your face is sufficiently visible, then point your face into the highlighted circle": "Ujistěte se, že je Váš obličej dostatečně viditelný, poté nasměrujte obličej do vyznačeného kruhu",
            "Move your face into the circle": "Přesuňte obličej do kruhu",
            "Move closer": "Přibližte se",
            "Scanning": "Scanování",
            "Default camera": "Výchozí kamera"
        },
        "en": {
            "Face verification with selfie": "Face verification with selfie",
            "Click here to start the verification": "Click here to start the verification",
            "Make sure your face is sufficiently visible, then point your face into the highlighted circle": "Make sure your face is sufficiently visible, then point your face into the highlighted circle",
            "Move your face into the circle": "Move your face into the circle",
            "Move closer": "Move closer",
            "Scanning": "Scanning",
            "Default camera": "Default camera"
        }
    }
</i18n>

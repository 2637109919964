<template>
    <el-dialog 
        v-if="contact && data"
        custom-class="lsDialog" 
        append-to-body
        :title="'Kolize dat - ' + contact.full_name" 
        :visible.sync="value"
    >
        <div style="word-break: break-word;">
            <el-alert :closable="false" type="info" title="Některé údaje v tomto dokumentu se liší od údajů na kontaktu. Vyberte, které údaje chcete použít." />
            <br>

            <el-row>
                <el-col :offset="6" :span="9">
                    <strong>Údaje v dokumentu</strong>
                </el-col>
                <el-col :span="9">
                    <strong>Údaje na kontaktu</strong>
                </el-col>
            </el-row>

            <CollisionRow v-bind="binder" prop="prefix" title="Titul před" />
            <CollisionRow v-bind="binder" prop="name" title="Jméno" />
            <CollisionRow v-bind="binder" prop="surname" title="Příjmení" />
            <CollisionRow v-bind="binder" prop="sufix" title="Titul za" />
            <CollisionRow v-bind="binder" prop="address_street" title="Ulice" />
            <CollisionRow v-bind="binder" prop="address_city" title="Město" />
            <CollisionRow v-bind="binder" prop="address_postal" title="PSČ" />
            <CollisionRow v-bind="binder" prop="address_country" title="Krajina" />
            <CollisionRow v-bind="binder" prop="birthdate" title="Datum narození" format="formatDate" />
            <CollisionRow v-bind="binder" prop="rc" title="Rodné číslo" />
            <CollisionRow v-bind="binder" prop="email" title="E-mail" />
            <CollisionRow v-bind="binder" prop="phone" title="Telefon" />
            <CollisionRow v-bind="binder" prop="bank_account" title="Bankovní účet" />
            <CollisionRow v-bind="binder" prop="ic" title="IČ" />
            <CollisionRow v-bind="binder" prop="dic" title="DIČ" />
            <CollisionRow v-bind="binder" prop="registration_country" title="Země původu" />
            <CollisionRow v-bind="binder" prop="nationality" title="Národnost" />
            <CollisionRow v-bind="binder" prop="gender" title="Pohlaví" />
            <CollisionRow v-bind="binder" prop="id_number" title="Číslo dokladu" />
            <CollisionRow v-bind="binder" prop="id_type" title="Typ dokladu" />
            <CollisionRow v-bind="binder" prop="id_expiration_date" title="Platnost dokladu" format="formatDate" />
            <CollisionRow v-bind="binder" prop="id_issued_date" title="Datum vydání dokladu" format="formatDate" />
            <CollisionRow v-bind="binder" prop="id_issuer_country" title="Země vydání dokladu" />
            <CollisionRow v-bind="binder" prop="id_issuer_authority" title="Doklad vydal" />

            <el-radio-group v-model="truthSwitch" style="width: 100%; margin-top: 20px">
                <el-row>
                    <el-col :offset="6" :span="9">
                        <el-radio border label="document">
                            Použít tyto údaje &nbsp;
                            <el-tooltip placement="top-start" content="Aktualizovat kontakt datami z dokumentu">
                                <i class="el-icon-question" />
                            </el-tooltip>
                        </el-radio>
                    </el-col>
                    <el-col :span="9">
                        <el-radio border label="contact">
                            Použít tyto údaje &nbsp;
                            <el-tooltip placement="top-start" content="Načíst data z kontaktu">
                                <i class="el-icon-question" />
                            </el-tooltip>
                        </el-radio>
                    </el-col>
                </el-row>
            </el-radio-group>
        </div>

        <span slot="footer">
            <el-button @click="save" :disabled="truthSwitch == null" type="primary">Uložit</el-button>
            <el-button @click="cancel">Zrušit</el-button>
        </span>
    </el-dialog>
</template>

<script>
import CollisionRow from './CollisionRow.vue';
export default {
  components: { CollisionRow },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        compareFunction: {
            type: Function,
            required: true
        },
        contact: {
            type: Object
        },
        data: {
            type: Object
        },
    },
    data() {
        return {
            truthSwitch: null,
        }
    },
    watch: {
        value() {
            this.truthSwitch = null;
        }
    },
    computed: {
        binder() {
            return {
                contact: this.contact,
                candidate: this.data,
                truthSwitch: this.truthSwitch,
                compareFunction: this.compareFunction,
            }
        },
    },
    methods: {
        save() {
            if(this.truthSwitch === "document") {
                this.$emit('update-contact');
            }
            else if(this.truthSwitch === "contact") {
                this.$emit('update-document');
            }
        },

        cancel() {
            this.$emit('input', false);
        }
    }
}
</script>

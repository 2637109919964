<template>
    <el-tooltip :content="message" :disabled="message == null">
        <el-button
            type="primary"
            :disabled="message != null || disabled"
            @click="send"
        >
            <i class="fa-solid fa-upload" /> {{ $t("ls.single-case.send") }}
        </el-button>
    </el-tooltip>
</template>

<script>
export default {
    inject: ["doc"],
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        message() {
            if (!this.doc.custom_data?.sc_title) {
                return this.$t("ls.single-case.not-from-sc")
            } else if (this.doc.locked) {
                return this.$t("ls.single-case.locked")
            } else {
                return null
            }
        }
    },

    methods: {
        /**
         * Send file to Single Case
         *  - wait for generating process to finish
         *  - set document as locked
         *
         *  - this will trigger webhook
         *  - webhook will send echo to SC
         *  - SC will send request to LS for files
         *  - LS will return array of files
         *  - SC will download generated docx (and optionally PDF)
         */
        async send() {
            try {
                //show loading
                this.$msgbox({
                    message:
                        `<div style="width: 300px; margin: 40px 0 0;">
                            <div class="el-loading-spinner" style="margin-top: 0; position: relative">
                                <div style="margin: 20px 0 10px;">
                                    <svg viewBox="25 25 50 50" class="circular">
                                        <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
                                    </svg>
                                </div>
                                <p class="el-loading-text" style="text-align: center;">
                                    ` + this.$t("ls.single-case.sending") + `
                                </p>
                            </div>
                        </div>`,
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    center: true,
                    showClose: false,
                    showConfirmButton: false,
                    dangerouslyUseHTMLString: true,
                });

                //force finish document
                this.doc.finished = true;

                //wait till generating processes are finished
                while (1) {
                    let ready = await this.$api.get(`proculus-api/documents/${this.doc.id}/generate/isReady`).then((x) => x.data);

                    if (ready) break;
                    await this.sleep(3000);
                }

                //lock document (and send to SC by webhook)
                await this.$api.post(`proculus-api/documents/${this.doc.id}/lock`);
                this.doc.locked = moment().unix();

                //wait for data propagation trough webhook
                await this.sleep(3000);

                //close existing popup
                this.$msgbox.close();
                await this.$nextTick();
                await this.$nextTick();
                await this.$nextTick();

                //show success message
                this.$msgbox({
                    message:
                    `
                        <div style="width: 300px; margin: 40px 0 0;">
                            <div class="el-loading-spinner" style="margin-top: 0; position: relative">
                                <div>
                                <div style="font-size: 48px; margin-bottom: 25px;">
                                    <i class="fa-solid fa-circle-check" style="color: #65bf98"></i>
                                </div>
                                <p class="el-loading-text" style="text-align: center; color: #65bf98">
                                    ` + this.$t("ls.single-case.success") + `<br><br>` + this.$t("ls.single-case.finish") + `
                                </p>
                                </div>
                            </div>
                        </div>`,
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    center: true,
                    showClose: false,
                    showConfirmButton: false,
                    dangerouslyUseHTMLString: true,
                });
            } catch (e) {
                //handle error
                await this.$nextTick();
                await this.$nextTick();
                this.$msgbox.close();
                this.$catch(e, this.$t("ls.single-case.error"));
            }
        },
    },
};
</script>

<style scoped>

</style>

<i18n>
  {
    "cz": {
      "ls": {
        "single-case": {
          "send": "Odeslat do SingleCase",
          "sending": "Váš dokument se odesílá.",
          "success": "Požadavek na přenos byl úspěšně přijat.",
          "finish": "Dokument bude v SingleCase dostupný zanedlouho. Tuto stránku můžete zavřít.",
          "not-from-sc": "Dokument nebyl vytvořen přes SingleCase, automatický přenos není podporován. Přeneste dokument ručně.",
          "locked": "Dokument již byl automaticky přenesen do SingleCase, opakovaný automatický přenos není podporován.",
          "error": "Při odesílání dokumentu došlo k chybě."
        }
      }
    },
    "en": {
      "ls": {
        "single-case": {
          "send": "Send to SingleCase",
          "sending": "Your document is being sent.",
          "success": "The transfer request was successfully received.",
          "finish": "The document will be available in SingleCase shortly. You can close this page.",
          "not-from-sc": "The document was not created via SingleCase, automatic transfer is not supported. Transfer the document manually.",
          "locked": "The document has already been automatically transferred to SingleCase, repeated automatic transfer is not supported.",
          "error": "An error occurred while sending the document."
        }
      }
    }
  }
</i18n>

<template>
    <div class="initiator" @click="attemptToStart" v-loading="loading">
        <template v-if="permission === true || permission === null">
            <template v-if="image === null">
                <i :class="icon + ' icon'"></i>
                <span>{{ title }}</span>
                <small><i>{{ subtitle }}</i></small>
            </template>

            <template v-else>
                <img :src="previewUrl" class="preview-image">
            </template>
        </template>

        <template v-if="permission === false">
            <div style="max-width: 400px;">
                <i class="fa-solid fa-video-slash icon"></i>
                <el-alert type="error" :closable="false" :title="$t('We need your permission to use the camera. Please grant this permission in your browser settings and click again.')" />
            </div>
        </template>
    </div>
</template>

<script type="text/javascript">
export default {
    props: {
        image: {
            type: File,
            required: false,
            default: null,
        },
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: null
        },
        side: {
            type: String,
            required: true,
            validator: (value) => {
                return ['user', 'environment'].includes(value)
            }
        },
        selectedDeviceId: {
            type: String,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            permission: null,
            loading: false,
        }
    },
    watch: {
        selectedDeviceId() {
            this.attemptToStart()
        },
    },
    computed: {
        previewUrl() {
            return this.image ? URL.createObjectURL(this.image) : null
        },
    },
    methods: {
        async attemptToStart(){
            this.loading = true

            /** Because of safari's need to call getUserMedia before enumerateDevices to get permission for available devices **/
            let [stream, streamError] = await this.generateStream({ video: true, audio: false })

            if (streamError) {
                this.permission = false
                this.loading = false
                return
            }
            //* ****************************************************** */

            const devices = await this.detectDevices()

            stream.getTracks().forEach(track => track.stop()) // remove existing tracks from initial call to fix bugged wrong phone video resolution when switching cameras

            const bestDeviceId = devices.find(device => device.label.includes('back') && device.label.includes('0'))?.deviceId;
            const activeDeviceId = this.selectedDeviceId ?? bestDeviceId ?? undefined;
            const facingMode = this.selectedDeviceId === null ? this.side : undefined;

            [stream, streamError] = await this.generateStream({
                audio: false,
                video: {
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                    facingMode: facingMode,
                    deviceId: activeDeviceId,
                },
            })

            if (streamError) {
                this.permission = false
                this.loading = false
                return
            }

            this.permission = true
            this.loading = false
            this.$emit('start', { stream: stream, devices: devices });
        },
        async detectDevices() {
            return (await navigator.mediaDevices.enumerateDevices()).filter((device) => device.kind === 'videoinput')
        },
        async generateStream(constraints) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints)

                return [stream, null]
            }
            catch(error) {
                return [null, error]
            }
        },
    }
}
</script>

<style scoped lang="scss">
.initiator {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 200px;
    color: #AAA;
    border: 2px dashed #d9d9d9;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;

    @media (min-width: 768px) {
        height: 240px;

        &:hover {
            border-color: rgba(0, 0, 0, .8);
            color: #222;
        }
    }

    &:active {
        border-color: rgba(0, 0, 0, .8);
        color: #222;
    }
}
.preview-image {
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: #222;
}

.icon {
    font-size: 50px;
    margin-bottom: 1rem
}

</style>
<i18n>
    {
        "cz": {
            "We need your permission to use the camera. Please grant this permission in your browser settings and click again.": "Potřebujeme Vaše svolení k použití fotoaparátu. Udělte toto svolení v nastavení prohlížeče a klikněte znovu."
        },
        "en": {
            "We need your permission to use the camera. Please grant this permission in your browser settings and click again.": "We need your permission to use the camera. Please grant this permission in your browser settings and click again."
        }
    }
</i18n>

<!-- inspired by https://github.com/iamstevendao/vue-tel-input -->
<template>
  <div class="input-with-select-validate">
    <el-form-item 
      :prop="cProp" 
      :label="label" 
      :help="help"
      :rules="[required ? rl.required : {}, { validator: validatePhone }]"
      ref="input-phone-item"
    >
      <el-input 
        class="input-with-select" 
        v-model="data" 
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        :disabled="disabled"
        :placeholder="placeholder"
      >
        <div slot="prepend">
          <div class="ls-prepend" v-if="$slots.prepend">
            <slot name="prepend"></slot>
          </div>
          <el-select
            class="country-select"
            value-key="iso2" 
            :filter-method="filter" 
            @input="countryInput"
            :value="country" 
            filterable 
            :disabled="disabled"
            :placeholder="$t('ls.inputPhone.country')"
          >
            <el-option
              v-for="c in filteredCountries"
              :key="c.iso2"
              :label="c.iso2"
              :value="c"
            >
              <span class="vti__selection">
                <span :class="['flag', 'vti__flag', c.iso2.toLowerCase()]" ></span>
              </span>
              <span>{{c.name}}</span>
              <span class="vti__dial">+{{c.dial}}</span>
            </el-option>
            <span slot="prefix" class="vti__selection">
              <span v-if="country" :class="['vti__flag', country.iso2.toLowerCase()]"></span>
            </span>
          </el-select>
        </div>
      </el-input>
    </el-form-item>
  </div>  
</template>

<style lang="scss" scoped>
  .country-select::v-deep {
    .el-input {
      width: 85px !important;
      transition: width 0.2s ease-in-out;
    }
    .el-input.is-focus {
      width: 120px !important;
    }
    .el-input__inner {
      padding-left: 35px;
    }

    .el-input__validateIcon {
      display: none;
    }
  }

  .input-with-select::v-deep {
    .el-input__prefix {
      left: 10px;
    }
    .ls-prepend {
      border-right: 1px solid #DCDFE6;
      display: inline-block;
      height: 100%;
      margin-right: 20px;
      padding-right: 10px;
      margin-left: -5px;
    }
  }

  .input-with-select-validate::v-deep {
    .el-form-item.is-error .input-with-select .el-input-group__prepend {
      border-color: #F56C6C;
    }
  }

  .flag {
    margin-right: 5px;
  }

  .vti__selection {
    float: left;
    display:flex;
    align-items:center;
    height: 100%;
  }

  .vti__dial {
    float: right; 
    opacity: 0.5;
    font-size: 0.8em;
    margin-left: 5px;
  }
</style>

<script>
  import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js';
  import allCountries from './all-countries';

  export default {
    props: {
      value: null,
      label: {
        type: String,
        default: function(){
          return this.$t('ls.inputPhone.label');
        }
      },
      help: String,
      placeholder: {
        type: String,
        default: function(){
          return this.$t('ls.inputPhone.placeholder');
        }
      }, 
      prop: String, //validator prop
      required: { //required?
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      //set default flag by IP address
      ip: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        filteredCountries: [...allCountries],
        data: this.value,
        country: null,
        countryDefault: null,
        focus: false,
        cProp: this.prop ?? this.$vnode.data.model.expression.replace("doc.", ""),
      };
    },

    watch: {
      value(val) { //data changed outside component
        this.data = val;
        this.handleInput();
      },
      data(val) {
        this.$emit('input', val);
      },
    },
    mounted() {
      if(this.data) {
        this.handleInput();
        //phone provided without dialcode - add CZ
        if(!this.country) {
          this.setDef(allCountries.find(x => x.dial == '420'));
          this.countryInput(this.country);
        }
      }
      else {
        //default is CZ
        this.setDef(allCountries.find(x => x.dial == '420'));
        if(this.ip)
          this.setByIp();
      }
    },

    methods: {
      setDef(country, clear = true) {
        this.country = country;
        this.countryDefault = Object.assign({}, country);
        if(clear) {
          this.$nextTick(() => {
            this.$refs["input-phone-item"]?.clearValidate?.();
          });
        }
      },

      setByIp() {
        //try guessing default by IP
        fetch('https://ip2c.org/s').then(response => response.text())
          .then(response => {
            const result = (response || '').toString();

            if (!result || result[0] !== '1')
              throw new Error('unable to fetch the country');

            //data provided while fetching - do not overwrite
            if(this.data)
              return;

            var iso2 = result.substr(2, 2);

            this.setDef(allCountries.find(x => x.iso2 == iso2));
          })
          .catch(e => {
            console.log(e);
          });
      },

      /**
       * click to empty input = prefill country code
       */
      handleFocus() {
        this.focus = true;
        if(!this.data && this.country){
          this.countryInput(this.country);
          this.$nextTick(() => {
            this.$refs["input-phone-item"]?.clearValidate?.();
          });
        }
      },
      
      /**
       * click from input with just country code = clear
       */
      handleBlur() {
        this.focus = false;
        if(this.country && ('+'+this.country.dial) == this.data)
          this.data = '';
      },
      
      handleInput() {
        if(typeof this.data !== "string")
          return;

        var parsed = parsePhoneNumberFromString(this.data);
        if(parsed) {
          this.setDef(allCountries.find(x => x.dial == parsed.countryCallingCode), false);
          this.filteredCountries = allCountries;
          this.data = parsed.formatInternational();
        }
        else {
          //hotfix to prefill country sooner
          if(this.data && this.data.length < 6) {
            if(this.data.includes('+421'))
              var dial = '421';
            else if(this.data.includes('+420'))
              var dial = '420';
            else
              var dial = this.data.replace('+','');

            this.setDef(allCountries.find(x => x.dial == dial), false);
          }
          else
            this.country = this.focus ? null : this.countryDefault;
        }
      },

      countryInput(val) {
        var number = this.data || '';
        
        //strip existing country code
        if(this.data && this.country?.dial)
          number = this.data.replace('+'+this.country.dial, '').trim();
        
        this.data = '+'+val.dial + number;
        this.country = val;
      },

      filter(filterValue) {
        this.filteredCountries = allCountries.filter(x => 
          this.$stringHelpers.removeDiacritics(x.name.toLowerCase()).includes(this.$stringHelpers.removeDiacritics(filterValue.toLowerCase()))
        );
      },

      validatePhone(_, value, callback) {
        if(!value)
          callback();
        else if(typeof value === "string" && isValidPhoneNumber(value))
          callback();
        else
          callback(new Error(this.$t('ls.inputPhone.validator')));
      },

      isRequired() {
        return this.required != undefined && this.required != false;
      },      
    }
  };
</script>

<style src="./sprite.css"></style>

<i18n>
  {
    "cz": {
      "ls": {
        "inputPhone": {
          "label": "Telefonní číslo",
          "placeholder": "Telefonní číslo",
          "country": "Krajina",
          "validator": "Nesprávný formát telefonního čísla"
        }
      }
    },
    "sk": {
      "ls": {
        "inputPhone": {
          "label": "Telefónne číslo",
          "placeholder": "Telefónne číslo",
          "country": "Krajina",
          "validator": "Nesprávny formát telefónneho čísla"
        }
      }
    },
    "en": {
      "ls": {
        "inputPhone": {
          "label": "Phone number",
          "placeholder": "Phone number",
          "country": "Country",
          "validator": "Incorrect phone number"
        }
      }
    },
    "pl": {
      "ls": {
        "inputPhone": {
          "label": "Numer telefonu",
          "placeholder": "Numer telefonu",
          "country": "Kraj",
          "validator": "Nieprawidłowy numer telefonu"
        }
      }
    },
    "hu": {
      "ls": {
        "inputPhone": {
          "label": "Telefonszám",
          "placeholder": "Telefonszám",
          "country": "Ország",
          "validator": "Helytelen telefonszám"
        }
      }
    }
  }
</i18n>

<template>
  <div>

    <el-form-item v-if="allowTown" label="Typ právnické osoby">
      <el-radio-group v-model="model.type">
        <el-radio label="company">Společnost</el-radio>
        <el-radio label="town">Veřejnoprávní subjekt</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-row>
      <el-col :sm="12" :md="16">
        <el-form-item :label="labelGen('Název subjektu')" :prop="cProp + '.name'" :rules="reqRuleGen()">
          <!-- INPUT from enum suggestions -->
          <ls-autocomplete2
            v-if="suggestions !== undefined"
            v-model="model.name"
            :disabled="model.loading"
            :suggestions="suggestions"
            storage="combined"
            button="prepend"
            placeholder="Název subjektu"
            :multi="{
              ic: {
                validators: [rl.ico],
                placeholder: 'IČ'
              }
            }"
            @select="suggestionSelected"
          >
            <el-button slot="append" :icon="loadingIcon" @click="searchByName(model)">
              Načíst z OR
            </el-button>
          </ls-autocomplete2>
          <!-- INPUT from contacts -->
          <ls-contacts
            v-else-if="contacts"
            :contact="model"
            subprop="contact"
            placeholder="Název subjektu"
            :type="['PO']"
            :columns="[
              'name',
              'type',
              'ic',
              'dic',
              'email',
              'phone',
              'bank_account',
            ]"
            :change-internal="x => {
              model.name = x.full_name;
              model.ic = x.ic;
              model.dic = x.dic;
              model.email = x.email;
              model.phone = x.phone;
              model.bank_account = x.bank_account;
            }"
            :change-external="x => {
              x.name = model.name;
              x.full_name = model.name;
              x.ic = model.ic;
              x.dic = model.dic;
              x.email = model.email;
              x.phone = model.phone;
              x.bank_account = model.bank_account;
            }"
          >
            <el-button slot="append" :icon="loadingIcon" @click="searchByName(model)">
              Načíst z OR
            </el-button>
          </ls-contacts>  
          <!-- basic input -->
          <el-input
            v-else
            v-model="model.name"
            placeholder="Název subjektu"
            :disabled="model.loading">
            <el-button slot="append" :icon="loadingIcon" @click="searchByName(model)">
              Načíst z OR
            </el-button>
          </el-input>
        </el-form-item>
        </el-col>
        <el-col :sm="12" :md="8">
        <el-form-item :label="labelGen('IČ')" :prop="cProp + '.ic'" :rules="reqRuleGen(rl.ico)">
          <el-input v-model="model.ic" placeholder="IČ" :disabled="model.loading">
            <el-button slot="append" :icon="loadingIcon" @click="searchByIco(model)">
              Načíst z OR
            </el-button>
          </el-input>
        </el-form-item>
      </el-col>  
    </el-row>
    
    <el-row>
      <el-col :sm="12" :md="16">
        <el-form-item :label="labelGen('Sídlo')" :prop="cProp + '.address'" :rules="reqRuleGen()">
          <el-input v-model="model.address" placeholder="Sídlo" :disabled="model.loading"/>
        </el-form-item>
      </el-col>  

      <el-col :sm="12" :md="8">
        <el-form-item :label="labelGen('DIČ')">
          <el-input v-model="model.dic" placeholder="DIČ" :disabled="model.loading"/>
        </el-form-item>
      </el-col>
    </el-row>

    <template v-if="isCompany">
      <el-row>
        <el-col :sm="12">
          <el-form-item :label="labelGen('Vedená u')" :prop="cProp + '.Soud'" :rules="reqRuleGen()">
            <el-input v-model="model.Soud" placeholder="Rejstříkový soud" :disabled="model.loading"/>
          </el-form-item>
        </el-col>
          
        <el-col :sm="12">
          <el-form-item :label="labelGen('Pod sp. zn.')" :prop="cProp + '.Oddil_vlozka'" :rules="reqRuleGen()">
            <el-input v-model="model.Oddil_vlozka" placeholder="Spisová značka" :disabled="model.loading"/>
          </el-form-item>
        </el-col>
      </el-row>

      <Statutory
        v-model="model"
        :prop="cProp"
        :labels="labels"
        :required="required"
        :statutar-phone="statutarPhone"
        :statutar-email="statutarEmail"
      />
    </template>

    <!-- company level contacts -->
    <template v-if="companyEmail !== undefined || companyPhone !== undefined || companyBank !== undefined">
      <span class="h4">
        Kontaktní údaje<span v-if="isCompany"> společnosti</span>
      </span>
      <el-row>
        <el-col :sm="12" :md="8" v-if="companyEmail !== undefined">
          <ls-input-email
            :label="labelGen('Kontaktní e-mail')"
            :prop="cProp + '.email'"
            :required="companyEmail == 'required'"
            v-model="model.email"
          />
        </el-col>
        <el-col :sm="12" :md="8" v-if="companyPhone !== undefined">
          <ls-input-phone
            :label="labelGen('Kontaktní telefon')"
            :prop="cProp + '.phone'"
            :required="companyPhone == 'required'"
            v-model="model.phone"
          />
        </el-col>
        <el-col :sm="12" :md="8" v-if="companyBank !== undefined">
          <el-form-item 
            :label="labelGen('Číslo účtu')" 
            :prop="cProp + '.bank_account'" 
            :rules="[companyBank == 'required' ? rl.required : {}, rl.cu]"
          >
            <el-input placeholder="Číslo účtu" v-model="model.bank_account"/>
          </el-form-item>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
  import Statutory from "./_statutar";

  export default {
    components: {
      Statutory
    },
    props: {
      value: {},
      prop: {
        type: String,
        default: undefined
      },
      labels: {
        type: Boolean,
        default: true
      },
      required: {
        type: Boolean,
        default: false
      },

      allowTown: {
        type: Boolean,
        default: false
      },
      osvc: undefined,

      companyEmail: undefined,
      companyPhone: undefined,
      companyBank: undefined,
      statutarPhone: undefined,
      statutarEmail: undefined,

      //same as ls-autocomplete2
      suggestions: {
        type: String
      }, 

      //contacts
      contacts: {
        type: Boolean,
        default: false
      },

    },
    watch: {
      model:{
        handler(val) {
          this.$emit('input', val);
        },
        deep: true
      },
      "model.loading":{
        handler(val) {
          if(!val) {
            if(this.custom)
              this.model.custom = Object.assign({}, this.custom);
            this.$emit('loaded', true);
          }
        }
      }
    },
    data() {
      return {
        model: this.value,
        cProp: this.prop ? this.prop : this.$vnode.data.model.expression.replace("doc.", ""),

        userSuggestions: { 
          or_contacts: [] 
        },
        custom: null

      };
    },
    created() {
      if(!this.model)
        this.model = this.$globalHelpers.getEmptyPO();
    },
    computed: {
      isRequired() {
        return this.required !== undefined && this.required != false;
      },
      loadingIcon() {
        return this.model.loading ? 'el-icon-loading' : 'el-icon-search';
      },
      isCompany() {
        return this.model.type != 'town' && this.osvc === undefined;
      },
    },
    methods: {
      labelGen(label) {
        return this.labels == false ? '' : label;
      },
      reqRuleGen(rule = {}) {
        return this.isRequired ? [this.rl.required, rule] : [rule];
      },
      suggestionSelected(val) {
        this.model.ic = val.ic; 
        if(val.custom)
          this.custom = val.custom;
        this.searchByIco(this.model);
      },

      searchByIco(model) {
        if(this.isCompany)
          return this.orSearchByIco(model);
        return this.orSearchByIcoBasic(model);
      },

      searchByName(model) {
        if(this.isCompany)
          return this.orSearchByName(model);
        return this.orSearchByNameBasic(model);
      }

    },

  };
</script>

<template>
    <div>
        <el-alert type="info" show-icon :closable="false"  style="margin-bottom: 2rem" :title="
            $t('If you wish to verify your identity on another device, retrieve the QR code below or have the SMS link sent to you')
        "/>
        <div style="display: flex; flex-direction: column; text-align: left; margin-bottom: 3rem">
            <div>
                <p>{{ $t('Scan the QR code') }}</p>
                <img :src="qr" v-loading="!qr" width="350" height="350" style="max-width: 350px; width: 350px; object-fit: contain; border: solid 1px #EEE; padding: .5rem; border-radius: .5rem">
            </div>

            <div style="margin-top: 2rem">
                <p>{{ $t('Send link via SMS') }}</p>
                <ls-sms :sms-route="'external-api/kyc-attempts/' + kycAttemptUuid + '/redirect-sms'" />
            </div>

            <div>
                <p>{{ $t("Or open the link in your device's browser") }}</p>
                <ls-copy :value="url" />
            </div>
        </div>

        <el-button @click="close">
            <i class="fa-solid fa-chevron-left" style="margin-right: 10px"></i>
            {{ $t('Back') }}
        </el-button>
    </div>
</template>

<script type="text/javascript">
import LsSms from '@/components/ls-sms.vue'

export default {
    components: {
        LsSms
    },
    props: {
        kycAttemptUuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            qr: null,
        }
    },
    computed: {
        url() {
            return this.hostname + '/public/kyc-attempts/' + this.kycAttemptUuid + '/redirect'
        }
    },
    methods: {
        async generateQr() {
            const response = await this.$api.post('/external-api/qr-code', { data: this.url })
            this.qr = response.data
        },
        close() {
            this.$emit('close')
        }
    },
    created() {
        this.generateQr()
    }
}
</script>

<i18n>
    {
        "cz": {
            "Scan the QR code": "Nascanujte QR kód",
            "Send link via SMS": "Odeslat odkaz přes SMS",
            "Or open the link in your device's browser": "Nebo otevřete odkaz v prohlížeči Vašeho zařízení"
        },
        "en": {
            "Scan the QR code": "Scan the QR code",
            "Send link via SMS": "Send link via SMS",
            "Or open the link in your device's browser": "Or open the link in your device's browser"
        }
    }
</i18n>

<template>
    <div>
        <camera-initiator
            :title="label"
            :subtitle="$t('Click here to open the camera')"
            :icon="icon"
            :side="'environment'"
            :image="image"
            :selected-device-id="selectedDeviceId"
            @start="open"
        />

        <camera-wrapper v-if="opened"
            :stream="stream"
            :mirror="mirror"
            @mirror="mirror = !mirror"
            @play="element => video = element">

            <template #overlay-guide="{ resume }">
                <i :class="icon" style="font-size: 40px; background: #dddddd; padding: 1.25rem 1.5rem; border-radius: 5px"></i>
                <span class="h4">{{ label }}</span>
                <el-alert type="info" slot="content" :closable="false">
                    <template slot="title">
                        <li>{{ $t('Place the document on a table top or other support') }}</li>
                        <li>{{ $t('Make sure there is enough light in the room') }}</li>
                        <li>{{ $t('Take a picture of the document so that it is inside the drawn rectangle') }}</li>
                        <li>{{ $t('Photograph the document ideally from above') }}</li>
                    </template>
                </el-alert>
                <el-button @click="resume" style="margin-top: 1rem">{{ $t('Lets go') }}</el-button>
            </template>

            <template #video-synced-container>
                <div ref="target" class="target" :style="'outline-color:' + (!previewUrl ? '#000000ab' : '#000000e3')">
                    <i v-if="!previewUrl" :class="icon + ' icon'"></i>
                </div>
                <img v-if="previewUrl" class="captured-image" :src="previewUrl">
            </template>

            <template #header-controls="{ pause, clearVideo, mirror }">
                <el-button type="text" @click="close" style="margin-right: auto">
                    <span class="header-text">
                        <i class="fa-solid fa-chevron-left" style="margin-right: 10px; font-size: 26px"></i>
                        <span>{{ $t('Back') }}</span>
                    </span>
                </el-button>

                <select v-model="selectedDeviceId" @change="retry(); clearVideo()">
                    <option :value="null">{{ $t('Default camera') }}</option>
                    <option v-for="device in devices"
                        :key="device.deviceId"
                        :value="device.deviceId">
                        {{ device.label }}
                    </option>
                </select>

                <el-button @click="mirror"
                    style="margin-left: auto; margin-right: 10px; transform: rotate(90deg);"
                    class="icon-button"
                    type="text"
                    size="medium"
                    icon="fas fa-arrow-down-up-across-line"
                />

                <el-button @click="pause"
                    class="icon-button"
                    type="text"
                    size="medium"
                    icon="far fa-question-circle"
                />
            </template>

            <template #default>
                <div class="controls">
                    <el-button v-if="!previewUrl"
                        class="button"
                        type="text"
                        @click="capture"
                    >
                        <i class="fa-solid fa-camera"></i>
                        <p>{{ $t('Take a picture of document') }}</p>
                    </el-button>
                    <div v-else style="display: grid; grid-template-columns: 1fr 15px 1fr; grid-template-rows: 1fr; width: 100%; height: 100%">
                        <el-button @click="retry" class="button button--red" type="text">
                            <i class="fa-solid fa-rotate-right"></i>
                            <p>{{ $t('Try again') }}</p>
                        </el-button>
                        <div style="height: 100%; width: 1px; background-color: #333; margin: 0 auto"></div>
                        <el-button @click="submit" class="button button--green" type="text">
                            <i class="fa-solid fa-check"></i>
                            <p>{{ $t('Yes, use this photo') }}</p>
                        </el-button>
                    </div>
                </div>
            </template>
        </camera-wrapper>
    </div>
</template>

<script type="text/javascript">
import CameraWrapper from '@/components/checks/kyc/camera/camera-wrapper.vue'
import CameraInitiator from '@/components/checks/kyc/camera/camera-initiator.vue'

export default {
    components: {
        CameraWrapper,
        CameraInitiator,
    },
    props: {
        label: {
            type: String,
            required: true,
            default: null,
        },
        icon: {
            type: String,
            required: true,
            default: null
        },
    },
    data() {
        return {
            stream: null,
            video: null,
            preview: null,
            image: null,

            devices: null,
            selectedDeviceId: null,

            opened: false,
            mirror: false,
        }
    },
    computed: {
        previewUrl() {
            return this.preview ? URL.createObjectURL(this.preview) : null
        },
    },
    methods: {
        open({ stream, devices }) {
            this.devices = devices
            this.stream = stream
            this.opened = true
        },
        close() {
            this.opened = false
            this.preview = null
        },
        retry() {
            this.preview = null
        },
        submit() {
            this.image = this.preview
            this.$emit('submit', this.image)
            this.preview = null
            this.opened = false
        },
        capture() {
            const canvas = document.createElement('canvas')
            canvas.width = this.video.videoWidth
            canvas.height = this.video.videoHeight

            const context = canvas.getContext('2d')

            if (this.mirror) {
                context.translate(canvas.width, 0);
                context.scale(-1, 1);
            }

            context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight)

            canvas.toBlob((blob) => {
                this.preview = new File([blob], 'selfie.png', { type: blob.type })
            }, 'image/png')
        },
    },
}
</script>

<style lang="scss" scoped>

.controls {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #eee;
    background-color: #111;

    .button {
        width: 100%;
        height: 100%;
        color: white;
        background: #111;
        transition: .2s;
        outline: none;
        border-radius: 0;

        &:hover {
            background: #222;
        }

        &--green {
            color: #67c23a;
        }

        &--red {
            color: #ee5858;
        }

        p {
            font-weight: bold;
        }

        i {
            font-size: 30px;
            padding: 8px;

            @media (min-width: 768px) {
                font-size: 35px;
                padding: 10px;
            }
        }
    }
}

.target {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    width: 100%;
    left: 50%;
    top: 50%;
    aspect-ratio: 171/108;
    transform: translate(-50%, -50%);
    border: dashed 2px rgb(255 255 255 / 55%);
    border-radius: 1rem;
    outline: 100vw solid rgba(black, .6);
    transition: .4s;
    z-index: 1;

    @media (min-width: 768px) {
        max-width: 60%;
    }

    .icon {
        font-size: 65px;
        color: white;
        opacity: 0.75;
    }
}

.captured-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 2rem black;
    object-fit: contain;
    z-index: 0;
}

</style>

<i18n>
    {
        "cz": {
            "Front side of the document": "Přední strana dokladu",
            "Back side of the document": "Zadní strana dokladu",
            "Click here to open the camera": "Klikněte zde pro otevření fotoaparátu",
            "Lets go": "Pojďme na to",
            "Place the document on a table top or other support": "Umístěte doklad na desku stolu nebo jinou podložku",
            "Make sure there is enough light in the room": "Ujistěte se, že je v místnosti dostatek světla",
            "Take a picture of the document so that it is inside the drawn rectangle": "Vyfotťe doklad tak, aby byl uvnitř nakresleného obdelníku",
            "Photograph the document ideally from above": "Doklad foťte ideálně ze shora",
            "Back": "Zpět",
            "Take a picture of document": "Vyfotit doklad",
            "Try again": "Zkusit znovu",
            "Yes, use this photo": "Ano, použít tuhle fotku"
        },
        "en": {
            "Front side of the document": "Front side of the document",
            "Back side of the document": "Back side of the document",
            "Click here to open the camera": "Click here to open the camera",
            "Lets go": "Lets go",
            "Place the document on a table top or other support": "Place the document on a table top or other support",
            "Make sure there is enough light in the room": "Make sure there is enough light in the room",
            "Take a picture of the document so that it is inside the drawn rectangle": "Take a picture of the document so that it is inside the drawn rectangle",
            "Photograph the document ideally from above": "Photograph the document ideally from above",
            "Back": "Back",
            "Take a picture of document": "Take a picture of document",
            "Try again": "Try again",
            "Yes, use this photo": "Yes, use this photo"
        }
    }
</i18n>

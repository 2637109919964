<template>
    <div class="camera-wrapper" :style="'height:' + innerHeight + 'px'">
        <video ref="video" :class="{'video': true, 'mirror': mirror}" playsinline :src-object.prop="stream" @canplay="updateSizes(); playVideo()" />

        <div class="video-synced-container" :style="'width: ' + videoWidth + 'px; height: ' + videoHeight + 'px'" ref="container">
            <slot name="video-synced-container" v-if="!paused" :width="videoWidth" :height="videoHeight" />
        </div>

        <div class="header-controls">
            <slot name="header-controls" :close="close" :pause="pause" :resume="resume" :clear-video="clearVideo" :mirror="toggleMirror" />
        </div>

        <div class="overlay-guide" v-if="paused">
            <el-card>
                <div class="card-box">
                    <slot name="overlay-guide" :resume="resume"></slot>
                </div>
            </el-card>
        </div>

        <slot name="default" :close="close" :pause="pause" :resume="resume" />
    </div>
</template>

<script type="text/javascript">
export default {
    props: {
        stream: {
            type: MediaStream,
            required: false,
            default: null,
        },
        mirror: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            innerHeight: window.innerHeight,
            videoWidth: 0,
            videoHeight: 0,
            paused: true,
        }
    },
    methods: {
        playVideo() {
            this.$refs.video.play()
            this.$emit('play', this.$refs.video)
        },
        clearVideo() {
            this.$refs.video.srcObject.getVideoTracks().forEach(track => {
                track.stop();
                this.$refs.video.srcObject.removeTrack(track)
            })
        },
        updateSizes() {
            this.innerHeight = window.innerHeight
            this.videoWidth = this.$refs.video.clientWidth
            this.videoHeight = this.$refs.video.clientHeight
        },
        toggleMirror() {
            this.$emit('mirror');
        },
        pause() {
            this.paused = true;
            this.$emit('pause');
        },
        resume() {
            this.$emit('resume');
            this.paused = false;
        },
        close() {
            this.$emit('close');
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateSizes)
        document.body.classList.add('modal-open')
    },
    beforeDestroy() {
        this.clearVideo()
        window.removeEventListener('resize', this.updateSizes)
        document.body.classList.remove('modal-open')
    },
}
</script>

<style lang="scss" scoped>
.camera-wrapper {
    position: fixed;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
}

.video {
    max-width: 1100px;
    margin: 0 auto;
    width: 100%;
    object-fit: contain;
}

.video-synced-container {
    position: absolute;
    display: flex;
    margin: 0 auto;
    max-width: 100%;
}

.overlay-guide {
    background-color: rgba(0,0,0,.8);
    transition: opacity .5s;
    padding: 2rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    .card-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 450px
    }
}

.header-controls {
    z-index: 2;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    padding: 0 1rem;
}

.icon-button {
    height: 60px;
    font-size: 25px;
}

.mirror {
    transform: scaleX(-1);
}

select {
    background: black;
    padding: 5px;
    border: solid 2px rgba(white, .2);
    color: white;
    border-radius: 6px;
    min-width: 100px;
    max-width: 140px;
    margin: 0 5px;

    @media (min-width: 600px) {
        max-width: 250px;
    }
}

.header-text {
   color: white;
   display: flex;
   align-items: center;
}
</style>

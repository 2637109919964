/*
 * Javascript for external loading client forms
 */

require('./common');

require("./mixinsExternal")

//allows to externally mount components
window.httpVueLoader = require('http-vue-loader');

//temporary backward compatibility
window.axios = Vue.prototype.$api;

//load LS component
// requires setting token trough window.httpVueLoader.token
window.httpVueLoader.httpRequest = function(url) {
  return Vue.prototype.$http.get(url, {
    headers: { 'Authentication': window.httpVueLoader.token }
  }).then(function(res) {
    window.v.root.loaded = true;
    return res.data;
  }).catch(function(e) {
    window.v.root.error = true;
    Vue.prototype.$sentry.captureException(e);
    return Promise.reject(e.status);
  });
}

/**
 * Default HTML for external injection
 */
var injectComponent = `<legal-systems ref="ls" :root="root"></legal-systems>`;
var inject = `
<div class="proculusForm">
  <div class="ls-spinner" v-if="!root.mounted && !root.error">
    <div class="ls-cube ls-cube1"></div><div class="ls-cube ls-cube2"></div>
    <div class="ls-cube ls-cube3"></div><div class="ls-cube ls-cube4"></div>
    <div class="ls-cube ls-cube5"></div><div class="ls-cube ls-cube6"></div>
    <div class="ls-cube ls-cube7"></div><div class="ls-cube ls-cube8"></div>
    <div class="ls-cube ls-cube9"></div><div class="ls-cube ls-cube10"></div>
    <div class="ls-cube ls-cube11"></div><div class="ls-cube ls-cube12"></div>
    <div class="ls-cube ls-cube13"></div><div class="ls-cube ls-cube14"></div>
    <div class="ls-cube ls-cube15"></div><div class="ls-cube ls-cube16"></div>
  </div>

  <!-- error message -->
  <div v-cloak v-if="root.error">
    <center>Načtení formuláře selhalo. Náš tým byl informován.</center>
  </div>

  <!-- external form component -->
  <div style="width: 95%; margin: 0 auto;">
    ${injectComponent}
  </div>
</div>
`;

/**
 * Helper for super-easy external form integration
 */
window.LegalSystems = {
  //client ID
  id: null,

  //Mounted Vue.js instance (ViewModel)
  vm: null,

  //loaded forms
  forms: [],

  /**
   * Initialise LegaL Systems external mounting
   *
   * @param {*} options:
   *  url: proculus origin URL (can be switched to fe. staging) [default proculus]
   *  id: client url-name identifier [*required]
   *  token: client token [*required]
   *  api: USER api token (for auth operations) [default null]
   */
  init: function(options) {
    try {
      //origin URL
      window.proculus = options.url ?? "https://proculus.legalsystems.cz";

      //Create LS component
      this.id = options.id;

      //inject CSS
      let link = document.createElement("link");
      link.href = window.proculus+"/css/external.css";
      link.type = "text/css";
      link.rel = "stylesheet";
      document.getElementsByTagName("head")[0].appendChild(link);

      let linkTw = document.createElement("link");
      linkTw.href = window.proculus+"/css/tailwind-external.css";
      linkTw.type = "text/css";
      linkTw.rel = "stylesheet";
      document.getElementsByTagName("head")[0].appendChild(linkTw);

      //axios defaults
      Vue.prototype.$api.defaults.baseURL = window.proculus;
      Vue.prototype.$api.defaults.headers.common = {
        'Authentication': options.token,
        ...(options.auth ? { 'Authorization': 'Bearer ' + options.auth } : {}),
      };
    }
    catch(e) {
      console.log(e);
      throw new Error("Legal Systems init failed.");
    }

    //register component
    window.httpVueLoader.httpRequest = url => {
      let component = this.forms.find(x => x.name === url);

      if(!component)
        throw "Form "+url+" not found.";

      return new Promise(async (resolve, reject) => {
        component.data.then(x => {
          resolve(x);
        }).catch(x => {
          if(this.vm)
            this.vm.root.error = true;
          reject(x);
        });
      });
    }
  },


  /**
   * Preload components (useful for popup solutions - no need to wait while mounting)
   *
   * @param form:
   *  injected template/form url-name identifier [*required]
   *  String / Array
   *
   */
  load: async function(form) {
    if(typeof form === "string")
      form = [form];

    for(let f of form) {
      if(this.forms.find(x => x.name === f+'.vue'))
        continue;

      let url = '/external-api/'+this.id+'/vue/'+f+'/ls.vue';
      this.forms.push({
        name: f+'.vue',
        data: Vue.prototype.$api.get(url).then(res => res.data)
      });
    }

    return new Promise(async (resolve, reject) => {
      for(let f of this.forms) {
          try { await f.data; }
          catch(e) {
            console.log(e);
            Vue.prototype.$sentry.captureException(`Form ${f} could not be loaded.`);
            reject();
          }
        }
        resolve();
    });
  },

  /**
   * Initialise Vue and mount component to el
   *
   * @param form:
   *  injected template/form url-name identifier [*required]
   *
   * @param {*} data:
   *  object of data passed to Vue component
   *
   * @param {*} options:
   *  el: HTML element ID to mount LS to
   *  inject: inject HTML to <div id="legal-systems">? [default true]
   *  silent: should inject only component? [default false if el found and mounting there, otherwise true and mounting to body end]
   */
  mount: async function(form, data = null, options = {}) {
    //clear any previous running instances
    if(this.vm)
      this.destroy();

    //load component (if not already)
    this.load(form).catch(_ => {});

    try {
      //HTML element ID for injection
      let el = options.el ?? "legal-systems";

      //if HTML element not found, create temporary and append to body
      if(!document.getElementById(el)) {
        let g = document.createElement('div');
        g.setAttribute("id", el);

        //set temporary flag for removal when destroying
        g.setAttribute("data-tmp", 1);
        document.body.appendChild(g);

        //set silent mode as defaoult (for popups)
        if(options.silent === undefined)
          options.silent = true;
      }

      //inject HTML
      if(options.inject !== false) {
        if(options.silent === true)
          document.getElementById(el).innerHTML = injectComponent;
        else
          document.getElementById(el).innerHTML = inject;
      }

      //load component async
      Vue.component('legal-systems', httpVueLoader(form+'.vue'));

      //init Vue JS
      this.vm = window.v = new Vue({
        el: '#'+el,
        data() {
          return {
            //state variables (side effect)
            root: {
              loaded: false,
              mounted: false,
              error: false,
              data: data
            }
          }
        }
      });
    }
    catch(e) {
      console.log(e);
      throw new Error(`Form ${f} could not be mounted.`);
    }
  },

  /**
   * Destroy currently mounted Vue Instance
   */
  destroy: function() {
    try {
      //remove component
      delete Vue.options.components['legal-systems'];

      let el = this.vm.$el;
      this.vm.$destroy();
      this.vm = window.v = null;
      document.getElementById(el.id).innerHTML = null;

      //clear temporary HTML mounting point
      if(el.getAttribute('data-tmp'))
        document.getElementById(el.id).remove();
    }
    catch(e) {
      console.log(e);
      throw new Error(`Error destroying Legal Systems instance.`);
    }
  },
}

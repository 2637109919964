<template>
    <div>
        <el-alert type="info" :closable="false" show-icon>
            <div slot="title" style="word-break: break-word; text-align: justify;">
                Pro dokončení ověření totožnosti je nutno provést mikroplatbu v částce 1Kč z účtu ověřující osoby na údaje uvedené níže. Nezapomeňte vyplnit variabilní symbol a zprávu / poznámku platby. Ověřujete-li za právnickou osobu, je třeba, aby platba byla provedena z firemního účtu.
            </div>
        </el-alert>

        <el-descriptions title="Údaje k platbě:" direction="horizontal" :column="1" border style="margin-top: 20px">
            <el-descriptions-item label="Číslo účtu">{{ account }}</el-descriptions-item>
            <el-descriptions-item label="Variabilní symbol">{{ symbol }}</el-descriptions-item>
            <el-descriptions-item label="Zpráva / poznámka platby">{{ comment }}</el-descriptions-item>
            <el-descriptions-item label="Částka"> 1 Kč </el-descriptions-item>
            <el-descriptions-item label="QR kód platby">
                <img :src="qr" v-loading="!qr" width="150">
            </el-descriptions-item>
        </el-descriptions>

        <br>

        <div style="display: inline-flex; flex-wrap:wrap; margin-top: 20px; align-items: center; width: 100%;">
            <el-checkbox v-model="confirmed" border>Platba provedena</el-checkbox>
            <i style="margin-left: 10px">* Povinné pole</i>

            <el-button @click="$emit('completed')" :disabled="!confirmed" type="primary" style="margin-left: auto">
                Pokračovat
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        account: {
            type: String,
            required: true
        },
        symbol: {
            type: String,
            required: false
        },
        comment: {
            type: String,
            required: false
        }
    },
    data(){
        return {
            qr: null,
            confirmed: false
        }
    },
    created(){
        this.generateQr();
    },
    methods: {
        generateQr(){
            this.$api.post("/external-api/qr-payment/", {
                bankAccount: this.account,
                amount: "1",
                variableSymbol: this.symbol,
                comment: this.comment,
            }).then(res => {
                this.qr = res.data;
            }).catch(e => {
                this.$catch(e, 'Nastala chyba při sestavování QR kódu');
            });
        }
    }
}

</script>

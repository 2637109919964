<template>
  <div class="ls-select">
    <!-- 
      el-select does not support prepend/append slots, 
      so we need to fix it manually by CSS classes
    -->
    <div :class="'el-input el-input-group ' + ($slots.append ? 'el-input-group--append' : '') + ($slots.prepend ? 'el-input-group--prepend' : '')">
      <el-select 
      :key="'lssel'+key"
      v-model="model"
      :disabled="disabled"
      clearable
      filterable
      :allow-create="allowCreate"
      :multiple="multiple"
      :placeholder="placeholder"
      :value-key="valueKey"
      @change="change">
        <el-option v-for="(x, i) in dataMerged" :key="i" :label="x[valueKey]" :value="simpleProp ? x[simpleProp] : x"></el-option>
      </el-select>
      <div v-if="$slots.prepend" class="el-input-group__prepend">
        <slot name="prepend"></slot>
      </div>
      <div v-if="$slots.append" class="el-input-group__append">
        <slot name="append"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .ls-select::v-deep {
    .el-input-group--append .el-select .el-input.is-focus .el-input__inner {
      border-color: #0096B3;
    }
  }
</style>

<script>
  export default {
    name: 'LsSelect',    
    props: {
      value: null,
      valueKey: {
        type: String,
        default: 'value'
      },
      placeholder: null,
      disabled: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      //variable name (eg. bank, or orderId)
      suggestions: {
        type: String,
        required: true
      }, 
      allowCreate: {
        type: Boolean,
        default: true
      },
      //client, user, role or combined
      storage: {
        type: String,
        default: "user"
      },
      //handle multiple input, eg:
      //{ email: { validators: [rl.required, rl.email], placeholder: 'E-mail' } }
      multi: {
        type: Object,
        default: null
      },
      //get only certain property of object
      simpleProp: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        key: 0,
        loc: null,
        data: {
          user: [],
          role: [],
          client: []
        },
        model: this.value,
        loading: true,
        multiObj: {}
      }
    },
    computed: {
      dataMerged() {
        if(this.storage == 'combined')
          var out = this.data["user"].concat(this.data["role"], this.data["client"]);
        else
          var out = this.data[this.storage];

        out = [].concat(out);

        if(!this.simpleProp) {
          //add current model even if it is not in options anymore
          if(this.model) {
            if(this.multiple) {
              out = out.concat(this.model);
            }
            else {
              out.push(this.model);
            }

            out = _.uniqBy(out, this.valueKey);
          }
        }

        return out;
      }
    },
    watch: {
      value(val) {
        this.model = val;
        this.key++;
      },
    },    
    created: async function() {
      //handle multiple input
      if(this.multi) {
        Object.keys(this.multi).forEach(el => {
          this.multiObj[el] = '';
        });
      }

      this.loading = true;
      var s1, s2;

      this.loc = this.storage;
      if(this.storage == 'combined')
        this.loc = 'user';

      if(this.storage == 'user' || this.storage == 'combined')
        s1 = this.getData("user");
      if((this.storage == "role" || this.storage == 'combined') && !this.$context.role?.superuser)
        s2 = this.getData("role");
      if((this.storage == "client" || this.storage == 'combined') && !this.$context.role?.superuser)
        s2 = this.getData("client");

      await s1;
      await s2;
      this.loading = false;
    },
    methods: {
      change(val) {
        var fn = (x) => {
          if(!this.simpleProp && typeof x === "string") {
            let empty = {};
            empty[this.valueKey] = '';

            var obj = _.merge(empty, this.multiObj);
            obj[this.valueKey] = x;
            return obj;
          }
          else
            return x;
        }

        if(this.multiple)
          var out = val.map(x => fn(x));
        else
          var out = fn(val);

        this.$nextTick(()=>{
          this.$emit('input', out);
          this.$emit('change', out);
        });
      },

      getData(loc) {
        return this.$api.get('/proculus-api/getCustomData/'+loc+'/' + this.suggestions)
        .then(res => {
          if(res.data || res.data === []) {
            this.data[loc] = this.data[loc].concat(res.data);
          }
        })
        .catch(this.justFail);
      },

      justFail(error) {
        this.$sentry.captureException(error);
      }
    }
  }
</script>

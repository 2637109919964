<template>
  <div v-if="data">
    <el-alert v-if="data.status == 'ACCEPTED'" center type="success" show-icon>
      <div slot="title" class="kycAlert">
        {{ $t('kycOverview.success') }} ({{data.data.name || ''}} {{data.data.birthdate || ''}}).
      </div>
    </el-alert>

    <el-alert v-else center type="info" show-icon>
      <div slot="title" class="kycAlert">
        {{ $t('kycOverview.fail') }} {{data.reason}}
      </div>
    </el-alert>

    <br>
  </div>
</template>

<style scoped lang="scss">
  .kycAlert {
    word-break: break-word;
    text-align: justify;
  }
</style>

<script>
  export default {
    props: {
      data: null
    },
    data() {
      return {

      };
    },
  };
</script>

<i18n>
  {
    "cz": {
      "kycOverview": {
        "success": "Identita úspěšne ověřena",
        "fail": "Vaši identitu se nepodařilo ověřit."
      }
    },
    "en": {
      "kycOverview": {
        "success": "Identity successfully verified",
        "fail": "Your identity could not be verified."
      }
    }
  }
</i18n>

<!-- <ls-docx-viewer :doc="doc"></ls-docx-viewer> uses default file -->
<!-- <ls-docx-viewer :doc="doc" :file="Výpověď.docx"></ls-docx-viewer> uses "file" -->
<!-- <ls-docx-viewer :doc="doc" url="https://calibre-ebook.com/downloads/demos/demo.docx"></ls-docx-viewer> load external URL -->
<template>
  <div v-loading="linkLoading" :element-loading-text="$t('viewer.loading')">

    <div v-if="link" :style="'position:relative; '+'width: 100%; height: '+height+'; min-height: initial'" ref="viewer" />

    <div v-else-if="error">
    <el-alert 
      :title="$t('viewer.error')"
      :description="$t('viewer.errorDesc')"
      type="error"
      :closable="false"
      show-icon>
    </el-alert>
  	</div>
    <div v-else :style="'width: 100%; height: '+height+'; min-height: initial'"></div>
  </div>
</template>

<script>
  import PDFObject from 'pdfobject';
  export default {
    inject: {
      template: {
        default: null
      },
      savingProcess: {
        default: null
      }
    },
    props: {
      doc: Object,
      data: String,
      file: {
      	type: String,
      	default: null
      },
      url: {
      	type: String | Promise,
      	default: null
      },
      width: {
      	type: String,
      	default: "100%"
      },
      height: {
        type: String,
        default: "50vh"
      },
      keep: {
        type: Boolean,
        default: false
      },
      attachments: {
        type: Array,
        default: null
      }
    },
    data () {
      return {
      	link: null,
      	linkLoading: true,
      	error: false,
      }
    },

    watch: {
      link: async function() {
        if(this.link) {
          await this.$nextTick();
          PDFObject.embed(this.link, this.$refs.viewer);
        }
      }
    },

    mounted: async function() {
    	if(this.data) { //base64
        this.link = "data:application/pdf;base64,"+this.data;
        this.linkLoading = false;
      }
      else if(this.url !== null) {//just open external docx - no "doc" data required
  			this.link = await this.url;
  			this.linkLoading = false;
    	}
    	else {//generate docx, temporarily save it and preview it
    		if(this.doc)
		    	this.getDownloadPdfUrl(this.file, this.keep, 'preview.pdf', this.attachments).then(async (value) => {
  					this.link = await value;
  					this.linkLoading = false;
  				}).catch(res => {
            this.error = true;
            this.linkLoading = false;
            console.log(res);
            this.$sentry.captureException(res);
          });
        else {//no "doc" data specified!
        	this.error = true;
				  this.linkLoading = false;
        	throw new Error('Specify :doc="doc" or url for ls-viewer component!');
        }
    	}
  	},
    methods: {


    }
  }
</script>

<i18n>
  {
    "cz": {
      "viewer": {
        "loading": "Připravuji náhled dokumentu...",
        "error": "Chyba!",
        "errorDesc": "Náhled dokumentu se nepodařilo zobrazit."
      }
    },
    "sk": {
      "viewer": {
        "loading": "Pripravujem náhľad dokumentu...",
        "error": "Chyba!",
        "errorDesc": "Náhľad dokumentu sa nepodarilo zobraziť."
      }
    },
    "en": {
      "viewer": {
        "loading": "Preparing document preview ...",
        "error": "Error!",
        "errorDesc": "The document preview could not be displayed."
      } 
    },
    "pl": {
      "viewer": {
        "loading": "Przygotowanie podglądu dokumentu ...",
        "error": "Błąd!",
        "errorDesc": "Nie można wyświetlić podglądu dokumentu."
      } 
    },
    "hu": {
      "viewer": {
        "loading": "A dokumentum előnézete ...",
        "error": "Hiba!",
        "errorDesc": "A dokumentum előnézete nem jeleníthető meg."
      } 
    }
  } 
</i18n>

<template>
  <el-select
    v-cancel-read-only
    :loading="loading"
    :placeholder="placeholder"
    filterable
    clearable
    v-bind:value="data"
    v-on:input="output"
    :disabled="disabled"
  >
    <template v-if="grouping">
      <el-option-group
        :label="$t('popular')">
        <el-option
          v-for="(item, key) in options.filter(x=>x.a3 == 'CZE' || x.a3 == 'SVK')"
          :key="key"
          :label="$i18n.locale == 'en' ? item.nameEN : item.name"
          :value="simple ? item.name : JSON.stringify(item)">
        </el-option>
      </el-option-group>
      <el-option-group
        :label="$t('other')">
        <el-option
          v-for="(item, key) in options.filter(x=>x.a3 != 'CZE' && x.a3 != 'SVK')"
          :key="key"
          :label="$i18n.locale == 'en' ? item.nameEN : item.name"
          :value="simple ? item.name : JSON.stringify(item)">
        </el-option>
      </el-option-group>      
    </template>
    <template v-else>
      <el-option
        v-for="(item, key) in options"
        :key="key"
        :label="$i18n.locale == 'en' ? item.nameEN : item.name"
        :value="simple ? item.name : JSON.stringify(item)">
      </el-option>
    </template>
  </el-select>
</template>

<script>
  export default {
    name: 'LsCountries',    
    props: [
      'placeholder',
      'value',
      'autofocus',
      'limit',
      'simple',
      'disabled',
      'forceMatch'
    ],
    data() {
      return {
        loading: false,
        options: [],
        data: null,
        grouping: true
      };
    },
    watch: {
      value(val) { //data changed outside component
        if(!this.simple)
          this.data = this.value ? JSON.stringify(this.value) : null;
        else
          this.data = this.value;
      },
    },
    methods: {
      handleSelect(item) {
        this.$emit('input', item);
      },

      output(item) {
        this.data = item;
        if(item)
          this.$emit('input', this.simple ? item : JSON.parse(item));
        else
          this.$emit('input', null);
      }
    },
    created() {
      if(this.value) {
        if(!this.simple) {
          this.options.push(this.value);
          this.data = JSON.stringify(this.value);
        }
        else {
          this.data = this.value;
        }
      }

      //if only SK & CZ restriction, don't fetch JSON
      if(Array.isArray(this.limit) && _.xor(this.limit, ["CZE", "SVK"]).length === 0) {
        this.grouping = false;
        this.options = [
          {"a2":"CZ","a3":"CZE","name":"Česko","nameEN":"Czechia"},
          {"a2":"SK","a3":"SVK","name":"Slovensko","nameEN":"Slovakia"}
        ];
      }

      //fetch JSON with all countries
      else {
        this.loading = true;
        this.$api.get('/external-api/countries')
          .then(res => {
            this.loading = false;
            this.options = res.data;

            //apply restrictions
            if(Array.isArray(this.limit))
              this.options = this.options.filter( x => this.limit.includes(x.a3) );

            //loaded value must be in countries list, otherwise discard
            if(this.forceMatch) {
              if(this.simple) {
                if(!this.options.find(x => x.name == this.data))
                  this.$emit('input', null);
              }
              else {
                let d = JSON.parse(this.data);
                if(!this.options.find(x => x.a3 == d.a3))
                  this.$emit('input', null);
              }
            }            
          });
      }
    }
  };
</script>

<i18n>
  {
    "cz": {
      "popular": "Populární",
      "other": "Ostatní"
    },
    "sk": {
      "popular": "Populárne",
      "other": "Ostatné"
    },
    "en": {
      "popular": "Popular",
      "other": "Other"
    },
    "pl": {
      "popular": "Popularne",
      "other": "Inne"
    },
    "hu": {
      "popular": "Népszerű",
      "other": "Egyéb"
    },
    "ru": {
      "popular": "Популярный",
      "other": "Другой"
    }
  }
</i18n>

<template>
    <div v-loading="loading">
        <h3 v-if="showHeader">PEP kontrola - {{ contact.full_name }} (#{{ contactData.id }})</h3>
        <template v-if="!checkInstantly && !pep">
            <el-button :disabled="loading" @click="check()">
                <i v-if="loading" class="el-icon-loading"></i> Spustit kontrolu
            </el-button>
        </template>
        <template v-else-if="pep">
            <ls-pep-protocol :data="pep" />
            <ls-pep-evaluation
                :pep="pep"
                :showCloseButton="showCloseButton"
                @done-pep="$emit('done-pep', $event)"
                @done-sanctions="$emit('done-sanctions', $event)"
                @complete="$emit('complete')"
            />
        </template>
    </div>
</template>

<script>
import lsPepEvaluation from '@/components/checks/pep/lsPepEvaluation.vue'

export default {
    components: { lsPepEvaluation },
    props: {
        amlUuid: {
            type: String,
            required: false,
            default: null
        },
        contact: {
            type: Object,
            required: false,
            default: undefined
        },
        showCloseButton: {
            type: Boolean,
            required: false,
            default: false
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        },
        checkInstantly: {
            type: Boolean,
            required: false,
            default: false
        },
        existingPep: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            contactData: this.contact ? {...this.contact}: null,
            pep: this.existingPep?.data,
        };
    },

    methods: {
        async check() {
            if(this.contactData.type == 'PO')
                this.checkCompany();
            else
                this.checkPerson();
        },

        async checkPerson() {
            if(!this.contactData.name || !this.contactData.surname) {
                this.$message.warning('Pozor, chybí povinné údaje kontaktu. Pro kontrolu je vyžadováno jméno a příjmení osoby.');
                return;
            }

            this.loading = true;
            try {
                const res = await this.$api.post('/proculus-api/pep/person', {
                    first_name: this.contact.name,
                    last_name: this.contact.surname,
                    date_of_birth: this.contact.birthdate ? moment(this.contact.birthdate).format('YYYY-MM-DD') : null,
                    contact_id: this.contactData.id,
                    aml_person_uuid: this.amlUuid
                });

                this.pep = res.data;

                this.$emit('loaded', res.data);
            }
            catch(error) {
                this.$catch(error, 'Omlouváme se, ale služba třetí strany neodpovídá. Zkuste to prosím později');
            }
            finally {
                this.loading = false;
            }
        },

        async checkCompany() {
            if(!this.contactData.full_name) {
                this.$message.error('Pozor, chybí povinné údaje kontaktu. Pro kontrolu je vyžadováno jméno firmy a krajina působení.');
                return;
            }

            this.loading = true;
            try {
                const res = await this.$api.post('/proculus-api/pep/company', {
                    name: this.contactData.full_name,
                    country: 'cz',//@TODO: make universal??
                    contact_id: this.contactData.id,
                    aml_company_uuid: this.amlUuid
                });

                this.pep = res.data;

                this.$emit('loaded', res.data);
            }
            catch(error) {
                this.$catch(error, 'Omlouváme se, ale služba třetí strany neodpovídá. Zkuste to prosím později');
            }
            finally {
                this.loading = false;
            }
        },
    },
    created(){
        if (this.checkInstantly){
            this.check()
        }
    }
}
</script>

let apiURL = "/proculus-api/single-case/"; 

let get = function(url) {
  return Vue.prototype.$api.get(apiURL + url)
    .then(res => res.data);
}

export default {
    contacts: {
        all(doc) {
            return get(doc.id + '/contacts/');
        },
        case(doc) {
            return get(doc.id + '/contacts/' + '?filter=case');
        },
        client(doc) {
            return get(doc.id + '/contacts/' + '?filter=client');
        },
        case_client(doc) {
            return get(doc.id + '/contacts/' + '?filter=case_client');
        }
    },

    adversaries:  {
        all(doc) {
            return get(doc.id + '/adversaries/');
        },
        case(doc) {
            return get(doc.id + '/adversaries/' + '?filter=case');
        },
    },
}

/*
 * Vue.js global mixins - external forms only
 */

Vue.mixin({
  methods: {
    //just send download request - external way
    justDownload(type, rename, store = false) {
      var template = null;
      var file = type;
      if(typeof file === 'object') {
        template = file.template;
        file = file.file;
      }

      document.getElementById('ls-form-json').value = JSON.stringify(this.doc);
      if(this.doc.id)
        document.getElementById('ls-form-id').value = this.doc.id;
      if(this.doc.uuid)
        document.getElementById('ls-form-uuid').value = this.doc.uuid;
      document.getElementById('ls-form-token').value = this.$context.client.token;
      document.getElementById('ls-form-template').value = template;
      document.getElementById('ls-form').action = this.generatorUrl(file, rename, true, false, store);
      document.getElementById('ls-form').submit();
    },

    //just get download url (promise)
    justGetUrl(type, keep, rename, attachments = null) {
      var template = null;
      var file = type;
      if(typeof file === 'object') {
        template = file.template;
        file = file.file;
      }

      var url = this.hostname + "/external-api/access/";
      return new Promise((resolve, reject) => {
        this.$api.post(this.generatorUrl(file, rename, false, keep), {
          id: this.doc?.id,
          uuid: this.doc?.uuid,
          json: JSON.stringify(this.doc),
          template: template
        }).then(res => {
          resolve(url + res.data);
        }).catch(res => {
          reject(res);
        });
      });
    },

    //direct -> prepare link that will return download URL (true) or prepare direct download (false)
    generatorUrl(file, rename, direct = true, keep = false, store = false) {
      var uSwitch = (!direct) ? "Url" : "";
      var sSwitch = store ? "1" : "0";
      
      return this.hostname + "/external-api/" + this.$context.client.slug + "/generate"+uSwitch+"/"+sSwitch+"/"+this.template.slug+"/"+file+(rename ? ('/'+rename) : '');
    },

  }
});
